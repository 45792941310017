import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Handle, Position, useReactFlow, useStore } from 'reactflow';

const InvisibleNode = ({ id }) => {
  const label = useStore(s => {
    const node = s.nodeInternals.get(id);

    if (!node) {
      return null;
    }

    return `position x:${parseInt(node.position.x)} y:${parseInt(
      node.position.y
    )}`;
  });
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
        }}
      />
      <Text color={'#fff'} padding={0}>.</Text>
      {/* {label || 'no node connected'} */}
      <Handle
        type="source"
        position={Position.Right}
        id="e"
        style={{
          backgroundColor: 'transparent',
          border: 'none',
        }}
      />
    </>
  );
};

export default InvisibleNode;
