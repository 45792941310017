import React, { useEffect, useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { FaRegCopy } from 'react-icons/fa';
import { Text } from '@chakra-ui/react';
import { IoIosWarning } from 'react-icons/io';
import { FaCloud } from 'react-icons/fa';
import { motion } from 'framer-motion';

const IconNode = ({ data }) => {
  const reactFlowInstance = useReactFlow();
  const [animated, setAnimated] = useState(false);

  // console.log('Icon data', data);
  useEffect(() => {
    // hack to avoid animation on first render; for some reason nodes were animating from position 0
    // to their initial position
    setAnimated(true);
  }, []);

  return (
    <motion.div
      className="animated-node"
      layout={animated}
      // create new component when animated changes, see issue workaround https://github.com/framer/motion/issues/2238#issue-1809290539
      key={data?.id?.concat(animated.toString())}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
        }}
      />
      <div
        className="icon-node nodrag"
        style={{
          background: `${
            data?.icon === 'FaRegCopy'
              ? '#1966B9'
              : data?.icon === 'FaCloud'
              ? '#3C5062'
              : '#C52421'
          } `,
        }}
      >
        <div>
          {/* <img
          src={
            data?.img ||
            'https://media.istockphoto.com/id/1389937723/vector/cartoon-planet-earth-3d-vector-icon-on-white-background.jpg?s=612x612&w=0&k=20&c=hntEYVS5xepGQi1AIpRipUTYnH2Tp_S1TXS5M-pQe3A='
          }
          alt="earth"
          height={50}
          width={50}
          draggable="false"
        /> */}
          {data?.icon === 'FaRegCopy' ? (
            <FaRegCopy color="#fff" fontSize={30} />
          ) : data?.icon === 'IoIosWarning' ? (
            <IoIosWarning color="#fff" fontSize={30} />
          ) : (
            <FaCloud color="#fff" fontSize={30} />
          )}
        </div>
        {/* <Handle type="source" position={Position.Right} id="a" /> */}
      </div>
      <div
        style={{
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translate(-50%)',
          pointerEvents: 'none',
          width: 'max-content',
        }}
      >
        {data.label}
      </div>
    </motion.div>
  );
};

export default IconNode;
