import React from 'react';
import {
  Box,
  Accordion,
  Stack,
  Text,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Divider,
  Link,
} from '@chakra-ui/react';

const Insight = ({ data }) => {
  return (
    <Stack width={'100%'} display={'flex'} flexDirection={'column'} gap={5}>
      {Object.entries(data).map(([sectionName, records], sectionIndex) => (
        <Accordion
          key={sectionIndex}
          allowMultiple
          defaultIndex={[0]} // Sets the first item open in each section
          collapsible
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  {sectionName.toUpperCase()}
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} backgroundColor={'#FFFFFF'}>
              {records.map((record, recordIndex) => (
                <Accordion
                  allowMultiple
                  key={recordIndex}
                  mb={4}
                  defaultIndex={recordIndex === 0 ? [0] : []}
                >
                  <AccordionItem
                    border={'1px solid #1E86FF'}
                    rounded={'md'}
                    backgroundColor={'#FFFFFF'}
                  >
                    <h2>
                      <AccordionButton
                        color={'#1E86FF'}
                        backgroundColor={'#EBF4FF'}
                      >
                        <Box as="span" flex="1" textAlign="left">
                          {`${sectionName.toUpperCase()} Record ${
                            recordIndex + 1
                          }`}
                        </Box>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} overflowY="auto" width="75%">
                      {Object.entries(record).map(
                        ([key, value], valueIndex, arr) => (
                          <Box key={valueIndex} mb={4}>
                            <Box mb={2}>
                              <Text
                                fontWeight="bold"
                                fontSize="lg"
                                isTruncated={false}
                              >
                                {key}:
                              </Text>
                              <Text
                                pl={4}
                                whiteSpace="pre-wrap"
                                wordBreak="break-word"
                              >
                                {key === 'source_tracker_result' &&
                                Array.isArray(value) &&
                                value.length > 0
                                  ? // Render the source_tracker_result array with its content
                                    value.map((result, resultIndex) => (
                                      <Box key={resultIndex} mb={2}>
                                        {Object.entries(result).map(
                                          ([resultKey, resultValue]) =>
                                            resultKey === 'source_url' ? (
                                              <Box key={resultKey}>
                                                <Text
                                                  as="span"
                                                  fontWeight="bold"
                                                >
                                                  {resultKey}:{' '}
                                                </Text>
                                                <Link
                                                  href={resultValue}
                                                  color="blue.500"
                                                  isExternal
                                                >
                                                  {resultValue}
                                                </Link>
                                              </Box>
                                            ) : (
                                              <Box key={resultKey}>
                                                <Text
                                                  as="span"
                                                  fontWeight="bold"
                                                >
                                                  {resultKey}:{' '}
                                                </Text>
                                                <Text as="span">{`${resultValue}`}</Text>
                                              </Box>
                                            )
                                        )}
                                      </Box>
                                    ))
                                  : typeof value === 'object'
                                  ? JSON.stringify(value, null, 2)
                                  : value}
                              </Text>
                            </Box>
                            {valueIndex < arr.length - 1 && (
                              <Divider width={'100%'} borderColor="gray.300" />
                            )}
                          </Box>
                        )
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </Stack>
  );
};

export default Insight;

const items = [
  { value: 'a', title: 'First Item', text: 'Some value 1...' },
  { value: 'b', title: 'Second Item', text: 'Some value 2...' },
  { value: 'c', title: 'Third Item', text: 'Some value 3...' },
];
