import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Heading,
  Stack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Input,
  Button,
  Divider,
  List,
  ListItem,
  Link,
  Text,
  Spinner,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Select,
  useToast,
} from '@chakra-ui/react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  ConnectionLineType,
} from 'reactflow';
import 'reactflow/dist/style.css';
import SelectionNode from '../VisualFlow/SelectionNode';
import '../VisualFlow/custom-node.css';
import CustomEdge from '../VisualFlow/CustomEdge';
import IconNode from '../VisualFlow/CustomIcon';
import { POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import Azure from '../../assets/images/companies/azure.png';

const initialEdges = [
  { id: '1->2', type: 'smoothstep', source: '1', target: '2' },
  { id: '2->3', type: 'smoothstep', source: '2', target: '3', animated: true },
];

const nodeTypes = { selectUpdater: SelectionNode, IconDisplay: IconNode };
const edgeTypes = {
  'custom-edge': CustomEdge,
};

const VisualFlow = ({ selectedVal, icon }) => {
  const user = useSelector(state => state.value);
  const serviceNowUsers = useSelector(state => state.serviceNowUsers);
  const hibernatingErrorMessage =
    'Could not extract response: no suitable HttpMessageConverter found for response type [com.cipherstack.itsm.servicenow.model.servicenow.ServiceNowTableResponse<com.cipherstack.itsm.servicenow.dto.IssueRequest>] and content type [text/html]';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const serviceNowAuth = useSelector(state => state.serviceNowAuth);
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const {
    isOpen: isAssignOpen,
    onOpen: onAssignOpen,
    onClose: onAssignClose,
  } = useDisclosure();
  const [nodeData, setnodeData] = useState(null);
  const [data, setData] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [assignData, setAssignData] = useState({
    customer_id: '22',
    number: '1234556',
    caller_id: 'seqoria2@testtest.com',
    type: '',
    active: '',
    description: '',
    short_description: '',
    urgency: '',
    assigned_to: '',
    close_notes: '',
    comments: '',
    category: '',
    fix_notes: '',
  });

  const handleNodeClick = (evt, node) => {
    if (node.type === 'IconDisplay' && node?.data?.nodeData) {
      setnodeData(node?.data?.nodeData?.result);
      // default node
      console.log('NODE', node);
      console.log('I found a black sheep');
      onOpen();
    }
  };

  const serviceNowIssue = async () => {
    setIsLoading(true);
    try {
      let response = await POST(`api/itsm/servicenow/issue`, assignData, {
        bearerToken: `${serviceNowAuth?.access_token}`,
      });
      console.log(response);
      if (response?.message === hibernatingErrorMessage) {
        toast({
          description:
            'Unable to reach servicenow server, Please visit dev. servicenow!',
          status: 'error',
          position: 'top-right',
          duration: 4500,
          isClosable: true,
        });
      } else if (response?.status === 400) {
        toast({
          description: 'Invalid data provided!',
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          description: 'Issue assigned successfully!',
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        description:
          err?.message ?? 'Something went wrong in third party integration',
        status: 'error',
        position: 'bottom-left',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
    onAssignClose();
  };

  const getData = async () => {
    setIsLoading(true);
    // setFetchingCompliance(true);
    try {
      const res = await POST(
        'api/template/compliance/result/vendor/AZURE/from/2024-05-09/to/2024-05-10'
      );
      setData([res?.data[res?.data?.length - 1]]);
      console.log('WE Got Data', res?.data[res?.data?.length - 1]);
      const node = [
        {
          id: '1',
          position: { x: 100, y: 425 },
          type: 'selectUpdater',
          data: {
            values:
              res?.data[res?.data?.length - 1]?.compliance?.compliance[0].items,
            img: Azure,
            placeholder: 'Please Selet a Zone',
            totalIds: 1,
            selectedVal: selectedVal,
            loadingState: setIsLoading,
          },
        },
        // {
        //   id: '2',
        //   type: 'IconDisplay',
        //   position: { x: 300, y: 230 },
        //   data: { label: 'Application Endpoint', icon: 'FaRegCopy' },
        // },
      ];
      setNodes(node);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    setIsLoading(false);
    // setFetchingCompliance(false);
  };

  const onConnect = useCallback(
    params => setEdges(eds => addEdge(params, eds)),
    [setEdges]
  );

  useEffect(() => {
    console.log(user);
    getData();
  }, [user]);

  
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={'10px'}
            padding={'35px 30px'}
            background={'#fff'}
            borderRadius={'10px'}
            marginBottom={'20px !important'}
            spacing={0}
            flexWrap={'wrap'}
          >
            <Image src={icon ?? ''} />
            <Heading
              fontSize={'18px'}
              fontWeight={'700'}
              color={'#8A959E'}
              textWrap={'initial'}
            >
              {selectedVal}
            </Heading>
            <Stack width={'full'} height={'75vh'} border={'1px solid #000'}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodeClick={handleNodeClick}
                // fitView
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                // zoomOnScroll={false}
                // panOnDrag={false}
                zoomOnDoubleClick={false}
                // nodesDraggable={false}
              >
                <Controls />
                {/* <MiniMap /> */}
                {/* <Background variant="dots" gap={12} size={1} /> */}
              </ReactFlow>
            </Stack>
          </Stack>
          {nodeData && nodeData !== null && (
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
              size={'sm'}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Endpoint Details</DrawerHeader>
                <Divider />
                <DrawerBody padding={4}>
                  <List gap={6} spacing={3}>
                    <ListItem display={'flex'} gap={2}>
                      <Text fontWeight="bold">ID:</Text>{' '}
                      <Text>{nodeData?.id}</Text>
                    </ListItem>
                    <ListItem display={'flex'} gap={2}>
                      <Text fontWeight="bold">Rule Key:</Text>{' '}
                      <Text>{nodeData?.ruleKey}</Text>
                    </ListItem>
                    <ListItem display={'flex'} gap={2}>
                      <Text fontWeight="bold">Vendor Type:</Text>{' '}
                      <Text>{nodeData?.vendorType}</Text>
                    </ListItem>
                    <ListItem display={'flex'} gap={2}>
                      <Text fontWeight="bold">Security:</Text>{' '}
                      <Text color={nodeData?.vulnerable ? 'red' : 'green'}>
                        {nodeData?.vulnerable ? 'HIGH' : 'LOW'}
                      </Text>
                    </ListItem>
                    <ListItem display={'flex'} gap={2}>
                      <Text fontWeight="bold">Detailed Name:</Text>{' '}
                      <Text>{nodeData?.ruleName}</Text>
                    </ListItem>
                    <ListItem display={'flex'} gap={2} my={4}>
                      <Button
                        bgColor={'primary.100'}
                        onClick={() => onAssignOpen()}
                        color={'#fff'}
                        _hover={{ bgColor: '#444' }}
                        _active={{ bgColor: '#444' }}
                        padding={'8px 15px'}
                      >
                        Action
                      </Button>
                    </ListItem>
                    {/*<ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">Detection Method:</Text>{' '}
                    <Text>{nodeData?.detectionMethod}</Text>
                  </ListItem>
                  <ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">Source:</Text>{' '}
                    <Text>{nodeData?.source}</Text>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight="bold">Data Source Link:</Text>{' '}
                    <Link
                      href={nodeData?.dataSourceLink?.link}
                      color={'blue.400'}
                    >
                      {' '}
                      {nodeData?.dataSourceLink?.linkName}{' '}
                    </Link>
                  </ListItem> */}
                  </List>
                </DrawerBody>

                <DrawerFooter>
                  <Button
                    variant="outline"
                    colorScheme="blue"
                    mr={3}
                    onClick={onClose}
                    width={'full'}
                  >
                    View Details
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          )}
        </>
      )}
      <Modal
        isOpen={isAssignOpen}
        onClose={onAssignClose}
        size={'2xl'}
        padding={'40px'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Issue(s)</ModalHeader>
          <ModalBody>
            <Text
              fontFamily={'Inter'}
              fontSize={'16px'}
              colo={'#667085'}
              marginBottom={'20px'}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore{' '}
            </Text>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mb={'10px'}
            >
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Select rule info
                </Text>
                <Select
                  cursor={'pointer'}
                  border={'none'}
                  _focusVisible={{ outline: 'none' }}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      type: e.target.value,
                    });
                  }}
                >
                  <option value={''} style={{ display: 'none' }}></option>
                  <option value={'INCIDENT'}>Service Now</option>
                  <option value={'INCIDENT'}>INCIDENT</option>
                </Select>
              </Box>
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Active Status
                </Text>
                <Select
                  cursor={'pointer'}
                  border={'none'}
                  _focusVisible={{ outline: 'none' }}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      active: e.target.value,
                    });
                  }}
                >
                  <option value={''} style={{ display: 'none' }}></option>
                  <option value={'true'}>Active</option>
                  <option value={'false'}>In active</option>
                </Select>
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mb={'10px'}
            >
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Description
                </Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      description: e.target.value,
                    });
                  }}
                />
              </Box>
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Short Description
                </Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      short_description: e.target.value,
                    });
                  }}
                />
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mb={'10px'}
            >
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  SLA
                </Text>
                <Select
                  cursor={'pointer'}
                  border={'none'}
                  _focusVisible={{ outline: 'none' }}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      urgency: e.target.value,
                    });
                  }}
                >
                  <option value={''} style={{ display: 'none' }}></option>
                  <option value={'HIGH'}>between 8-24 hrs</option>
                  <option value={'MEDIUM'}>1-3 days</option>
                  <option value={'LOW'}>3-7 days</option>
                </Select>
              </Box>
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Assign to{' '}
                </Text>
                <Select
                  cursor={'pointer'}
                  border={'none'}
                  _focusVisible={{ outline: 'none' }}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      assigned_to: e.target.value,
                    });
                  }}
                >
                  {serviceNowUsers?.users?.length > 0 ? (
                    <option value={''} style={{ display: 'none' }}></option>
                  ) : (
                    <option value={''} style={{ display: 'none' }}>
                      No user found!
                    </option>
                  )}
                  {serviceNowUsers?.users?.length > 0 &&
                    serviceNowUsers?.users?.map(val => (
                      <option key={val?.id} value={val?.email}>
                        {val?.username}
                      </option>
                    ))}
                </Select>
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mb={'10px'}
            >
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Close Notes
                </Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      close_notes: e.target.value,
                    });
                  }}
                />
              </Box>
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Comments
                </Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      comments: e.target.value,
                    });
                  }}
                />
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mb={'10px'}
            >
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Category
                </Text>
                <Select
                  cursor={'pointer'}
                  border={'none'}
                  _focusVisible={{ outline: 'none' }}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      category: e.target.value,
                    });
                  }}
                >
                  <option value={''} style={{ display: 'none' }}></option>
                  <option value={'ZeroTrust'}>Zero Trust</option>
                </Select>
              </Box>
              <Box
                w={'49%'}
                borderRadius={'8px'}
                border={'1px solid #adadad'}
                padding={'10px'}
                cursor={'pointer'}
              >
                <Text fontSize={'15px'} fontFamily={'Inter'}>
                  Fix Notes
                </Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  onChange={e => {
                    setAssignData({
                      ...assignData,
                      fix_notes: e.target.value,
                    });
                  }}
                />
              </Box>
            </Stack>
            <Stack
              mt={'30px !important'}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Button
                isLoading={isLoading}
                onClick={() => serviceNowIssue()}
                w={'190px'}
                bgColor={'#4A4A4A'}
                color={'#fff'}
                padding={'22px 0'}
                fontSize={'16px'}
                fontWeight={'500'}
                border={'2px solid #4A4A4A'}
                _hover={{
                  bgColor: 'transparent',
                  color: '#4A4A4A',
                }}
              >
                Assign
              </Button>
              <Button
                onClick={() => {
                  onAssignClose();
                }}
                w={'80px'}
                bgColor={'transparent'}
                color={'#8A959E'}
                padding={'22px 0'}
                fontSize={'16px'}
                fontWeight={'500'}
                border={'2px solid transparent'}
                _hover={{
                  borderColor: '#4A4A4A',
                  color: '#4A4A4A',
                }}
              >
                Cancel
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default VisualFlow;
