import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  Handle,
  Position,
  useReactFlow,
  useNodesInitialized,
  useStore,
} from 'reactflow';
import { Button, Input } from '@chakra-ui/react';

const fitviewOption = {
  includeHiddenNodes: true,
  duration: 0.1,
};
const options = {
  includeHiddenNodes: false,
};

const InputNode = ({ data, id }) => {
  const reactFlowInstance = useReactFlow();
  const nodesInitialized = useNodesInitialized(options);
  const [animated, setAnimated] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [fieldsCreated, setFieldsCreated] = useState(false);

  const label = useStore(s => {
    const node = s.nodeInternals.get(id);

    if (!node) {
      return null;
    }

    return `position x:${parseInt(node.position.x)} y:${parseInt(
      node.position.y
    )}`;
  });

  const onChange = event => {
    console.log('Current Value', event.target.value);
    setNameValue(event.target.value);
  };

  // Create Selection Field
  const createNeighborFields = () => {
    let newIds = [];
    let newEdgeIds = [];
    let totalIds = reactFlowInstance.getNodes().length;

    // const groupNode = {
    //   id: 'A',
    //   data: { label: 'Neighbor Relationship' },
    //   position: { x: 850, y: 350 },
    //   className: 'light nodrag',
    //   style: {
    //     backgroundColor: 'rgba(255, 0, 0, 0.2)',
    //     width: 235,
    //     height: 400,
    //   },
    // };
    // reactFlowInstance.addNodes(groupNode);
    const floatNode = {
      id: 'FL',
      position: { x: 774, y: 194 },
      type: 'FloatingText',
      data: {
        text: 'Neighbor Relationship',
      },
    };
    newIds.push(floatNode?.id);
    reactFlowInstance.addNodes(floatNode);

    const nodesPayload = [
      {
        position: { x: 850, y: 240 },
        type: 'NeighborSelector',
        data: {
          placeholder: 'Originator',
        },
      },
      {
        position: { x: 850, y: 330 },
        type: 'NeighborSelector',
        data: {
          placeholder: 'Adjecent',
        },
      },
      {
        position: { x: 850, y: 460 },
        type: 'NeighborSelector',
        data: {
          placeholder: 'Transient',
        },
      },
      {
        position: { x: 850, y: 550 },
        type: 'NeighborSelector',
        data: {
          placeholder: 'Destination',
        },
      },
    ];
    for (let i = 0; i < nodesPayload.length; i++) {
      const id = `${++totalIds}A`;
      newIds.push(id); // Collect new ID
      const newNode = {
        id,
        type: nodesPayload[i].type,
        position: nodesPayload[i].position,
        // parentId: 'A',
        data: {
          placeholder: nodesPayload[i].data.placeholder,
          isNeighbor: true,
          values: ['AZURE - 663cf917c562623780ac0130'],
          selectedValue: '',
        },
      };
      reactFlowInstance.addNodes(newNode);
      newIds = [...newIds, id];

      const newEdge = {
        id: `edge-${++totalIds}`,
        source: data?.currentId,
        target: id,
        sourceHandle: 'c',
        style: {
          strokeWidth: 2,
          stroke: data?.edgeColor ? data.edgeColor : 'black',
        },
      };
      console.log('New Edge payload', newEdge);
      newEdgeIds.push(`edge-${id}`);
      reactFlowInstance.addEdges(newEdge);
      setFieldsCreated(true);

    }
    data?.setCreatedNodes(prevNodes => [...prevNodes, ...newIds]);
    data?.setCreatedEdges(prevEdges => [...prevEdges, ...newEdgeIds]);
    // if (nodesInitialized) {
    //   setFieldsCreated(true);
    //   console.log('fit view triggered');
    //   setTimeout(() => reactFlowInstance?.fitView(fitviewOption), 50);
    // }
  };

  useEffect(() => {
    console.log('Data', data);
  }, [data]);

  return (
    <motion.div
      className="animated-node"
      layout={animated}
      // create new component when animated changes, see issue workaround https://github.com/framer/motion/issues/2238#issue-1809290539
      key={data?.id?.concat(animated.toString())}
    >
      <div className="text-updater-node ">
        {/* <Handle type="target" position={Position.Left} /> */}
        {/* <Handle type="source" position={Position.Bottom} id="a" /> */}
        <Handle
          type="source"
          position={Position.Right}
          id="c"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        />
        <div>
          <label htmlFor="text"> Name: </label>
          <Input
            id="text"
            name="text"
            placeholder={data?.placeholder}
            onChange={onChange}
          />
        </div>
        <Handle
          type="target"
          position={Position.Left}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '110%',
          left: '35%',
          transform: 'translate(-50%)',
        }}
      >
        {/* {label || 'no node connected'} */}
        <Button
          padding={'10px 20px'}
          height={'unset'}
          disabled={!nameValue }
          onClick={() => createNeighborFields()}
        >
          Continue
        </Button>
      </div>
    </motion.div>
  );
};

export default InputNode;
