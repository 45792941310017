import { MenuButton, Menu, MenuList, MenuItem, Icon, Button, Avatar, Stack, Box, Text } from "@chakra-ui/react";
import { FaRegBell, FaCircle } from 'react-icons/fa'

export default function NotificationIcon() {
    return (
        <Menu>
            <MenuButton
                ml="30px !important"
                position="relative"
                p="0"
                minW="0"
                minH="0"
                background="transparent"
                _hover={{ background: 'transparent' }}
                _activeVisible={{ background: 'transparent !important' }}
                as={Button}
                rightIcon={<Icon as={FaRegBell}
                    fontSize="24px"
                />}>
                <Text
                    as="span"
                    position="absolute"
                    top="-3px"
                    right="-7px"
                    bgColor="#1a1a1a"
                    color="#fff"
                    borderRadius="100%"
                    fontSize="12px"
                    w="20px"
                    h="20px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >3</Text>
            </MenuButton>
            <MenuList>
                <MenuItem>
                    <Stack borderRadius={'12px'} p="15px 10px" bgColor={'#fff'} mb="0px !important">
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} gap="20px" flex="2">
                                <Box>
                                    <Avatar title='Adam Knight' name='Adam Knight'></Avatar>
                                </Box>
                                <Box>
                                    <Text textTransform={'capitalize'} fontWeight={600} fontSize={{ base: 13, md: 14 }}>Adam Knight</Text>
                                    <Text fontSize={{ base: 13, md: 14 }}>Lorem Ipsum dolor sit amet is a dummy text to place to fill up some space.</Text>
                                </Box>
                            </Box>
                            <Box textAlign={'right'} flex="1">
                                <Icon as={FaCircle} fontSize={'8px'} color={'transparent'} />
                                <Text color={'#adadad'} fontSize={'14px'} p="10px 0 0 0">15min ago</Text>
                            </Box>
                        </Stack>
                    </Stack>
                </MenuItem>
                <MenuItem>
                    <Stack borderRadius={'12px'} p="15px 10px" bgColor={'#fff'} mb="0px !important">
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} gap="20px" flex="2">
                                <Box>
                                    <Avatar title='Adam Knight' name='Adam Knight'></Avatar>
                                </Box>
                                <Box>
                                    <Text textTransform={'capitalize'} fontWeight={600} fontSize={{ base: 13, md: 14 }}>Adam Knight</Text>
                                    <Text fontSize={{ base: 13, md: 14 }}>Lorem Ipsum dolor sit amet is a dummy text to place to fill up some space.</Text>
                                </Box>
                            </Box>
                            <Box textAlign={'right'} flex="1">
                                <Icon as={FaCircle} fontSize={'8px'} color={'transparent'} />
                                <Text color={'#adadad'} fontSize={'14px'} p="10px 0 0 0">15min ago</Text>
                            </Box>
                        </Stack>
                    </Stack>
                </MenuItem>
                <MenuItem>
                    <Stack borderRadius={'12px'} p="15px 10px" bgColor={'#fff'} mb="0px !important">
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} gap="20px" flex="2">
                                <Box>
                                    <Avatar title='Adam Knight' name='Adam Knight'></Avatar>
                                </Box>
                                <Box>
                                    <Text textTransform={'capitalize'} fontWeight={600} fontSize={{ base: 13, md: 14 }}>Adam Knight</Text>
                                    <Text fontSize={{ base: 13, md: 14 }}>Lorem Ipsum dolor sit amet is a dummy text to place to fill up some space.</Text>
                                </Box>
                            </Box>
                            <Box textAlign={'right'} flex="1">
                                <Icon as={FaCircle} fontSize={'8px'} color={'transparent'} />
                                <Text color={'#adadad'} fontSize={'14px'} p="10px 0 0 0">15min ago</Text>
                            </Box>
                        </Stack>
                    </Stack>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}
