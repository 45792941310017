import {
    Box, Button, Icon, Select, Stack, Text, FormLabel, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Heading,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from '@chakra-ui/react'
import { Divider, Form, Input, Typography } from 'antd'
import { desiredExecutors, desiredCompliance } from '../../../utilities/desiredOutcomesData'
import { BsChevronDown } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import EnterpriseRiskTolerance from '../EnterpriseRiskTolerance/EnterpriseRiskTolerance';
import { useState } from 'react';
import GenAiSecurity from '../GenAiPostureManagement/Index.js'
import SpacedStack from '../../SpacedStack/index.js';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

export default function EnhanceControl() {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isCliOpen, onOpen: onCliOpen, onClose: onCliClose } = useDisclosure();
    const [isShowingCustom, setIsShowingCustom] = useState(false);
    return (
        <>
            {
                !isShowingCustom ?
                    <Stack>
                        <form>
                            <Stack display='flex' alignItems={'flex-end'}>
                                <Button
                                    onClick={() => setIsShowingCustom(!isShowingCustom)}
                                    bgColor={'#ebf4ff'}
                                    border={'2px solid #1E86FF'}
                                    borderRadius={'4px'}
                                    color={'#1E86FF'}
                                    fontWeight={'600'}
                                    w={'140px'}
                                >{!isShowingCustom ? 'Custom UI' : 'Back'}</Button>
                            </Stack>
                            <Stack
                                className='cyberResiliencyMain'
                                direction={'row'}
                                alignItems={'flex-end'}
                                justifyContent={'space-between'}
                            >
                                <Box
                                    w={{ base: "100%", md: "45%", xl: "35%" }}
                                >
                                    <FormLabel>Rules Name</FormLabel>
                                    {/* <Typography.Text></Typography.Text> */}
                                    <Form.Item name="ruleName">
                                        <Input
                                            width={"100%"}
                                            size="large"
                                            placeholder="Check Vms & MFA setting"
                                        />
                                    </Form.Item>
                                </Box>
                                <Box
                                    w={{ base: "100%", md: "45%", xl: "35%" }}
                                >
                                    <Typography.Text>Rule Key</Typography.Text>
                                    <Form.Item name="ruleKey">
                                        <Input
                                            size="large"
                                            width={"100%"}
                                            placeholder="Check Vms & MFA setting"
                                        />
                                    </Form.Item>
                                </Box>
                                <Box
                                    w={{ base: "100%", md: "45%", xl: "15%" }}
                                >
                                    <Select
                                        _focusVisible={{ outline: 'none' }}
                                        border={'1px solid #d9d9d9'}
                                        borderRadius={8}
                                    >
                                        <option style={{ display: 'none' }} value={''}>Vendor</option>
                                        <option value='aws'>AWS</option>
                                        <option value='gcp'>GCP</option>
                                        <option value='azure'>Azure</option>
                                        <option value='zoom'>Zoom</option>
                                    </Select>
                                </Box>
                                <Box
                                    w={{ base: "100%", md: "45%", xl: "20%" }}
                                >
                                    <Select
                                        _focusVisible={{ outline: 'none' }}
                                        border={'1px solid #d9d9d9'}
                                        borderRadius={8}
                                    >
                                        <option value='AWS configuration 1'>AWS configuration 1</option>
                                        <option value='AWS configuration 1'>AWS configuration 2</option>
                                    </Select>
                                </Box>

                            </Stack>
                        </form>
                        <Divider style={{ margin: '30px 0' }} />
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            bgColor={'#555555'}
                            borderRadius={'6px'}
                            padding={'20px 30px'}
                            mb={'45px !important'}
                        >
                            <Text
                                fontFamily={'Inter'}
                                fontSize={'16px'}
                                fontWeight={'600'}
                                color={'#fff'}
                            >
                                Executors</Text>
                            <Select
                                w={'235px'}
                                background={'#fff'}
                                borderRadius={8}
                                onChange={e => {
                                    if (e.target.value === 'rest') {
                                        onOpen();
                                    } else {
                                        onCliOpen();
                                    }
                                }}
                            >
                                <option style={{ display: 'none' }}>Add</option>
                                <option value={'rest'}>Rest API</option>
                                <option value={'cli'}>Command Line CLI</option>
                            </Select>
                        </Stack>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            flexWrap={'wrap'}
                            spacing={0}
                            gap={'10px'}
                        >
                            {
                                desiredExecutors?.map((v, i) =>
                                    <Button
                                        key={i}
                                        w={'32.8%'}
                                        background={'#F0F0F0'}
                                        border={'1px solid'}
                                        borderColor={'#E8EDFC'}
                                        borderRadius={'6px'}
                                        padding={'32px 17px'}
                                        _hover={{ bgColor: '#F0F0F0' }}
                                        _active={{ bgColor: '#F0F0F0' }}
                                    // onClick={() => setSelectedDesireOption(name)}
                                    >
                                        <Stack
                                            w={'full'}
                                            direction={'row'}
                                            alignItems={'center'}
                                            gap={'10px'}
                                            justifyContent={'space-between'}
                                        >
                                            <Box
                                                display={'flex'}
                                                gap={'10px'}
                                                alignItems={'center'}
                                            >
                                                <Text
                                                    fontSize={'16px'}
                                                    fontWeight={'600'}
                                                    color={'#8A959E'}
                                                >
                                                    {v.name}
                                                </Text>
                                            </Box>
                                            <Icon fontWeight={'700'} fontSize={'17px'} as={BsChevronDown} />
                                        </Stack>
                                    </Button>
                                )
                            }
                        </Stack>
                        <Divider style={{ margin: '30px 0' }} />
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            bgColor={'#555555'}
                            borderRadius={'6px'}
                            padding={'20px 30px'}
                            mb={'45px !important'}
                        >
                            <Text
                                fontFamily={'Inter'}
                                fontSize={'16px'}
                                fontWeight={'600'}
                                color={'#fff'}
                            >
                                Compliance Rules</Text>
                            <Select
                                w={'235px'}
                                background={'#fff'}
                                onChange={() => navigate('/rule')}
                                borderRadius={8}
                            >
                                <option style={{ display: 'none' }}>Add</option>
                                <option>Rules</option>
                            </Select>
                        </Stack>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            flexWrap={'wrap'}
                            spacing={0}
                            gap={'10px'}
                        >
                            {
                                desiredCompliance?.map((v, i) =>
                                    <Button
                                        key={i}
                                        w={'32.8%'}
                                        background={'#F0F0F0'}
                                        border={'1px solid'}
                                        borderColor={'#E8EDFC'}
                                        borderRadius={'6px'}
                                        padding={'32px 17px'}
                                        _hover={{ bgColor: '#F0F0F0' }}
                                        _active={{ bgColor: '#F0F0F0' }}
                                    // onClick={() => setSelectedDesireOption(name)}
                                    >
                                        <Stack
                                            w={'full'}
                                            direction={'row'}
                                            alignItems={'center'}
                                            gap={'10px'}
                                            justifyContent={'space-between'}
                                        >
                                            <Box
                                                display={'flex'}
                                                gap={'10px'}
                                                alignItems={'center'}
                                            >
                                                <Text
                                                    fontSize={'16px'}
                                                    fontWeight={'600'}
                                                    color={'#8A959E'}
                                                >
                                                    {v.name}
                                                </Text>
                                            </Box>
                                            <Icon fontWeight={'700'} fontSize={'17px'} as={BsChevronDown} />
                                        </Stack>
                                    </Button>
                                )
                            }
                        </Stack>
                        <Stack
                            mt={'50px !important'}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                w={'190px'}
                                bgColor={'#555555'}
                                color={'#fff'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid #4A4A4A'}
                                _hover={{
                                    bgColor: 'transparent',
                                    color: '#4A4A4A'
                                }}
                            >Save</Button>
                            <Button
                                w={'190px'}
                                bgColor={'transparent'}
                                color={'#8A959E'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid transparent'}
                                _hover={{
                                    borderColor: '#4A4A4A',
                                    color: '#4A4A4A'
                                }}
                            >Cancel</Button>
                        </Stack>
                        {/* <EnterpriseRiskTolerance sideMenu={false} /> */}
                    </Stack>
                    :
                    <GenAiSecurity />
            }

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={'full'}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SpacedStack>
                            <Stack>
                                <Stack
                                    direction={'row'}
                                    spacing={0}
                                    gap={6}
                                    flexWrap={'wrap'}
                                    className='vendorConfigFieldsBox'
                                >
                                    <Box w={"35%"} margin={'0 0 15px 0 !important'}>
                                        <Typography.Text>Name (Unique)</Typography.Text>
                                        <Input
                                            size="md"
                                            p={4}
                                            borderRadius={6}
                                            placeholder="Production Account Configuration"
                                        />
                                    </Box>
                                    <Box w={"50%"} margin={'0 0 15px 0 !important'}>
                                        <Typography.Text>API Endpoint</Typography.Text>
                                        <Input
                                            size="md"
                                            p={4}
                                            borderRadius={6}
                                            placeholder="123xxx555xxx"
                                        />
                                    </Box>
                                </Stack>
                                <Box w={'full'} className="vendorConfigOpt">
                                    <Heading fontSize={'22px'} color={'#2626262'} margin={'30px 0 0 0'}>
                                        Parse API Response
                                    </Heading>
                                </Box>
                                <Box w={'full'} margin={'25px 0 0 0 !important'}>
                                    <Accordion
                                        defaultIndex={[0, 1]}
                                        allowMultiple
                                        allowToggle
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={'flex-start'}
                                    >
                                        <AccordionItem
                                            w={'47%'}
                                            border={'none'}
                                            borderRadius={'6px'}
                                            mb={'30px'}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <h2>
                                                        <AccordionButton
                                                            border={0}
                                                            outlineOffset={'0px'}
                                                        >
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                borderRadius={'6px'}
                                                            >
                                                                Extract Result
                                                            </Box>
                                                            {isExpanded ? (
                                                                <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                            ) : (
                                                                <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                            )}
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={0} px={0}>
                                                        <Stack
                                                            bgColor={'primary.100'}
                                                            color={'#fff'}
                                                            direction={'row'}
                                                        >
                                                            <Text
                                                                py={'20px'}
                                                                w={'150px'}
                                                                borderRight={'1px solid #E8EDFC'}
                                                                pl={'20px'}
                                                            >
                                                                Variable
                                                            </Text>
                                                            <Text py={'20px'} pl={'20px'}>
                                                                Value (JSON Path Expression)
                                                            </Text>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                Account_json
                                                            </Text>
                                                            <Box w={'78%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                                                                <Input
                                                                    _focusVisible={{
                                                                        outlineOffset: '0px'
                                                                    }}
                                                                    border={'0 !important'}
                                                                    style={{ width: '82px' }}
                                                                    size="large"
                                                                    name='rgn1'
                                                                    placeholder="Value 1"
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={0}
                                                            alignItems={'center'}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                            </Text>
                                                            <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                                {/* <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='format'
                              placeholder="Value 2"
                            /> */}
                                                            </Box>
                                                        </Stack>
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                        <AccordionItem
                                            w={'47%'}
                                            border={'none'}
                                            borderRadius={'6px'}
                                            mb={'30px'}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                borderRadius={'6px'}
                                                            >
                                                                Http Headers
                                                            </Box>
                                                            {isExpanded ? (
                                                                <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                            ) : (
                                                                <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                            )}
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={0} px={0}>
                                                        <Stack
                                                            bgColor={'primary.100'}
                                                            color={'#fff'}
                                                            direction={'row'}
                                                        >
                                                            <Text
                                                                py={'20px'}
                                                                w={'150px'}
                                                                borderRight={'1px solid #E8EDFC'}
                                                                pl={'20px'}
                                                            >
                                                                Content
                                                            </Text>
                                                            <Text py={'20px'} pl={'20px'}>
                                                                Application/json
                                                            </Text>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                Name
                                                            </Text>
                                                            <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                                <Input
                                                                    _focusVisible={{
                                                                        outlineOffset: '0px'
                                                                    }}
                                                                    border={'0 !important'}
                                                                    style={{ width: '82px' }}
                                                                    size="large"
                                                                    name='name'
                                                                    type='text'
                                                                    placeholder="name"
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                RGN
                                                            </Text>
                                                            <Box
                                                                w={'78%'}
                                                                p={'20px 12px'}
                                                            >
                                                                <Input
                                                                    _focusVisible={{
                                                                        outlineOffset: '0px'
                                                                    }}
                                                                    border={'0 !important'}
                                                                    style={{ width: '82px' }}
                                                                    size="large"
                                                                    name='rgn2'
                                                                    type='text'
                                                                    placeholder="value"
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        {/* <Stack direction={'row'} spacing={0}>
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            Content
                          </Text>
                          <Box
                            border={'1px solid #E8EDFC'}
                            w={'78%'}
                            p={'12px'}
                            display={'flex'}
                            alignItems={'center'}
                            gap={'10px'}
                          >
                            <CharkInput
                              border={'1px solid #E8EDFC'}
                              // style={{ width: '82px', display: 'none' }}
                              size="large"
                              placeholder="Value 2"
                              name='content'
                              type="file"
                            />
                          </Box>
                        </Stack> */}
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                                <Box w={'full'} margin={'25px 0 0 0 !important'}>
                                    <Accordion
                                        defaultIndex={[0, 1]}
                                        allowMultiple
                                        allowToggle
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={'flex-start'}
                                    >
                                        <AccordionItem
                                            w={'47%'}
                                            border={'none'}
                                            borderRadius={'6px'}
                                            mb={'15px'}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <h2>
                                                        <AccordionButton
                                                            border={0}
                                                            outlineOffset={'0px'}
                                                        >
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                borderRadius={'6px'}
                                                            >
                                                                Uri Parameters
                                                            </Box>
                                                            {isExpanded ? (
                                                                <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                            ) : (
                                                                <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                            )}
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={0} px={0}>
                                                        <Stack
                                                            bgColor={'primary.100'}
                                                            color={'#fff'}
                                                            direction={'row'}
                                                        >
                                                            <Text
                                                                py={'20px'}
                                                                w={'150px'}
                                                                borderRight={'1px solid #E8EDFC'}
                                                                pl={'20px'}
                                                            >
                                                                Variable / Key
                                                            </Text>
                                                            <Text py={'20px'} pl={'20px'}>
                                                                Value (Context Variable)
                                                            </Text>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                ac_id
                                                            </Text>
                                                            <Box w={'78%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                                                                {/* <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='rgn1'
                              placeholder="Value 1"
                            /> */}
                                                                <Select w={'200px'}>
                                                                    <option>Argument 1</option>
                                                                    <option>Argument 2</option>
                                                                </Select>
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={0}
                                                            alignItems={'center'}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                Format
                                                            </Text>
                                                            <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                                {/* <Text
                              border={'1px solid #E8EDFC'}
                              py={'20px'}
                              w={'150px'}
                              pl={'20px'}
                            > */}
                                                                json
                                                                {/* </Text> */}
                                                            </Box>
                                                        </Stack>
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                        <AccordionItem
                                            w={'47%'}
                                            border={'none'}
                                            borderRadius={'6px'}
                                            mb={'15px'}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                borderRadius={'6px'}
                                                            >
                                                                Query Parameters
                                                            </Box>
                                                            {isExpanded ? (
                                                                <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                            ) : (
                                                                <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                            )}
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={0} px={0}>
                                                        <Stack
                                                            bgColor={'primary.100'}
                                                            color={'#fff'}
                                                            direction={'row'}
                                                        >
                                                            <Text
                                                                py={'20px'}
                                                                w={'150px'}
                                                                borderRight={'1px solid #E8EDFC'}
                                                                pl={'20px'}
                                                            >
                                                                Variable / Key
                                                            </Text>
                                                            <Text py={'20px'} pl={'20px'}>
                                                                Value (Context Variable)
                                                            </Text>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                rgn
                                                            </Text>
                                                            <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                                <Select w={'200px'}>
                                                                    <option>Argument 1</option>
                                                                    <option>Argument 2</option>
                                                                </Select>
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                Format
                                                            </Text>
                                                            <Box
                                                                w={'78%'}
                                                                p={'20px 12px'}
                                                            >
                                                                {/* <Text
                              border={'1px solid #E8EDFC'}
                              py={'20px'}
                              w={'150px'}
                              pl={'20px'}
                            > */}
                                                                json
                                                                {/* </Text> */}
                                                            </Box>
                                                        </Stack>
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            </Stack>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                            >
                                <Button
                                    // isLoading={isLoading}
                                    w={'190px'}
                                    bgColor={'primary.100'}
                                    color={'#fff'}
                                    padding={'22px 0'}
                                    fontSize={'16px'}
                                    fontWeight={'500'}
                                    border={'2px solid #4A4A4A'}
                                    _hover={{
                                        bgColor: 'transparent',
                                        color: '#4A4A4A',
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    w={'190px'}
                                    bgColor={'transparent'}
                                    color={'#8A959E'}
                                    padding={'22px 0'}
                                    fontSize={'16px'}
                                    fontWeight={'500'}
                                    border={'2px solid transparent'}
                                    _hover={{
                                        borderColor: '#4A4A4A',
                                        color: '#4A4A4A',
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </SpacedStack>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={isCliOpen}
                onClose={onCliClose}
                size={'full'}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SpacedStack>
                            <Stack>
                                <Stack
                                    direction={'row'}
                                    spacing={0}
                                    gap={6}
                                    flexWrap={'wrap'}
                                    className='vendorConfigFieldsBox'
                                >
                                    <Box w={"35%"} margin={'0 0 15px 0 !important'}>
                                        <Typography.Text>Name (Unique)</Typography.Text>
                                        <Input
                                            size="md"
                                            p={4}
                                            borderRadius={6}
                                            placeholder="Production Account Configuration"
                                        />
                                    </Box>
                                    <Box w={"50%"} margin={'0 0 15px 0 !important'}>
                                        <Typography.Text>API Endpoint</Typography.Text>
                                        <Input
                                            size="md"
                                            p={4}
                                            borderRadius={6}
                                            placeholder="123xxx555xxx"
                                        />
                                    </Box>
                                </Stack>
                                <Box w={'full'} className="vendorConfigOpt">
                                    <Heading fontSize={'22px'} color={'#2626262'} margin={'30px 0 0 0'}>
                                        Parse API Response
                                    </Heading>
                                </Box>
                                <Box w={'full'} margin={'25px 0 0 0 !important'}>
                                    <Accordion
                                        defaultIndex={[0, 1]}
                                        allowMultiple
                                        allowToggle
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={'flex-start'}
                                    >
                                        <AccordionItem
                                            w={'47%'}
                                            border={'none'}
                                            borderRadius={'6px'}
                                            mb={'30px'}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <h2>
                                                        <AccordionButton
                                                            border={0}
                                                            outlineOffset={'0px'}
                                                        >
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                borderRadius={'6px'}
                                                            >
                                                                Extract Result
                                                            </Box>
                                                            {isExpanded ? (
                                                                <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                            ) : (
                                                                <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                            )}
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={0} px={0}>
                                                        <Stack
                                                            bgColor={'primary.100'}
                                                            color={'#fff'}
                                                            direction={'row'}
                                                        >
                                                            <Text
                                                                py={'20px'}
                                                                w={'150px'}
                                                                borderRight={'1px solid #E8EDFC'}
                                                                pl={'20px'}
                                                            >
                                                                Variable
                                                            </Text>
                                                            <Text py={'20px'} pl={'20px'}>
                                                                Value (JSON Path Expression)
                                                            </Text>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                Account_json
                                                            </Text>
                                                            <Box w={'78%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                                                                <Input
                                                                    _focusVisible={{
                                                                        outlineOffset: '0px'
                                                                    }}
                                                                    border={'0 !important'}
                                                                    style={{ width: '82px' }}
                                                                    size="large"
                                                                    name='rgn1'
                                                                    placeholder="Value 1"
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={0}
                                                            alignItems={'center'}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                            </Text>
                                                            <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                                {/* <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='format'
                              placeholder="Value 2"
                            /> */}
                                                            </Box>
                                                        </Stack>
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                        <AccordionItem
                                            w={'47%'}
                                            border={'none'}
                                            borderRadius={'6px'}
                                            mb={'30px'}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                borderRadius={'6px'}
                                                            >
                                                                Http Headers
                                                            </Box>
                                                            {isExpanded ? (
                                                                <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                            ) : (
                                                                <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                            )}
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={0} px={0}>
                                                        <Stack
                                                            bgColor={'primary.100'}
                                                            color={'#fff'}
                                                            direction={'row'}
                                                        >
                                                            <Text
                                                                py={'20px'}
                                                                w={'150px'}
                                                                borderRight={'1px solid #E8EDFC'}
                                                                pl={'20px'}
                                                            >
                                                                Content
                                                            </Text>
                                                            <Text py={'20px'} pl={'20px'}>
                                                                Application/json
                                                            </Text>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                Name
                                                            </Text>
                                                            <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                                <Input
                                                                    _focusVisible={{
                                                                        outlineOffset: '0px'
                                                                    }}
                                                                    border={'0 !important'}
                                                                    style={{ width: '82px' }}
                                                                    size="large"
                                                                    name='name'
                                                                    type='text'
                                                                    placeholder="name"
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                RGN
                                                            </Text>
                                                            <Box
                                                                w={'78%'}
                                                                p={'20px 12px'}
                                                            >
                                                                <Input
                                                                    _focusVisible={{
                                                                        outlineOffset: '0px'
                                                                    }}
                                                                    border={'0 !important'}
                                                                    style={{ width: '82px' }}
                                                                    size="large"
                                                                    name='rgn2'
                                                                    type='text'
                                                                    placeholder="value"
                                                                />
                                                            </Box>
                                                        </Stack>
                                                        {/* <Stack direction={'row'} spacing={0}>
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            Content
                          </Text>
                          <Box
                            border={'1px solid #E8EDFC'}
                            w={'78%'}
                            p={'12px'}
                            display={'flex'}
                            alignItems={'center'}
                            gap={'10px'}
                          >
                            <CharkInput
                              border={'1px solid #E8EDFC'}
                              // style={{ width: '82px', display: 'none' }}
                              size="large"
                              placeholder="Value 2"
                              name='content'
                              type="file"
                            />
                          </Box>
                        </Stack> */}
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                                <Box w={'full'} margin={'25px 0 0 0 !important'}>
                                    <Accordion
                                        defaultIndex={[0, 1]}
                                        allowMultiple
                                        allowToggle
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={'flex-start'}
                                    >
                                        <AccordionItem
                                            w={'47%'}
                                            border={'none'}
                                            borderRadius={'6px'}
                                            mb={'15px'}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <h2>
                                                        <AccordionButton
                                                            border={0}
                                                            outlineOffset={'0px'}
                                                        >
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                borderRadius={'6px'}
                                                            >
                                                                Uri Parameters
                                                            </Box>
                                                            {isExpanded ? (
                                                                <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                            ) : (
                                                                <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                            )}
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={0} px={0}>
                                                        <Stack
                                                            bgColor={'primary.100'}
                                                            color={'#fff'}
                                                            direction={'row'}
                                                        >
                                                            <Text
                                                                py={'20px'}
                                                                w={'150px'}
                                                                borderRight={'1px solid #E8EDFC'}
                                                                pl={'20px'}
                                                            >
                                                                Variable / Key
                                                            </Text>
                                                            <Text py={'20px'} pl={'20px'}>
                                                                Value (Context Variable)
                                                            </Text>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                ac_id
                                                            </Text>
                                                            <Box w={'78%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                                                                {/* <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='rgn1'
                              placeholder="Value 1"
                            /> */}
                                                                <Select w={'200px'}>
                                                                    <option>Argument 1</option>
                                                                    <option>Argument 2</option>
                                                                </Select>
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={0}
                                                            alignItems={'center'}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                Format
                                                            </Text>
                                                            <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                                {/* <Text
                              border={'1px solid #E8EDFC'}
                              py={'20px'}
                              w={'150px'}
                              pl={'20px'}
                            > */}
                                                                json
                                                                {/* </Text> */}
                                                            </Box>
                                                        </Stack>
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                        <AccordionItem
                                            w={'47%'}
                                            border={'none'}
                                            borderRadius={'6px'}
                                            mb={'15px'}
                                        >
                                            {({ isExpanded }) => (
                                                <>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                                borderRadius={'6px'}
                                                            >
                                                                Query Parameters
                                                            </Box>
                                                            {isExpanded ? (
                                                                <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                            ) : (
                                                                <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                            )}
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={0} px={0}>
                                                        <Stack
                                                            bgColor={'primary.100'}
                                                            color={'#fff'}
                                                            direction={'row'}
                                                        >
                                                            <Text
                                                                py={'20px'}
                                                                w={'150px'}
                                                                borderRight={'1px solid #E8EDFC'}
                                                                pl={'20px'}
                                                            >
                                                                Variable / Key
                                                            </Text>
                                                            <Text py={'20px'} pl={'20px'}>
                                                                Value (Context Variable)
                                                            </Text>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                rgn
                                                            </Text>
                                                            <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                                <Select w={'200px'}>
                                                                    <option>Argument 1</option>
                                                                    <option>Argument 2</option>
                                                                </Select>
                                                            </Box>
                                                        </Stack>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={0}
                                                        >
                                                            <Text
                                                                border={'1px solid #E8EDFC'}
                                                                py={'20px'}
                                                                w={'150px'}
                                                                pl={'20px'}
                                                            >
                                                                Format
                                                            </Text>
                                                            <Box
                                                                w={'78%'}
                                                                p={'20px 12px'}
                                                            >
                                                                {/* <Text
                              border={'1px solid #E8EDFC'}
                              py={'20px'}
                              w={'150px'}
                              pl={'20px'}
                            > */}
                                                                json
                                                                {/* </Text> */}
                                                            </Box>
                                                        </Stack>
                                                    </AccordionPanel>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            </Stack>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                            >
                                <Button
                                    // isLoading={isLoading}
                                    w={'190px'}
                                    bgColor={'primary.100'}
                                    color={'#fff'}
                                    padding={'22px 0'}
                                    fontSize={'16px'}
                                    fontWeight={'500'}
                                    border={'2px solid #4A4A4A'}
                                    _hover={{
                                        bgColor: 'transparent',
                                        color: '#4A4A4A',
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    w={'190px'}
                                    bgColor={'transparent'}
                                    color={'#8A959E'}
                                    padding={'22px 0'}
                                    fontSize={'16px'}
                                    fontWeight={'500'}
                                    border={'2px solid transparent'}
                                    _hover={{
                                        borderColor: '#4A4A4A',
                                        color: '#4A4A4A',
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </SpacedStack>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
