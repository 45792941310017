import { WarningIcon } from '@chakra-ui/icons'
import { Box, Button, Icon, Image, Stack, Text } from '@chakra-ui/react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

export default function DesiredButton({ id, icon, name, selectedDesireOption, setSelectedDesireOption, data, index, activeIcon }) {

    return (
        <Button
            key={id}
            w={{ base: "100%", md: "68%", lg: "40%", xl: "32%" }}
            background={'#F0F0F0'}
            border={'1px solid'}
            borderColor={name === selectedDesireOption.name ? '#1E86FF' : '#EBF4FF'}
            borderRadius={'3px'}
            padding={'10px 10px'}
            _hover={{ bgColor: '#F0F0F0' }}
            _active={{ bgColor: '#F0F0F0' }}
            onClick={() => {
                 setSelectedDesireOption({
                    name:name,
                    index : index,
                    icon: icon
                })
               
            }}
        >
            <Stack
                w={'full'}
                direction={'row'}
                alignItems={'center'}
                gap={'10px'}
                justifyContent={'space-between'}
            >
                <Box
                    display={'flex'}
                    gap={'10px'}
                    alignItems={'center'}
                >
                    <Image src={name === selectedDesireOption.name ? activeIcon : icon ?? ''} />
                    {/* <Icon as={icon ?? WarningIcon} color="blue" /> */}
                    <Text
                        fontSize={'14px'}
                        fontWeight={'600'}
                        color={name === selectedDesireOption?.name ? '#1E86FF' : '#8A959E'}
                        textwrap={'initial'}
                        className='textWrapInitial'
                    >
                        {name ?? ''}
                    </Text>
                </Box>
                {/* <Icon fontWeight={'700'} fontSize={'17px'} as={name === selectedDesireOption ? BsChevronUp : BsChevronDown} /> */}
            </Stack>
        </Button>
    )
}
