import { Select } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { Handle, Position, useReactFlow, useNodesInitialized } from 'reactflow';
import IconNode from './CustomIcon';
import { IoIosWarning } from 'react-icons/io';
import { POST } from '../../utilities/ApiProvider';
import { useToast } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const handleStyle = { left: 10 };
const nodeTypes = { IconDisplay: IconNode };

const options = {
  includeHiddenNodes: false,
};
const fitviewOption = {
  includeHiddenNodes: true,
  duration: 0.1,
};

const SelectionNode = ({ data }) => {
  const toast = useToast();
  const { loadingState } = data;
  const nodesInitialized = useNodesInitialized(options);
  const [createdNodes, setCreatedNodes] = useState([]);
  const [createdEdges, setCreatedEdges] = useState([]);
  const [animated, setAnimated] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const hibernatingErrorMessage =
    'Could not extract response: no suitable HttpMessageConverter found for response type [com.cipherstack.itsm.servicenow.model.servicenow.ServiceNowTableResponse<com.cipherstack.itsm.servicenow.dto.IssueRequest>] and content type [text/html]';

  const reactFlowInstance = useReactFlow();
  const onChange = useCallback(
    async evt => {
      let value = JSON.parse(evt);
      console.log('Evt Value', value);
      let totalNodeValue = reactFlowInstance.getNodes();
      if (totalNodeValue.length >= 3) {
        const nodePayload = createdNodes.map(id => ({ id }));
        const edgePayload = createdEdges.map(id => ({ id }));
        reactFlowInstance.deleteElements({
          nodes: nodePayload,
          edges: edgePayload,
        });
        setCreatedNodes([]);
        setCreatedEdges([]);
        createNodes(value || data?.totalIds || 0);
      } else {
        createNodes(value || data?.totalIds || 0);
      }
    },
    [createdNodes, createdEdges]
  );

  const createNodes = async count => {
    if (!count?.items) {
      return;
    }
    setisLoading(true);
    // loadingState(true);
    const newIds = [];
    const newEdgeIds = [];
    let totalItem = count?.items?.length || count;
    let finalResult = [];
    if (data?.selectedVal === 'Regulatory Compliance and Governance') {
      for (let val of count?.items) {
        for (let i = 0; i < val?.items.length; i++) {
          if (val?.items[i]?.resultIds) {
            try {
              let response = await POST(`api/template/compliance/results`, [
                val?.items[i]?.resultIds[0],
              ]);
              // console.log(`single Res ${[i]}`, response);
              if (response?.message === hibernatingErrorMessage) {
                toast({
                  description:
                    'Unable to reach servicenow server, Please visit dev. servicenow!',
                  status: 'error',
                  position: 'top-right',
                  duration: 4500,
                  isClosable: true,
                });
              } else if (response?.status === 400) {
                toast({
                  description: 'Invalid data provided!',
                  status: 'error',
                  position: 'top-right',
                  duration: 3000,
                  isClosable: true,
                });
              } else {
                finalResult.push(response?.data[0]);
                // console.log('result in', response?.data[0]);

                const id = `${++nodeId}`;
                newIds.push(id); // Collect new ID
                const newNode = {
                  id,
                  type: 'IconDisplay',
                  position: {
                    x: 700,
                    y: id * 80,
                  },
                  data: {
                    label: val?.items[i]?.key || `Node ${id}`,
                    nodeData: response?.data[0],
                    icon:
                      response?.data[0]?.result?.vulnerable === true
                        ? 'IoIosWarning'
                        : 'FaCloud',
                  },
                  targetPosition: Position.Left,
                };
                reactFlowInstance.addNodes(newNode);
                const edge = {
                  id: `edge-${id + 1}`,
                  source: '1', // Assuming the source node id is 'a'
                  target: id,
                  sourceHandle: 'a',
                  style: {
                    strokeWidth: 2,
                    stroke: 'linear-gradient(to right, #1966B9, #C52421)',
                  },
                };
                newEdgeIds.push(`edge-${id}`);
                reactFlowInstance.addEdges(edge);

                // setSingleComplianceData(prev => [...prev, response?.data[0]]);
                // toast({
                //     description: 'Compliance Fetched!',
                //     status: 'success',
                //     position: 'top-right',
                //     duration: 3000,
                //     isClosable: true,
                // })
              }
            } catch (err) {
              console.error(err?.message);
              // toast({
              //     description: err?.message ?? 'Something went wrong in third party integration',
              //     status: 'error',
              //     position: 'bottom-left',
              //     duration: 3000,
              //     isClosable: true,
              // })
            }
          }
        }
      }
    } else {
      console.log('Total Item', count.items);
      for (let val of count?.items) {
        if (val?.resultIds) {
          try {
            console.log('Result Ids', val?.key);
            let response = await POST(`api/template/compliance/results`, [
              val?.resultIds[0],
            ]);
            if (response?.message === hibernatingErrorMessage) {
              toast({
                description:
                  'Unable to reach servicenow server, Please visit dev. servicenow!',
                status: 'error',
                position: 'top-right',
                duration: 4500,
                isClosable: true,
              });
            } else if (response?.status === 400) {
              toast({
                description: 'Invalid data provided!',
                status: 'error',
                position: 'top-right',
                duration: 3000,
                isClosable: true,
              });
            } else {
              finalResult.push(response?.data[0]);
              const id = `${++nodeId}`;
              newIds.push(id); // Collect new ID
              const newNode = {
                id,
                type: 'IconDisplay',
                position: {
                  x: 700,
                  y: id * 80,
                },
                data: {
                  label: val?.key || `Node ${id}`,
                  nodeData: response?.data[0],
                  icon:
                    response?.data[0]?.result?.vulnerable === true
                      ? 'IoIosWarning'
                      : 'FaCloud',
                },
                targetPosition: Position.Left,
              };
              reactFlowInstance.addNodes(newNode);
              const edge = {
                id: `edge-${id + 1}`,
                source: '1', // Assuming the source node id is 'a'
                target: id,
                sourceHandle: 'a',
                style: {
                  strokeWidth: 2,
                  stroke: 'linear-gradient(to right, #1966B9, #C52421)',
                },
              };
              newEdgeIds.push(`edge-${id}`);
              reactFlowInstance.addEdges(edge);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    }

    if (nodesInitialized) {
      console.log('fit view triggered');
      setTimeout(() => reactFlowInstance?.fitView(fitviewOption), 50);
    }
    // loadingState(false);
    setisLoading(false);
    setCreatedNodes(newIds);
    setCreatedEdges(newEdgeIds);
  };

  // Custom function to handle node click
  const onClickCustomNode = (e_, data) => {
    console.log('custom node clicked', data);
  };

  const combineValues = data => {
    let nistdata = data?.values?.find(
      val => val?.name === 'NIST AI-RMF'
    )?.items;
    let owaspData = data?.values?.find(val => val?.name === 'OWASP')?.items;
    let totalData = [...nistdata, ...owaspData];
    setTotalData(totalData);
  };

  let nodeId = (data?.totalIds || 0) + 1;

  useEffect(() => {
    // hack to avoid animation on first render; for some reason nodes were animating from position 0
    // to their initial position
    setAnimated(true);
    combineValues(data);
  }, [data]);

  // console.log('Loading State', loadingState);

  return (
    <motion.div
      className="animated-node"
      layout={animated}
      // create new component when animated changes, see issue workaround https://github.com/framer/motion/issues/2238#issue-1809290539
      key={data?.id?.concat(animated.toString())}
    >
      <div className="select-updater-node nodrag">
        {/* <Handle type="target" position={Position.Left} /> */}
        <div>
          <img
            src={
              data?.img ||
              'https://media.istockphoto.com/id/1389937723/vector/cartoon-planet-earth-3d-vector-icon-on-white-background.jpg?s=612x612&w=0&k=20&c=hntEYVS5xepGQi1AIpRipUTYnH2Tp_S1TXS5M-pQe3A='
            }
            alt="earth"
            height={50}
            width={80}
            objectFit={'contain'}
            draggable="false"
          />
        </div>
        {/* <Handle type="source" position={Position.Bottom} id="a" /> */}
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '110%',
          left: '50%',
          transform: 'translate(-50%)',
        }}
      >
        <Select
          size="sm"
          placeholder={data?.placeholder || 'Select option'}
          id="text"
          name="text"
          onChange={onChange}
          className="nodrag"
          disabled={isLoading}
        >
          {data?.selectedVal === 'Regulatory Compliance and Governance'
            ? data?.values?.map((val, ind) => (
                <option
                  value={JSON.stringify(val) || val}
                  style={{
                    width: '100%',
                  }}
                >
                  {val?.name ? val?.name : val}
                </option>
              ))
            : totalData?.map((val, ind) => (
                <option
                  value={JSON.stringify(val) || val}
                  style={{
                    width: '100%',
                  }}
                >
                  {val?.name ? val?.name : val}
                </option>
              ))}
        </Select>
      </div>
    </motion.div>
  );
};

export default SelectionNode;
