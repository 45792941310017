import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { evidenceButtons, evidenceData } from '../../utilities/EnterPriseData';

const RiskTable = () => {
  const [selectedRisk, setSelectedRisk] = useState({});
  return (
    <>
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
      >
        <Heading color={'#262626'} fontWeight={700} fontSize={16}>
          Evidence - Risk Triage
        </Heading>
        <Stack
          display={'flex'}
          direction={'row'}
          gap={'10px'}
          alignItems={'center'}
        >
          {evidenceButtons?.map((v, i) => (
            <Button
              key={v?.id}
              w={'fit-content'}
              background={'#F0F0F0'}
              border={'1px solid'}
              borderColor={
                v?.name === selectedRisk?.name ? '#1E86FF' : '#EBF4FF'
              }
              borderRadius={'3px'}
              padding={'10px 10px'}
              _hover={{ bgColor: '#F0F0F0' }}
              _active={{ bgColor: '#F0F0F0' }}
              onClick={() => {
                setSelectedRisk({
                  name: v?.name,
                  index: v?.id,
                });
              }}
            >
              <Text
                fontSize={'14px'}
                fontWeight={'600'}
                color={v?.name === selectedRisk?.name ? '#1E86FF' : '#8A959E'}
              >
                {v?.name ?? ''}
              </Text>
            </Button>
          ))}
        </Stack>
      </Stack>
      <Stack pt={6} width={'100%'}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr bgColor={'#555555'} border={'1px solid #555555'}>
                <Th textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Flagged</Th>
                <Th textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Title</Th>
                <Th textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>User</Th>
                <Th textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Interface</Th>
                <Th textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Last updated </Th>
                <Th textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Engine</Th>
                <Th textAlign={"center"} color={'#fff'} fontSize={'13px'} fontWeight={'500'}>Topics</Th>
              </Tr>
            </Thead>
            <Tbody>
              {evidenceData?.map((data, index) => (
                <Tr key={index}>
                  <Td>{data.flagged}</Td>
                  <Td>{data.title}</Td>
                  <Td>{data.user}</Td>
                  <Td>{data.interface}</Td>
                  <Td>{data.lastUpdated}</Td>
                  <Td>{data.engine}</Td>
                  <Td>{data.topics}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default RiskTable;
