import {
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const CoreComponent = ({ item, selectedCore, setSelectedCore }) => {
  console.log(selectedCore, 'selectedCore');
  console.log(item, 'selectedCore Item');
  return (
    <Button
      key={item?.id}
      background={item?.heading === selectedCore ? '#EBF4FF' : '#F1F4F8'}
      border={
        item?.heading === selectedCore
          ? '2px solid #1E86FF'
          : '1px solid #E2E8F0'
      }
      borderRadius={'md'}
      height={'auto'}
      width={'100%'}
      padding={'35px 10px'}
      _hover={{ bgColor: '#f0f4ff' }}
      _active={{ bgColor: '#f0f4ff' }}
      onClick={() => {
        console.log(`Setting selected core to: ${item?.heading}`);
        setSelectedCore(item?.heading);
      }}
    >
      <Grid templateColumns="1fr" gap={4} alignItems="center">
        {/* Icon */}
        {item?.icon && (
          <Box display="flex" justifyContent="center">
            <Image
              src={item?.icon}
              alt={item.heading}
              height={8}
              width={8}
              draggable={false}
              color={'#1E86FF'}
            />
          </Box>
        )}

        {/* Heading */}
        <Heading
          fontSize={'18px'}
          fontWeight={'600'}
          color={'#262626'}
          textAlign="center"
          whiteSpace="normal" // Allows text to wrap within container
        >
          {item?.heading || 'No Heading'}
        </Heading>

        {/* Description */}
        <Text
          fontSize={'14px'}
          textAlign="center"
          color={'#4A5568'}
          whiteSpace="normal" // Allows text to wrap within container
        >
          {/* {item?.description || 'No Description'} */}
        </Text>
      </Grid>
    </Button>
  );
};

export default CoreComponent;
