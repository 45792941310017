import { Avatar, Box, Heading, Icon, Stack, Text, Toast, useToast } from '@chakra-ui/react';
import LocationHeader from '../components/LocationHeader';
import SideWrapper from '../components/SideBar/SideWrapper';
import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { GET } from '../utilities/ApiProvider';
import { useEffect, useState } from 'react';

const Notifications = () => {

  var toast = useToast();
  const [notifications, setNotifications] = useState([])

  const getNotifications = async () => {
    var response = await GET("api/v1/notification/");
    if (response?.success) {
      setNotifications(response.data);
    } else {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: response?.message,
      })
    }
  }


  const getElapsedTime = (date1, date2) => {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    if (d1.getFullYear() == d2.getFullYear()) {

      if (d1.getMonth() == d1.getMonth()) {

        if (d1.getDate() == d1.getDate()) {

          if(d1.getHours() == d2.getHours()) {
            return parseInt(((d1.getTime() - d2.getTime()) / (1000 * 60))) +" minutes ago";
            
          }else {
            return parseInt(((d1.getTime() - d2.getTime()) / (1000 * 60 * 60))) +" hours ago";
          }
        } else {
        return parseInt(((d1.getTime() - d2.getTime())/ (1000 * 60 * 60 * 24))) +" days ago";
      }
    } else {
      //convert to months;
    }
  } else {
    //convert to years
  }
}

  useEffect(() => {
    getNotifications();
  }, []);


  return (

    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        {/* Current Location identifier */}
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <LocationHeader
            forcedText={'Notifications'}
          />
        </Stack>
        {notifications.map((v, i) =>
          <Stack borderRadius={'12px'} p="15px 10px" bgColor={'#fff'} maxW={'80%'} mb="20px !important">
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} gap="20px">
                <Box>
                  <Avatar title={v.userId} name={v.userId}></Avatar>
                </Box>
                <Box>
                  <Text textTransform={'capitalize'} fontWeight={600} fontSize={{ base: 13, md: 14 }}>{v.userId}</Text>
                  <Text fontSize={{ base: 13, md: 14 }}>{v.message}</Text>
                </Box>
              </Box>
              <Box textAlign={'right'}>
                <Icon as={FaCircle} fontSize={'8px'} color={'green.400'} />
  
                <Text color={'#adadad'} fontSize={'14px'} p="10px 0 0 0">{v.createdOn}</Text>
              </Box>
            </Stack>
          </Stack>


        )}



      </Stack>
    </SideWrapper >
  );
};

export default Notifications;
