import { Text } from '@chakra-ui/react';
import React from 'react';
import { useStore } from 'reactflow';

const TextNode = ({ data, id }) => {
  const label = useStore(s => {
    const node = s.nodeInternals.get(id);

    if (!node) {
      return null;
    }

    return `position x:${parseInt(node.position.x)} y:${parseInt(
      node.position.y
    )}`;
  });

  return (
    <div className="text-node nodrag">
      <Text fontWeight={'600'} fontSize={'25px'}>
        {data?.text}
      </Text>
      {/* {label || 'no node connected'} */}
    </div>
  );
};

export default TextNode;
