import { Box, Button, Stack, Text } from '@chakra-ui/react';
import React from 'react';

const OnBoardButton = ({
  id,
  icon,
  name,
  selectedOnBoarding,
  setSelectedOnBoarding,
  data,
  index,
}) => {
  return (
    <Button
      key={id}
      w={'fit-content'}
      background={'#F0F0F0'}
      border={'1px solid'}
      borderColor={name === selectedOnBoarding?.name ? '#1E86FF' : '#EBF4FF'}
      borderRadius={'3px'}
      padding={'10px 20px'}
      _hover={{ bgColor: '#F0F0F0' }}
      _active={{ bgColor: '#F0F0F0' }}
      onClick={() => {
        setSelectedOnBoarding({
          name: name,
          index: index,
        });
      }}
    >
      <Stack
        w={'full'}
        direction={'row'}
        alignItems={'center'}
        gap={'10px'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} gap={'10px'} alignItems={'center'}>
          <Text
            fontSize={'14px'}
            fontWeight={'600'}
            color={name === selectedOnBoarding?.name ? '#1E86FF' : '#8A959E'}
            textWrap={'initial'}
            className="textWrapInitial"
          >
            {name ?? ''}
          </Text>
        </Box>
      </Stack>
    </Button>
  );
};

export default OnBoardButton;
