import gpt from '../assets/images/enterpriseAi/gpt.png';
import hug from '../assets/images/enterpriseAi/hug.png';
import meta from '../assets/images/enterpriseAi/meta.png';
import github from '../assets/images/enterpriseAi/github.png';
import copilot from '../assets/images/enterpriseAi/copilot.png';
import automated from '../assets/images/enterpriseAi/automated.png';
import server from '../assets/images/enterpriseAi/server.png';
import application from '../assets/images/enterpriseAi/application.png';
import cloud from '../assets/images/enterpriseAi/cloud.png';
import oracle from '../assets/images/enterpriseAi/oracle.png';
import amazon from '../assets/images/companies/amazon.png'
import cisco from '../assets/images/companies/cisco.png'
import azure from '../assets/images/companies/azure.png'
import zoom from '../assets/images/companies/zoom.png'
import juniper from '../assets/images/companies/juniper.png'
import fortinet from '../assets/images/companies/fortinet.png'
import human from '../assets/images/enterpriseAi/human.png';
import govern from '../assets/images/enterpriseAi/govern.png';
import crowd from '../assets/images/third-party-images/crowdstrike.png'

const onBoardButtons = [
  { id: 1, name: 'CSP' },
  { id: 2, name: 'CRM, SaaS Apps' },
  { id: 3, name: 'On-Premises/Edge' },
  { id: 4, name: 'AI Agents' },
  { id: 5, name: 'Collaboration/Cx' },
];

const evidenceButtons = [
  { id: 1, name: 'Agents' },
  { id: 2, name: 'Applications' },
  { id: 3, name: 'Data' },
  { id: 4, name: 'Infrastructure' },
  { id: 5, name: 'LLMOPs' },
];
const evidenceData = [
  {
    flagged: '9b748910',
    title: 'Src: 10.0.43.173 - Dst: 10.0.244.121',
    user: 'No',
    interface: 'Data Privacy - High Data',
    lastUpdated: '2024-01-01',
    engine: 'No',
    topics: 'Cost - Low Security',
  },
  {
    flagged: '4678623a',
    title: 'Src: 10.0.3.21 - Dst: 10.0.64.166',
    user: 'Yes',
    interface: 'Data Privacy - Low Data',
    lastUpdated: '2024-03-06',
    engine: 'Yes',
    topics: 'Cost - High Security',
  },
  {
    flagged: '76e4a024',
    title: 'Src: 10.0.43.173 - Dst: 10.0.244.121',
    user: 'Yes',
    interface: 'Data Privacy - Medium Data',
    lastUpdated: '2024-11-09',
    engine: 'No',
    topics: 'Cost - Medium Security',
  },
  {
    flagged: '12345678',
    title: 'Src: 10.0.1.1 - Dst: 10.0.2.2',
    user: 'No',
    interface: 'Data Privacy - Low Data',
    lastUpdated: '2024-12-04',
    engine: 'Yes',
    topics: 'Cost - High Security',
  },
  {
    flagged: 'abcdefgh',
    title: 'Src: 10.0.5.5 - Dst: 10.0.6.6',
    user: 'Yes',
    interface: 'Data Privacy - High Data',
    lastUpdated: '2024-01-05',
    engine: 'Yes',
    topics: 'Cost - Low Security',
  },
];

const VisibilityData = [
  {
    id: 1,
    agent: 'OpenAI',
    icon: gpt,
    risk: 'Medium',
  },
  {
    id: 2,
    agent: 'Hugging Face',
    icon: hug,
    risk: 'Low',
  },
  {
    id: 3,
    agent: 'OpenAI',
    icon: meta,
    risk: 'Medium',
  },
  {
    id: 4,
    agent: 'Copilot',
    icon: copilot,
    risk: 'Medium',
  },
  {
    id: 5,
    agent: 'GitHubCopilot',
    icon: github,
    risk: 'Medium',
  },
];

const sercurityData = [
  {
    id: 1,
    workflow: 'Collect & Prepare Data',
    risk: 'Medium',
    icon: server,
  },
  {
    id: 2,
    workflow: 'Model Design & Development',
    risk: 'Low',
    icon: application,
  },
  {
    id: 3,
    workflow: 'Infrastructure and Operations',
    risk: 'High',
    icon: automated,
  },
];

const governanceData = [
  {
    id: 1,
    agent: 'OWASP Top 10 for LLM',
    data: server,
    infra: cloud,
    application: oracle,
  },
  {
    id: 2,
    agent: govern,
    data: automated,
    infra: amazon,
    application: juniper,
  },
  {
    id: 3,
    agent: human,
    data: application,
    infra: azure,
    application: cisco,
  },
  {
    id: 4,
    agent: 'ZERO TRUST',
    data: gpt,
    infra: zoom,
    application: oracle,
  },
];

const hostData = [
  {
    id: '64eea4fbbe0efb1385e1f689',
    name: 'Amazon Web Services',
    iconPath: '/path/icon.png',
    key: 'AWS',
    implementation:
      'com.cipher.ruleengine.rule.template.model.vendor.impl.AWSVendor',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'CSP'
  },
  {
    id: '64eea4fbbe0efb1385e1f68a',
    name: 'Microsoft Azure',
    iconPath: '/path/icon.png',
    key: 'AZURE',
    implementation:
      'com.cipher.ruleengine.rule.template.model.vendor.impl.AzureVendor',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'CSP'
  },
  {
    id: '64eea4fbbe0efb1385e1f68b',
    name: 'Google Cloud Services',
    iconPath: '/path/icon.png',
    key: 'GCP',
    implementation:
      'com.cipher.ruleengine.rule.template.model.vendor.impl.GCPVendor',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'CSP'
  },
  {
    id: '64eea4fbbe0efb1385e1f68c',
    name: 'Microsoft Teams',
    iconPath: '/path/msteams.png',
    key: 'MSTEAMS',
    implementation:
      'com.cipher.ruleengine.rule.template.model.vendor.impl.MicrosoftTeamsVendor',
    cloudDisabled: null,
    policyDisabled: true,
    category: 'Collaboration/Cx'
  },
  {
    id: '64eea4fbbe0efb1385e1f68d',
    name: 'WebEx',
    iconPath: '/path/webex.png',
    key: 'WEBEX',
    implementation:
      'com.cipher.ruleengine.rule.template.model.vendor.impl.WebexVendor',
    cloudDisabled: null,
    policyDisabled: true,
    category: 'Collaboration/Cx'
  },
  {
    id: '65020ebaf59e1f53c913cd8d',
    name: 'Zoom',
    iconPath: '/path/zoom.png',
    key: 'ZOOM',
    implementation:
      'com.cipher.ruleengine.rule.template.model.vendor.impl.ZoomVendor',
    cloudDisabled: null,
    policyDisabled: true,
    category: 'Collaboration/Cx'
  },
  {
    id: '65283c747d749879b308b839',
    name: 'Okta',
    iconPath: null,
    key: 'OKTA',
    implementation:
      'com.cipher.ruleengine.rule.template.model.vendor.impl.OktaVendor',
    cloudDisabled: null,
    policyDisabled: true,
    category: 'CRM, SaaS Apps'
  },
  {
    id: '65597306546a307ce07bcad7',
    name: 'Palo Alto',
    iconPath: '/path/paloalto.png',
    key: 'PALOALTO',
    implementation:
      'com.cipher.ruleengine.rule.template.model.vendor.impl.PaloAltoVendor',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'On-Premises/Edge'
  },
  {
    id: '65e5ef4437a5b58af7775802',
    name: 'SAP',
    iconPath: '/path/sap.png',
    key: 'SAP',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'CRM, SaaS Apps'
  },
  {
    id: '65e5ef6237a5b58af7775804',
    name: 'Salesforce',
    iconPath: '/path/salesforce.png',
    key: 'SALESFORCE',
    implementation: '',
    cloudDisabled: true,
    policyDisabled: true,
    category: 'CRM, SaaS Apps'
  },
  {
    id: '65e5ef7437a5b58af7775806',
    name: 'Oracle',
    iconPath: '/path/racle.png',
    key: 'ORACLE',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'CRM, SaaS Apps'
  },
  {
    id: '65e5ef8537a5b58af7775808',
    name: 'Arista',
    iconPath: '/path/arista.png',
    key: 'ARISTA',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'On-Premises/Edge'
  },
  {
    id: '65e5ef9537a5b58af777580a',
    name: 'Cisco',
    iconPath: '/path/cisco.png',
    key: 'CISCO',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'On-Premises/Edge'
  },
  {
    id: '65e5efa537a5b58af777580c',
    name: 'Juniper',
    iconPath: '/path/juniper.png',
    key: 'JUNIPER',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'On-Premises/Edge'
  },
  {
    id: '65e5efa537a5b58af727580c',
    name: 'GPT',
    iconPath: '/path/gpt.png',
    key: 'GPT',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'AI Agents'
  },
  {
    id: '65e5efa537a5b58ad777580c',
    name: 'Hugging Face',
    iconPath: '/path/huggingFace.png',
    key: 'HUGGINGFACE',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'AI Agents'
  },
  {
    id: '65e5efa537a5b58af777589v',
    name: 'GitHub',
    iconPath: '/path/github.png',
    key: 'GITHUBCOPILOT',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'AI Agents'
  },
  {
    id: '65e5efa537a5b58af777510p',
    name: 'MS Copilot',
    iconPath: '/path/msCopilot.jpg',
    key: 'MSCOPILOT',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'AI Agents'
  },
  {
    id: '65e5efa537a5b58af777580l',
    name: 'Gemini',
    iconPath: '/path/gemini.png',
    key: 'GEMINI',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'AI Agents'
  },
  {
    id: '65e5efa537a5b58af771999c',
    name: 'Llama2',
    iconPath: '/path/llama2.jpg',
    key: 'LLAMA2',
    implementation: '',
    cloudDisabled: null,
    policyDisabled: null,
    category: 'AI Agents'
  },
];

export {
  onBoardButtons,
  VisibilityData,
  sercurityData,
  governanceData,
  evidenceButtons,
  hostData,
  evidenceData
};
