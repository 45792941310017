import { Box, Breadcrumb, Modal, BreadcrumbItem, BreadcrumbLink, Button, Heading, Icon, Image, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, useDisclosure, useToast, Spinner } from "@chakra-ui/react";
import riskLogo from '../../../assets/images/desired-icons/riskLogo.svg'
import { Form, Input, Typography, useBreakpointValue } from "antd";
import { VscSearch } from "react-icons/vsc";
import { useEffect, useRef, useState } from "react";
import EnterpriseRiskLineChart from "./EnterpriseRiskLineChart";
import EnterpriseRiskPieChart from "./EnterpriseRiskPieChart";
import EnterpriseRiskTable from "./EnterpriseRiskTable";
import { GET, POST } from "../../../utilities/ApiProvider";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import TestFlow from "../../Test/TestFlow";
import VisualFlow from "../VisualFlow";

export default function EnterpriseRiskTolerance({ sideMenu, selectedIcon, selectedName }) {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const hibernatingErrorMessage = "Could not extract response: no suitable HttpMessageConverter found for response type [com.cipherstack.itsm.servicenow.model.servicenow.ServiceNowTableResponse<com.cipherstack.itsm.servicenow.dto.IssueRequest>] and content type [text/html]";
    const user = useSelector(state => state.value);
    const serviceNowUsers = useSelector(state => state.serviceNowUsers);
    const serviceNowAuth = useSelector(state => state.serviceNowAuth);
    const { isOpen: isAssignOpen, onOpen: onAssignOpen, onClose: onAssignClose } = useDisclosure()
    const [riskSummaryList, setSiskSummaryList] = useState(['Host Cloud', 'Peer 1', 'Peer 2', 'Infrastructure', 'WLAN', 'Application', 'Data', 'Identity', 'End Points', 'Edge', 'Remote/Mobile', 'Visibility/Analytics', 'Generate Report']);
    const [selectedSummaryItem, setSelectedSummaryItem] = useState('WLAN');
    const [data, setData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [formattedInfo, setformattedInfo] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [fetchingCompliance, setFetchingCompliance] = useState(false);

    const [singleComplianceData, setSingleComplianceData] = useState([]);

    const getData = async () => {
        setFetchingCompliance(true);
        try {
            const res = await POST(
              'api/template/compliance/result/vendor/AZURE/from/2024-05-09/to/2024-05-10'
            );
            setData([res?.data[res?.data?.length - 1]]);
            console.log(res?.data[res?.data?.length - 1])
        } catch (err) {
            console.log(err)
        }
        setFetchingCompliance(false);
    }

    const selectedValue = (id) => {
        const findData = data.find((val) => val.id === id);
        if (findData?.compliance?.compliance && findData?.compliance?.compliance?.length > 0) {
            const myData = findData?.compliance?.compliance[0].items;
            console.log(myData)
            setDatas(myData);
        }
    }

    function evaluateInformationToCsvFormat(csvResponse) {
        console.log(csvResponse)
        let properFormat = csvResponse?.map(val => {
            return {
                ...val,
                data: String(val.data).replace(/,/g, "|"),
                gdpr: val?.gdpr?.length > 0 ? JSON.stringify(...val?.gdpr).replace(/,/g, "|") : null,
                hipaa: val?.hipaa?.length > 0 ? JSON.stringify(...val?.hipaa).replace(/,/g, "|") : null,
                iso: val?.iso?.length > 0 ? JSON.stringify(...val?.iso).replace(/,/g, "|") : null,
                pci: val?.pci?.length > 0 ? JSON.stringify(val?.pci).replace(/,/g, "|") : null,
                ransomware: val?.ransomware?.length > 0 ? JSON.stringify(val?.ransomware).replace(/,/g, "|") : null,
                tags: val?.tags?.length > 0 ? JSON.stringify(val?.tags).replace(/,/g, "|") : null,
            }
        });
        console.log(properFormat)
        setformattedInfo(properFormat);
    }

    useEffect(() => {
        let formattedForm = [];
        if (datas?.length > 0) {
            datas.map((v, i) => {
                if (v?.items?.length > 0) {
                    return v?.items.map((val, ind) => {
                        formattedForm.push(val?.results[Object.keys(val?.results)[0]]);
                    })
                }
            })
        }
        if (formattedForm.length > 0) evaluateInformationToCsvFormat(formattedForm);
    }, [datas]);

    const [isLoading, setIsLoading] = useState(false);
    const [assignData, setAssignData] = useState({
        customer_id: "22",
        number: "1234556",
        caller_id: "seqoria2@testtest.com",
        type: "",
        active: "",
        description: "",
        short_description: "",
        urgency: "",
        assigned_to: "",
        close_notes: "",
        comments: "",
        category: "",
        fix_notes: ""
    });


    useEffect(() => {
        console.log(user)
        getData();
    }, []);

    const serviceNowIssue = async () => {
        setIsLoading(true);
        try {
            let response = await POST(`api/itsm/servicenow/issue`, assignData, { bearerToken: `${serviceNowAuth?.access_token}` });
            console.log(response);
            if (response?.message === hibernatingErrorMessage) {
                toast({
                    description: 'Unable to reach servicenow server, Please visit dev. servicenow!',
                    status: 'error',
                    position: 'top-right',
                    duration: 4500,
                    isClosable: true,
                })
            } else if (response?.status === 400) {
                toast({
                    description: 'Invalid data provided!',
                    status: 'error',
                    position: 'top-right',
                    duration: 3000,
                    isClosable: true,
                })
            } else {
                toast({
                    description: 'Issue assigned successfully!',
                    status: 'success',
                    position: 'top-right',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (err) {
            toast({
                description: err?.message ?? 'Something went wrong in third party integration',
                status: 'error',
                position: 'bottom-left',
                duration: 3000,
                isClosable: true,
            })
        }
        setIsLoading(false)
        onAssignClose();
    }



    const categoryChangeHandler = async (dataArr) => {
        setSingleComplianceData([]);
        setFetchingCompliance(true)
        let receivedArr = JSON.parse(dataArr);
        console.log(receivedArr)
        if (receivedArr?.length > 0) {
            setIsLoading(true);
            let finalResult = [];
            receivedArr?.map((val) => {
                if (val?.items?.length > 0) {
                    val?.items.map(async (val) => {
                        if (val?.resultIds) {
                            try {
                                let response = await POST(`api/template/compliance/results`, [val?.resultIds[0]]);
                                console.log(response);
                                if (response?.message === hibernatingErrorMessage) {
                                    toast({
                                        description: 'Unable to reach servicenow server, Please visit dev. servicenow!',
                                        status: 'error',
                                        position: 'top-right',
                                        duration: 4500,
                                        isClosable: true,
                                    })
                                } else if (response?.status === 400) {
                                    toast({
                                        description: 'Invalid data provided!',
                                        status: 'error',
                                        position: 'top-right',
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                } else {
                                    finalResult.push(response?.data[0]);
                                    console.log(finalResult)
                                    setSingleComplianceData(prev => [...prev, response?.data[0]]);
                                    // toast({
                                    //     description: 'Compliance Fetched!',
                                    //     status: 'success',
                                    //     position: 'top-right',
                                    //     duration: 3000,
                                    //     isClosable: true,
                                    // })
                                }
                            } catch (err) {
                                console.error(err?.message)
                                // toast({
                                //     description: err?.message ?? 'Something went wrong in third party integration',
                                //     status: 'error',
                                //     position: 'bottom-left',
                                //     duration: 3000,
                                //     isClosable: true,
                                // })
                            }
                        }
                    })
                }
            })
            setIsLoading(false)
        }
        setFetchingCompliance(false)
    }

    return (
        <Stack>
            {/* <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={{ base: "center", lg: "flex-end" }}
                flexWrap={"wrap"}
                className="breadCrumMain"
            >
                <Breadcrumb separator='|'>
                    <BreadcrumbItem>
                        <BreadcrumbLink href='#'>noah.gonzales@meraki.com</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink href='#'>my profile</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink href='#'>sign out</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink onClick={() => { onOpen() }}>demo networks</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink onClick={() => { onAssignOpen() }}>show admin</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Stack> */}
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexDirection={{ base: "column", lg: "flex-start" }}
                padding={'0 0 10px 0'}
                margin={'0 0 10px 0'}
            >
                {/* <Stack

                    display={{ base: 'none', lg: 'flex' }}
                    direction={{ base: "column", lg: "row" }}
                    alignItems={'center'}
                    flex={5}
                    className="logoSideBox"
                    gap={'20px'}
                    w={'100%'}
                >
                    <Image src={riskLogo} w={{ base: "30px", md: "40px", lg: "60px", xl: "85px" }} mr={'6%'} />
                    <Box>
                        <Typography.Text className="net">Network</Typography.Text>
                        <Form.Item name="network">
                            <Select
                                _focusVisible={{ outline: 'none' }}
                                border={'1px solid #d9d9d9'}
                                borderRadius={2}
                            >
                                <option style={{ display: 'none' }} value={''}>Meraki Corp - Wireless</option>
                                <option value='aws'>option 1</option>
                                <option value='gcp'>option 2</option>
                            </Select>
                        </Form.Item>
                    </Box>
                    <Box>
                        <Typography.Text>SSID</Typography.Text>
                        <Form.Item name="network">
                            <Select
                                _focusVisible={{ outline: 'none' }}
                                border={'1px solid #d9d9d9'}
                                borderRadius={2}
                            >
                                <option style={{ display: 'none' }} value={''}>All</option>
                                <option value='aws'>option 1</option>
                                <option value='gcp'>option 2</option>
                            </Select>
                        </Form.Item>
                    </Box>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        flex={1}
                        justifyContent={'flex-end'}
                    >
                        <Icon as={VscSearch} fontWeight={'extrabold'} fontSize={'20px'} />
                        <Form.Item name="ruleName">
                            <Input
                                style={{ borderRadius: 2, width: '200px' }}
                                size="large"
                                placeholder="Search dashboard"
                            />
                        </Form.Item>
                    </Stack>
                </Stack> */}
            </Stack>
            <Stack
                direction={{ base: "column", lg: "row" }}
                w={'100%'}
            >
                {/* <Box
                    w={{ base: "100%", lg: '200px' }}
                    bgColor={'#F2F2F2'}
                    display={!sideMenu ? 'none' : 'initial'}
                >
                    <Heading
                        fontSize={{ base: "8px", md: "10px", lg: '14px' }}
                        fontWeight={'700'}
                        padding={'10px 0 10px 10px'}
                        borderLeft={'6px solid #A9A9A9'}
                        borderBottom={'1px solid #adadad'}
                    >Enterprise Risk Summary</Heading>
                    <Box className="riskSummaryList">
                        <ul>
                            {
                                riskSummaryList?.map((v, i) =>
                                    <li className={selectedSummaryItem === v ? 'current' : ''} key={i} onClick={() => setSelectedSummaryItem(v)}>{v}</li>
                                )
                            }
                        </ul>
                    </Box>
                </Box> */}
                <Box w={!sideMenu ? '100%' : '84%'}>
                    {/* <Stack
                        direction={{ base: "column", xl: "row" }}
                        justifyContent={'space-between'}
                    >
                        <Box w='75%'>
                            <Stack
                                direction={{ base: "column", lg: "row" }}
                                alignItems={'center'}
                                gap={'25px'}
                            >
                                <Heading
                                    fontSize={'14px'}
                                    padding={'8px 0'}
                                    fontWeight={700}
                                >Enterprise Risk History</Heading>
                                <Stack
                                    direction={'row'}
                                >
                                    <Text fontWeight={'700'} fontSize={{ base: "10px", lg: "14" }}>Jul 09 17:56 PDT</Text>
                                    <Text fontSize={'13px'}>to</Text>
                                    <Text fontWeight={'700'} fontSize={'13px'}>Jul 16 17:56 PDT</Text>
                                </Stack>
                                <Text fontSize={'13px'}>268.87 GB (203.57 GB received, 65.3 GB sent)</Text>
                            </Stack>
                            <Stack>
                                <EnterpriseRiskLineChart />
                            </Stack>
                        </Box>
                        <Stack w={{ base: "40%", md: "", lg: "15%" }} marginRight={"20px"} justifyContent={{ base: "center", lg: "none" }}>
                            <EnterpriseRiskPieChart />
                        </Stack>
                    </Stack> */}
                    {/* <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        padding={'10px 0'}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={'15px'}
                        >
                            <Select
                                _focusVisible={{ outline: 'none' }}
                                border={'1px solid #d9d9d9'}
                                borderRadius={2}
                                w={'300px'}
                            >
                                <option style={{ display: 'none' }}>Asset Type - ID</option>
                                <option>Asset Type - ID 1</option>
                                <option>Asset Type - ID 2</option>
                                <option>Asset Type - ID 3</option>
                                <option>Asset Type - ID 4</option>
                            </Select>
                            <Select
                                _focusVisible={{ outline: 'none' }}
                                border={'1px solid #d9d9d9'}
                                borderRadius={2}
                                // onChange={(e) => { selectedValue(e.target.value) }}
                                w={'300px'}
                                onChange={e => categoryChangeHandler(e.target.value)}
                            >
                                <option style={{ display: 'none' }}>Category</option>
                                {
                                    data &&
                                    data?.map((item) => {
                                        // Check if the "compliance" property and its "compliance" array exist
                                        if (item?.compliance?.compliance) {
                                            return item?.compliance?.compliance?.map((complianceItem) => {
                                                if (complianceItem?.items?.length > 0) {
                                                    return complianceItem?.items?.map((complianceItemTwo, index) => {
                                                        if (complianceItemTwo?.items?.length > 0) {
                                                            // return complianceItemTwo?.items?.map((complianceItemThree, index) => {
                                                            // if (complianceItemThree?.items[complianceItemThree?.items?.length - 1]?.resultIds) {
                                                            return (
                                                                <option key={index} value={JSON.stringify(complianceItemTwo?.items)}>{complianceItemTwo?.name}</option>
                                                                // <option key={index} value={JSON.stringify(complianceItemThree?.items)}>{complianceItemThree?.name}</option>
                                                            )
                                                            // };
                                                            // });
                                                        }

                                                    });
                                                }
                                                // return (
                                                //     <option key={item?.id} value={item?.trackingId}>{complianceItem?.name}</option>
                                                // );
                                            });
                                        }
                                        // Return null or an empty array if "compliance" doesn't exist
                                        return null;
                                    })
                                }


                            </Select>
                            {
                                fetchingCompliance && <Spinner />
                            }
                        </Box>
                        <Box>
                            <CSVLink
                                style={{
                                    padding: '8px 12px',
                                    borderRadius: '6px',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    background: "#222"
                                }}
                                data={formattedInfo}
                            >
                                Download as CSV
                            </CSVLink>
                        </Box>
                    </Stack> */}
                    {/* <EnterpriseRiskTable onOpen={onOpen} datas={datas} SingleComplianceData={singleComplianceData} isLoading={isLoading} onAssignOpen={onAssignOpen} /> */}
                    {selectedName && (
                        <VisualFlow selectedVal={selectedName} icon={selectedIcon}/>
                    )}
                </Box>
            </Stack>
            <Modal isOpen={isOpen} onClose={onClose} size={'lg'} padding={'40px'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Warning</ModalHeader>
                    <ModalBody>
                        <Text fontFamily={"Inter"} fontSize={'16px'} colo={'#667085'} marginBottom={'20px'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </Text>
                        <Box
                            border={'1px solid #adadad'}
                            padding={'10px'}
                            cursor={'pointer'}
                        >
                            <Text fontSize={'15px'} fontFamily={'Inter'}>Select rule info</Text>
                            <Select
                                cursor={'pointer'}
                                border={'none'}
                                _focusVisible={{ outline: 'none' }}
                            >
                                <option>Malicious</option>
                                <option>As Per Design</option>
                                <option>Accept Risk</option>
                            </Select>
                        </Box>
                        <Stack
                            mt={'30px !important'}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                w={'190px'}
                                bgColor={'#4A4A4A'}
                                color={'#fff'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid #4A4A4A'}
                                _hover={{
                                    bgColor: 'transparent',
                                    color: '#4A4A4A'
                                }}
                            >Yes</Button>
                            <Button
                                onClick={() => onClose()}
                                w={'80px'}
                                bgColor={'transparent'}
                                color={'#8A959E'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid transparent'}
                                _hover={{
                                    borderColor: '#4A4A4A',
                                    color: '#4A4A4A'
                                }}
                            >No</Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal isOpen={isAssignOpen} onClose={onAssignClose} size={'2xl'} padding={'40px'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Assign Issue(s)</ModalHeader>
                    <ModalBody>
                        <Text fontFamily={"Inter"} fontSize={'16px'} colo={'#667085'} marginBottom={'20px'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore </Text>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            mb={'10px'}
                        >
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}
                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Select rule info</Text>
                                <Select
                                    cursor={'pointer'}
                                    border={'none'}
                                    _focusVisible={{ outline: 'none' }}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            type: e.target.value
                                        })
                                    }}
                                >
                                    <option value={''} style={{ display: 'none' }}></option>
                                    <option value={'INCIDENT'}>Service Now</option>
                                    <option value={'INCIDENT'}>INCIDENT</option>
                                </Select>
                            </Box>
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}
                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Active Status</Text>
                                <Select
                                    cursor={'pointer'}
                                    border={'none'}
                                    _focusVisible={{ outline: 'none' }}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            active: e.target.value
                                        })
                                    }}
                                >
                                    <option value={''} style={{ display: 'none' }}></option>
                                    <option value={'true'}>Active</option>
                                    <option value={'false'}>In active</option>
                                </Select>
                            </Box>
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            mb={'10px'}
                        >
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}

                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Description</Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            description: e.target.value
                                        })
                                    }}
                                />
                            </Box>
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}

                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Short Description</Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            short_description: e.target.value
                                        })
                                    }}
                                />
                            </Box>
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            mb={'10px'}
                        >
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}

                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>SLA</Text>
                                <Select
                                    cursor={'pointer'}
                                    border={'none'}
                                    _focusVisible={{ outline: 'none' }}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            urgency: e.target.value
                                        })
                                    }}
                                >
                                    <option value={''} style={{ display: 'none' }}></option>
                                    <option value={'HIGH'}>between 8-24 hrs</option>
                                    <option value={'MEDIUM'}>1-3 days</option>
                                    <option value={'LOW'}>3-7 days</option>
                                </Select>
                            </Box>
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}

                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Assign to </Text>
                                <Select
                                    cursor={'pointer'}
                                    border={'none'}
                                    _focusVisible={{ outline: 'none' }}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            assigned_to: e.target.value
                                        })
                                    }}
                                >
                                    {
                                        serviceNowUsers?.users?.length > 0 ?
                                            <option value={''} style={{ display: 'none' }}></option>
                                            :
                                            <option value={''} style={{ display: 'none' }}>No user found!</option>
                                    }
                                    {
                                        serviceNowUsers?.users?.length > 0 &&
                                        serviceNowUsers?.users?.map(val =>
                                            <option key={val?.id} value={val?.email}>{val?.username}</option>
                                        )
                                    }
                                </Select>
                            </Box>
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            mb={'10px'}
                        >
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}

                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Close Notes</Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            close_notes: e.target.value
                                        })
                                    }}
                                />
                            </Box>
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}

                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Comments</Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            comments: e.target.value
                                        })
                                    }}
                                />
                            </Box>
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            mb={'10px'}
                        >
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}
                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Category</Text>
                                <Select
                                    cursor={'pointer'}
                                    border={'none'}
                                    _focusVisible={{ outline: 'none' }}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            category: e.target.value
                                        })
                                    }}
                                >
                                    <option value={''} style={{ display: 'none' }}></option>
                                    <option value={'ZeroTrust'}>Zero Trust</option>
                                </Select>
                            </Box>
                            <Box
                                w={'49%'}
                                borderRadius={'8px'}
                                border={'1px solid #adadad'}
                                padding={'10px'}
                                cursor={'pointer'}
                            >
                                <Text fontSize={'15px'} fontFamily={'Inter'}>Fix Notes</Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    onChange={e => {
                                        setAssignData({
                                            ...assignData,
                                            fix_notes: e.target.value
                                        })
                                    }}
                                />
                            </Box>
                        </Stack>
                        <Stack
                            mt={'30px !important'}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                isLoading={isLoading}
                                onClick={() => serviceNowIssue()}
                                w={'190px'}
                                bgColor={'#4A4A4A'}
                                color={'#fff'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid #4A4A4A'}
                                _hover={{
                                    bgColor: 'transparent',
                                    color: '#4A4A4A'
                                }}
                            >Assign</Button>
                            <Button
                                onClick={() => { onAssignClose(); }}
                                w={'80px'}
                                bgColor={'transparent'}
                                color={'#8A959E'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid transparent'}
                                _hover={{
                                    borderColor: '#4A4A4A',
                                    color: '#4A4A4A'
                                }}
                            >Cancel</Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Stack>
    )
}