import React, { createContext } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import RouteSwitch from './RouteSwitch';
import NotFound from '../views/NotFound';
import Auth from '../views/Auth';
import CloudOnboarding from '../views/CloudOnboarding';
import DesiredOutComes from '../views/DesiredOutcomes';
import DeviceApplication from '../views/DeviceApplication';
import SolutionBuilder from '../views/SolutionBuilder';
import DiscoveryInventory from '../views/DiscoveryInventory';
import RiskSimulation from '../views/RiskSimulation';
import BusinessSimulation from '../views/BusinessSimulation';
import Multifactor from '../views/Multifactor';
import ThridPartyInteg from '../views/ThirdPartyInteg';
import Policy from '../views/Policy';
import NeighborConfig from '../views/NeighborConfig';
import RestApi from '../views/RestApi';
import Rule from '../views/Rule';
import NavGpt from '../views/NavGpt';
import Outllook from '../views/Outlook';
import EnterpriseAiData from '../views/EnterpriseAiData';
import Notifications from '../views/Notification';
import TestFlow from '../components/Test/TestFlow';
import EnterPriseAI from '../views/EnterpriseAI';
import Agentic from '../views/Agentic';
import AgenticIndex from '../views/AgenticIndex';

export default function AppRoute() {
  return (
    <div>
      <Router>
        <RouteSwitch>
          <Route exact path="/" element={<Auth />}></Route>
          <Route exact path="/Multifactor" element={<Multifactor />}></Route>
          <Route
            exact
            path="/cloud-onboarding"
            element={<CloudOnboarding />}
          ></Route>
          <Route
            exact
            path="/desired-outcomes"
            element={<DesiredOutComes />}
          ></Route>
          <Route
            exact
            path="/device-application"
            element={<DeviceApplication />}
          ></Route>
          <Route
            exact
            path="/solution-builder"
            element={<SolutionBuilder />}
          ></Route>
          <Route
            exact
            path="/discover-inventory"
            element={<DiscoveryInventory />}
          ></Route>
          <Route
            exact
            path="/risk-simulation"
            element={<RiskSimulation />}
          ></Route>
          <Route
            exact
            path="/business-simulation"
            element={<BusinessSimulation />}
          ></Route>
          <Route exact path="/policy-generator" element={<Policy />}></Route>
          <Route exact path="/security-outlook" element={<Outllook />}></Route>
          <Route
            exact
            path="/third-party-integration"
            element={<ThridPartyInteg />}
          ></Route>
          <Route
            exact
            path="/neighbor-zone-config"
            element={<NeighborConfig />}
          ></Route>
          <Route exact path="/rest-api" element={<RestApi />}></Route>
          <Route exact path="/rule" element={<Rule />}></Route>
          <Route exact path="/navGPT" element={<NavGpt />}></Route>
          <Route
            exact
            path="/enterpriseAiData"
            element={<EnterpriseAiData />}
          ></Route>
          <Route exact path="/dashboard" element={<EnterPriseAI />}></Route>
          <Route
            exact
            path="/notifications"
            element={<Notifications />}
          ></Route>
          <Route exact path="/test" element={<TestFlow />}></Route>
          <Route exact path="/agentic-ai-risk" element={<Agentic />}></Route>
          <Route
            exact
            path="/agentic-workflow-oversight"
            element={<AgenticIndex />}
          ></Route>
          <Route exact path="*" element={<NotFound />}></Route>
        </RouteSwitch>
      </Router>
    </div>
  );
}
