import { Icon, Stack, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { BsChevronRight } from 'react-icons/bs'

export default function LocationHeader({ optionsList, followingRoute, forcedText }) {
    // console.log(followingRoute)
    const location = useLocation();
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            flexWrap={"wrap"}
            fontWeight={'500'}
        >
            {
                forcedText ?
                    <Text
                        textTransform={'capitalize'}
                        color={'#8A959E'}
                    >{forcedText}</Text>
                    :
                    <Text
                        textTransform={'capitalize'}
                        color={'#8A959E'}
                    >
                        {location.pathname.split('-')[0].replace('/', '')} {location.pathname.split('-')[1]}
                    </Text>
            }
            {
                // optionsList ?
                followingRoute &&
                followingRoute?.map((v, i) => {
                    if (v) return (
                        optionsList ?
                            <Stack
                                // key={i}
                                direction={'row'}
                                alignItems={'center'}
                            >
                                {
                                    optionsList &&
                                    <Text
                                        color={'#8A959E'}
                                        as={'span'}
                                    >
                                        <Icon as={BsChevronRight} fontSize={12} />
                                        {optionsList[i]}
                                    </Text>
                                }
                                <Text margin={'0 0 0 5px !important'} color={'#8A959E'} as={'span'} fontSize={13}>{`( ${v} )`}</Text>
                            </Stack>
                            :
                            <Text
                                color={'#8A959E'}
                                as={'span'}
                            >
                                <Icon as={BsChevronRight} fontSize={12} mr={'5px'} />
                                {v}
                            </Text>
                    )
                })
            }
        </Stack >
    )
}




