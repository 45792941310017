import { Stack } from '@chakra-ui/react'
import React from 'react'
import SimpleSidebar from './index.js'

export default function SideWrapper({children}) {
  return (
    <Stack
        direction={'row'}
        gap={{base:"30px",md:"60px",lg:"120px"}}
        bgColor={'#F6F6F6'}
        className='mainWrapper'
    >
        <SimpleSidebar />
        {children}
    </Stack>
  )
}
