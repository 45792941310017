import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import { Icon } from '@chakra-ui/icon'
import { Box } from '@chakra-ui/layout'
import React from 'react'

export default function SolutionSubOptBox({ id, name, selectedItems, removeSelectedItem, customName, children }) {

    let totalItems = selectedItems ?? [];

    return (
        <Box
            cursor={'pointer'}
            textAlign={'center'}
            borderRadius={'6px'}
            fontSize={'14px'}
            fontFamily={'Inter'}
            padding={'12px 30px'}
            bgColor={'#f0f0f0'}
            border={'1px solid'}
            borderColor={totalItems.includes(children ? customName : name) ? '#1E86FF' : '#E8EDFC'}
            color={totalItems.includes(children ? customName : name) ? '#1E86FF' : '#000'}
            display={'flex'}
            alignItems={'center'}
            fontWeight={'500'}
            justifyContent={'center'}
            onClick={() => removeSelectedItem(children ? customName : name)}
        >
            <Icon as={totalItems.includes(children ? customName : name) ? CheckOutlined : PlusOutlined} mr={'10px'} />
            {children ? customName : name}
        </Box>
    )
}
