import {
  Box, Stack, Heading, MenuButton, Menu,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Icon
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SideWrapper from '../components/SideBar/SideWrapper.js';
import LocationHeader from '../components/LocationHeader/index.js';
import NotificationIcon from '../components/LocationHeader/NotificationIcon.js';
import { useEffect, useState } from 'react';
import { desiredOptions, genPolicyData, desiredNewButton } from '../utilities/desiredOutcomesData.js';
import DesiredButton from '../components/DesiredOutcomes/DesiredButtons.js';
import SpacedStack from '../components/SpacedStack/index.js';
import { Form, Typography, Input } from 'antd';
import CyberResiliency from '../components/DesiredOutcomes/CyberResiliency.js';
import { GET, POST } from '../utilities/ApiProvider.js';
import { FaBell } from 'react-icons/fa';
import EnterpriseRiskTolerance from '../components/DesiredOutcomes/EnterpriseRiskTolerance/EnterpriseRiskTolerance.js';
import VulnerabilityManagement from '../components/DesiredOutcomes/VulnerabilityManagement/VulnerabilityManagement.js';
import EnhanceControl from '../components/DesiredOutcomes/EnhanceControl/EnhanceControl.js';
import ZeroTrustPosture from '../components/DesiredOutcomes/ZeroTrustPosture.js';
import GenAiPostureManagement from '../components/DesiredOutcomes/GenAiPostureManagement/Index.js'

export default function DesiredOutComes() {
  const navigation = useNavigate();
  const user = useSelector(state => state?.value);
  const [data, setData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [selectedDesireOption, setSelectedDesireOption] = useState({});

  // if(user === undefined || user == null){
  //   navigation('/auth');
  // }


  return (
    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        {/* Current Location identifier */}
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <LocationHeader followingRoute={[selectedDesireOption.name]} />
          <NotificationIcon />
        </Stack>
        {/* Cloud options */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'10px'}
          padding={'35px 30px'}
          background={'#fff'}
          borderRadius={'10px'}
          marginBottom={'20px !important'}
          spacing={0}
          flexWrap={'wrap'}
        >
          <Heading w="100%" fontSize={'22px'} margin={'0 0 20px 0'}>
            Generative / AI Workflow
          </Heading>
          {desiredNewButton?.map((v, i) => (
            <DesiredButton
              {...v}
              key={v.id}
              index={i}
              selectedDesireOption={selectedDesireOption}
              setSelectedDesireOption={setSelectedDesireOption}
            />
          ))}
        </Stack>
        {selectedDesireOption.name === desiredNewButton[1]['name'] ?
          <Stack>
            {/* <GenAiPostureManagement /> */}
            <EnterpriseRiskTolerance sideMenu={false} selectedName={selectedDesireOption?.name} selectedIcon={selectedDesireOption?.icon}/>
          </Stack>
          : selectedDesireOption.name === desiredNewButton[0]['name'] ?
            // <ZeroTrustPosture />
            <EnterpriseRiskTolerance sideMenu={false} selectedName={selectedDesireOption?.name} selectedIcon={selectedDesireOption?.icon}/>
            : selectedDesireOption.name === desiredNewButton[4]['name'] ?
              <SpacedStack>
                <VulnerabilityManagement />
              </SpacedStack>
              : selectedDesireOption.name === desiredNewButton[3]['name'] ?
                <SpacedStack>
                  <GenAiPostureManagement />
                </SpacedStack>
                : selectedDesireOption.name === desiredNewButton[6]['name'] ?
                  <SpacedStack>
                    <EnhanceControl />
                  </SpacedStack>
                  : selectedDesireOption.name === '' ? <></> : (
                    <Stack>
                      <EnterpriseRiskTolerance sideMenu={false} selectedName={selectedDesireOption?.name} selectedIcon={selectedDesireOption?.icon}/>
                    </Stack>
                  )}
      </Stack>
    </SideWrapper>
  );
}
