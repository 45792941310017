import {
  Box,
  Button,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import CoreComponent from '../components/AgenticAI/CoreComponent';
import Insight from '../components/AgenticAI/Insight';
import LocationHeader from '../components/LocationHeader';
import NotificationIcon from '../components/LocationHeader/NotificationIcon';
import SideWrapper from '../components/SideBar/SideWrapper';
import { coreData } from '../utilities/agenticAIData';
import { GET, POST } from '../utilities/ApiProvider';

const Agentic = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCore, setSelectedCore] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [displayUpload, setDisplayUpload] = useState(false);
  const fileInputRef = useRef();
  const [tagData, setTagData] = useState(null);

  const startDataFetching = async () => {
    setIsLoading(true);
    if (localStorage.getItem('accessToken')) {
      try {
        let res = await POST(
          'api/v1/dataconnotation/tag_data',
          {},
          {},
          false,
          'secondary'
        );
        // Check if the response returns an error
        if (res.error) {
          console.error('Error fetching data', res);
          setIsLoading(false);
          toast({
            position: 'bottom-left',
            isClosable: true,
            duration: 5000,
            status: 'error',
            description: res.error.message,
          });
          return;
        } else {
          console.log('Dil ka dariya', res);
          setIsLoading(false);
          setDataFetched(true);
          toast({
            position: 'bottom-left',
            isClosable: true,
            duration: 5000,
            status: 'success',
            description: 'Data fetched successfully',
          });
          return res;
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      setIsLoading(false);
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: 'Access token is missing',
      });
      return;
    }
  };

  const readFetchedData = async () => {
    if (localStorage.getItem('accessToken')) {
      try {
        let res = await GET(
          'api/v1/dataconnotation/read_tagged_data',
          {},
          'secondary'
        );
        setTagData(res);
        return res;
      } catch (error) {
        console.log(error);
      }
    } else {
      toast({
        position: 'bottom-left',
        isClosable: true,
        duration: 5000,
        status: 'error',
        description: 'Access token is missing',
      });
      return;
    }
  };

  function handleFileChange(event) {
    console.log(event.target.files);
  }

  function handleButtonClick() {
    fileInputRef.current.click(); // Programmatically trigger the file input click
  }

  return (
    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <LocationHeader
            forcedText={'Dashboard'}
            followingRoute={['Agentic AI Risk & Workflow Optimization Hub']}
          />
          <NotificationIcon />
        </Stack>

        {/* Header Section */}
        <Stack
          direction={'column'}
          gap={'10px'}
          padding={'25px 20px'}
          background={'#fff'}
          borderRadius={'10px'}
          marginBottom={'7px !important'}
          spacing={0}
          alignItems={'center'}
          justifyContent={'center'}
          flexWrap={'wrap'}
        >
          <Heading
            w="100%"
            fontSize={'22px'}
            margin={'0 0 20px 0'}
            alignItems={'center'}
            textAlign={'center'}
          >
            Agentic AI Risk & Workflow Optimization Hub
          </Heading>
          <Text w="100%" color={'#555555'} textAlign={'center'}>
            Identify and remove inefficiencies across Agent-Agent, Human-Agent,
            and Agent-Data/Application interaction. (Including RAG, RLHF, and
            HITL)
          </Text>
        </Stack>

        {/* Content Section */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderRadius={'10px'}
          marginBottom={'7px !important'}
          spacing={0}
          // flexWrap={'wrap'}
          width="100%"
        >
          <Box
            padding={'20px'}
            background={'#fff'}
            direction={'row'}
            width="100%"
            rounded={'md'}
          >
            <Heading
              w="100%"
              fontSize={'22px'}
              margin={'0 0 20px 0'}
              alignItems={'center'}
            >
              Core Capabilities
            </Heading>
            {/* <Stack
              direction={'row'}
              alignItems={'center'}
              // gap={'10px'}
              // padding={'35px 30px'}
              background={'#fff'}
              // borderRadius={'10px'}
              marginBottom={'20px !important'}
              flexWrap={'wrap'}
              borderRadius={'10px'}
              spacing={0}
              overflow={'hidden'}
            >
              {coreData.map((item, index) => (
                <CoreComponent
                  item={item}
                  key={index}
                  selectedCore={selectedCore}
                  setSelectedCore={setSelectedCore}
                />
              ))}
            </Stack> */}
            <SimpleGrid columns={4} spacing={6}>
              {coreData.map((item, index) => (
                <CoreComponent
                  item={item}
                  selectedCore={selectedCore}
                  setSelectedCore={setSelectedCore}
                />
              ))}
            </SimpleGrid>

            <Stack
              mt={14}
              direction={'row'}
              alignItems={'center'}
              width={'100%'}
              justifyContent={'flex-end'}
            >
              <Button
                variant={'outline'}
                borderColor={'#4A4A4A'}
                onClick={() => setDisplayUpload(!displayUpload)}
                isLoading={isLoading}
                isDisabled={isLoading || dataFetched}
              >
                Train/Fine-Tune Model with your Data
              </Button>
              <Button
                backgroundColor={'#4A4A4A'}
                color={'#fff'}
                _hover={{
                  backgroundColor: '#4A4A4A',
                }}
                onClick={startDataFetching}
                isLoading={isLoading}
                isDisabled={isLoading || dataFetched}
              >
                Start/Resume Processing
              </Button>
              <Button
                isDisabled={isLoading || !dataFetched}
                variant={'outline'}
                borderColor={'#4A4A4A'}
                onClick={readFetchedData}
              >
                Extract Insights
              </Button>
              <Button variant={'outline'} borderColor={'#4A4A4A'}>
                Edit Selection
              </Button>
            </Stack>
            {/* Upload Data Section */}
            {displayUpload && (
              <Stack
                direction="column"
                gap="10px"
                padding="25px 20px"
                background="#fff"
                borderRadius="10px"
                marginBottom="7px!important"
                spacing={0}
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                <Heading
                  w="100%"
                  fontSize="22px"
                  margin="0 0 10px 0"
                  alignItems="center"
                  textAlign="center"
                >
                  Enterprise Modal Training & Fine-Tuning
                </Heading>
                <Text w="100%" color="#555555" textAlign="center">
                  Upload your data to start processing
                  <br />
                  <br />
                  <Button
                    backgroundColor="#4A4A4A"
                    color="#fff"
                    _hover={{
                      backgroundColor: '#4A4A4A',
                    }}
                    onClick={handleButtonClick}
                  >
                    Upload Data
                  </Button>
                  <Input
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    display="none"
                  />
                </Text>
              </Stack>
            )}
          </Box>
        </Stack>

        {/* Tag Data Section */}
        {tagData && (
          <Stack
            alignItems={'center'}
            borderRadius={'10px'}
            marginBottom={'7px !important'}
            spacing={0}
            // flexWrap={'wrap'}
            width="100%"
          >
            <Insight data={tagData} />
          </Stack>
        )}
      </Stack>
    </SideWrapper>
  );
};

export default Agentic;
