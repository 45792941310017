import { Text } from '@chakra-ui/react';
import {

BaseEdge,
EdgeLabelRenderer,
getStraightPath,
useReactFlow,
} from 'reactflow';

export default function CustomEdge({
id,
sourceX,
sourceY,
targetX,
targetY,
text,
data, // Add a color prop
}) {
const { setEdges } = useReactFlow();
const [edgePath, labelX, labelY] = getStraightPath({
  sourceX,
  sourceY,
  targetX,
  targetY,
});

return (
  <>
    <BaseEdge id={id} path={edgePath} style={{ stroke: data?.customColor }} />
  </>
);
}
