import { Box, Stack, Heading, Button, Accordion, AccordionItem, AccordionButton, Icon, AccordionPanel, Text, Input as CharkInput, Select } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SideWrapper from '../components/SideBar/SideWrapper.js';
import SpacedStack from '../components/SpacedStack/index.js'
import LocationHeader from '../components/LocationHeader/index.js';
import { useState } from 'react';
import { Typography, Input } from 'antd';
import {
    MinusSquareOutlined,
    PlusSquareOutlined,
} from '@ant-design/icons';

export default function Rule() {
    const navigation = useNavigate();
    const user = useSelector(state => state?.value);
    const [data, setData] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [selectedDesireOption, setSelectedDesireOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // if(user === undefined || user == null){
    //   navigation('/auth');
    // }


    return (
        <SideWrapper>
            <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
                {/* Current Location identifier */}
                <LocationHeader forcedText={'Desired Outcomes > Enhance Control Coverage > Rule'} />
                {/* Cloud options */}
                <SpacedStack>
                    <Stack>
                        <Stack
                            direction={'row'}
                            spacing={0}
                            gap={6}
                            flexWrap={'wrap'}
                            className='vendorConfigFieldsBox'
                        >
                            <Box w={"25%"} margin={'0 0 15px 0 !important'}>
                                <Typography.Text>Name (Unique)</Typography.Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    placeholder="Production Account Configuration"
                                />
                            </Box>
                            <Box w={"25%"} margin={'0 0 15px 0 !important'}>
                                <Typography.Text>API Endpoint</Typography.Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    placeholder="123xxx555xxx"
                                />
                            </Box>
                        </Stack>
                        <Box w={'full'} className="vendorConfigOpt">
                            <Heading fontSize={'22px'} color={'#2626262'} margin={'30px 0 0 0'}>
                                Check Vulnerability
                            </Heading>
                        </Box>
                        <Stack
                            mb={'30px !important'}
                            direction={'row'}
                            spacing={0}
                            gap={6}
                            flexWrap={'wrap'}
                            className='vendorConfigFieldsBox'
                        >
                            <Box w={"15%"} margin={'0 0 15px 0 !important'}>
                                <Select height={"30px"} mt={'30px !important'}>
                                    <option>Argument</option>
                                    <option>Account_regions all_vms</option>
                                </Select>
                            </Box>
                            <Box w={"25%"} margin={'0 0 15px 0 !important'}>
                                <Typography.Text>API Endpoint</Typography.Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    placeholder="S[*].vms"
                                />
                            </Box>
                        </Stack>
                        <Stack
                            direction={'row'}
                            spacing={0}
                            gap={6}
                            flexWrap={'wrap'}
                            className='vendorConfigFieldsBox'
                            mb='30px !important'
                        >
                            <Box w={"25%"} margin={'0 0 15px 0 !important'}>
                                <Typography.Text>Impact</Typography.Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    placeholder="S[*].vms"
                                />
                            </Box>
                            <Box w={"25%"} margin={'0 0 15px 0 !important'}>
                                <Typography.Text>Recommendation</Typography.Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    placeholder="S[*].vms"
                                />
                            </Box>
                            <Box w={"25%"} margin={'0 0 15px 0 !important'}>
                                <Typography.Text>Ease of Remediation</Typography.Text>
                                <Input
                                    size="md"
                                    p={4}
                                    borderRadius={6}
                                    placeholder="S[*].vms"
                                />
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                    >
                        <Button
                            isLoading={isLoading}
                            w={'190px'}
                            bgColor={'primary.100'}
                            color={'#fff'}
                            padding={'22px 0'}
                            fontSize={'16px'}
                            fontWeight={'500'}
                            border={'2px solid #4A4A4A'}
                            _hover={{
                                bgColor: 'transparent',
                                color: '#4A4A4A',
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            w={'190px'}
                            bgColor={'transparent'}
                            color={'#8A959E'}
                            padding={'22px 0'}
                            fontSize={'16px'}
                            fontWeight={'500'}
                            border={'2px solid transparent'}
                            _hover={{
                                borderColor: '#4A4A4A',
                                color: '#4A4A4A',
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </SpacedStack>

            </Stack>
        </SideWrapper>
    );
}
