import { Box, Button, Heading, Icon, Select, Stack, Table, Tbody, Td, Thead, Tr, Tooltip, useToast } from "@chakra-ui/react";
import { Form, Input, Spin, Typography } from "antd";
import { Spinner, Text } from '@chakra-ui/react'
import { useEffect, useRef, useState } from "react";
import { GET, POST } from "../../../utilities/ApiProvider";
import { CSVLink } from "react-csv";
import { FaTimes } from "react-icons/fa";
import { async } from "q";
import EnterpriseRiskTolerance from "../EnterpriseRiskTolerance/EnterpriseRiskTolerance";

export default function VulnerabilityManagement() {

    const toast = useToast();
    const [vendorData, setVendorData] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState('amazon');
    const downloadCveRed = useRef(null);
    const [selectData, setSelectData] = useState('amazon');
    const [loadData, setLoadData] = useState(false);
    const [addProdLoader, setAddProdLoader] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [data, setData] = useState([]);
    const [spin, setSpin] = useState(false);
    const [load, setLoad] = useState(false);
    const [bool, setBool] = useState(false);
    const [cpeData, setCpeData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [addProductVal, setAddProductVal] = useState("");
    const [listOfAddProduct, setListOfAddProduct] = useState([]);
    const [downloadProductsData, setDownloadProductsData] = useState([]);

    const getVendorsData = async () => {
        const res = await GET("api/template/cve/vendors");
        setVendorData(res);
    }

    useEffect(() => {
        getVendorsData();
    }, []);

    function convertToCsvFormat(csvResponse) {
        let properFormat = csvResponse?.map(val => {
            return {
                ...val,
                metrics: JSON.stringify(...val?.metrics).replace(/,/g, "|"),
                weaknesses: JSON.stringify(...val?.weaknesses).replace(/,/g, "|"),
                references: JSON.stringify(...val?.references).replace(/,/g, "|"),
                cpeMatch: JSON.stringify(...val?.cpeMatch).replace(/,/g, "|"),
                cpes: JSON.stringify(val?.cpes).replace(/,/g, "|"),
            }
        });
        setCsvData(properFormat)
    }

    function convertProductListCsvFormat(csvResponse) {
        let properFormat = csvResponse?.map(val => {
            if (val?.refs) {
                return {
                    ...val,
                    refs: JSON.stringify(...val?.refs).replace(/,/g, "|"),
                }
            } else {
                return val
            }
        });
        setDownloadProductsData(properFormat);
    }

    // Get cves of a vendor
    const getData = async () => {
        setLoad(true);
        const res = await GET(`api/template/cve/cves/vendor?vendor=${selectData}&csv=false`);
        setData(res);
        convertToCsvFormat(res);
        setLoad(false);
    }

    // Get registered products of  
    const getCpeData = async () => {
        setSpin(true);
        const res = await GET(`api/template/cve/cpes?vendor=${selectData}&csv=false`);
        setCpeData(res);
        setSpin(false);
    }

    // // Get list of all the products
    // const getProductList = async () => {
    //     const res = await GET(`api/template/cve/cpes/search?vendor=${selectData}&product=&version=&csv=false`);
    //     setProductsList(res);
    // }

    // Get list of all products
    const searchCpesData = async () => {
        setLoadData(true);
        const res = await GET(`api/template/cve/cpes/search?vendor=${selectedVendor}&product=&version=&csv=false`);
        convertProductListCsvFormat(res);
        setLoadData(false);
    }

    // Adding a product to 
    const addProduct = async () => {
        let addProdRes;
        setAddProdLoader(true);
        let data = {
            vendor: selectData,
            names: listOfAddProduct
        }
        try {
            if (listOfAddProduct?.length > 0) {
                addProdRes = await POST(`api/template/cve/cpes`, data);
                console.log(addProdRes);
            }
            if (addProdRes[0]?.id) {
                toast({
                    description: 'Product added successfully!',
                    status: 'success',
                    isClosable: true,
                    duration: 3000,
                    position: 'top-right'
                });
                setListOfAddProduct([]);
            } else {
                toast({
                    description: 'Server cannot process your request. This may happen due to duplicate product or late server response',
                    status: 'error',
                    isClosable: true,
                    duration: 4500,
                    position: 'top-right'
                })
            }
        } catch (err) {
            toast({
                description: 'Something went wrong!',
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right'
            })
        }
        setAddProdLoader(false);
    }

    useEffect(() => {
        getCpeData();
        // getProductList();
        searchCpesData();
    }, [selectData, selectedVendor]);

    // Enter button handler to add multiple products during registration
    const handleKeyPress = event => {
        if (event.keyCode === 13) {
            if (addProductVal !== '') addRegisterProdItem();
        }
    };

    function addRegisterProdItem() {
        setListOfAddProduct([addProductVal, ...listOfAddProduct]);
        setAddProductVal("");
    }

    function removeRegisterProdItem(removingIndex) {
        const updatedArray = listOfAddProduct.filter((item, index) => index !== removingIndex);
        setListOfAddProduct(updatedArray);
    }

    return (
        <>
            <Stack
                direction={'row'}
                gap={'20px'}
            >
                {
                    bool ?
                        <Stack
                            w={'100%'}
                        >
                            <Box

                                display={'flex'}
                                alignItems={'center'}
                                gap={'10px'}
                                justifyContent={'space-between'}
                                marginBottom={'35px'}
                            >
                                <Box display={"flex"} alignItems={"center"} gap={'10px'}>
                                    <Typography.Text style={{ fontWeight: '700', fontSize: '14px' }}>Vendor</Typography.Text>
                                    <Select
                                        w={'290px'}
                                        onChange={(e) => setSelectData(e.target.value)}
                                    >
                                        {
                                            vendorData?.length > 0 && vendorData && vendorData.map((item) => {
                                                return (
                                                    <option value={item.name}>{item.name}</option>
                                                )
                                            })
                                        }

                                    </Select>
                                    {

                                    }
                                    <Typography.Text style={{ fontWeight: '700', fontSize: '14px' }}>Product</Typography.Text>

                                    <Select
                                        w={'290px'}
                                        onChange={(e) => setSelectedVendor(e)}
                                    >
                                        <option style={{ display: "none" }}>Select</option>
                                        {
                                            cpeData.length > 0 && cpeData && cpeData.map((v, i) => <option key={i} value={v.product}>{v.name}</option>)
                                        }
                                    </Select>


                                    <Button isLoading={load} px={"50px"} width={"100px"} onClick={getData} color={"#fff"} _hover={"none"} bg={"#222"}>Search</Button>
                                    {
                                        spin ? <Spinner /> : ""
                                    }
                                </Box>
                                <Button px={"50px"} width={"100px"} onClick={() => { setBool(!bool) }} color={"#fff"} _hover={"none"} bg={"#222"}>Back</Button>
                            </Box>
                            <Box
                                mt={'35px'}
                                border={'1px solid #E8EDFC'}
                                padding={'10px 20px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <Heading
                                    textAlign={'center'}
                                    fontSize={'22px'}
                                >
                                    CVEs</Heading>
                                <CSVLink ref={downloadCveRed} style={{
                                    padding: '5px 15px',
                                    borderRadius: '6px',
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    color: '#fff',
                                    background: "#222"
                                }} data={csvData}>Download as CSV</CSVLink>
                                {/* <Button
                                px={"15px"}
                                color={"#fff"}
                                _hover={"none"}
                                bg={"#222"}
                                onClick={() => downloadCveRed?.current.click()}
                            >
                                Download as CSV
                            </Button> */}
                            </Box>
                            <Table>
                                <Thead>
                                    <Tr
                                        bgColor={'#4A4A4A'}
                                        color={'#fff'}
                                        fontSize={'14px'}
                                    >
                                        <Td w={'14%'}>ID</Td>
                                        <Td w={'14%'}>Description</Td>
                                        <Td w={'10%'}>Attack Vector</Td>
                                        <Td w={'12%'}>Vulnerability Status</Td>
                                        <Td w={'14%'}>Impact</Td>
                                        <Td w={'7%'}>Severity</Td>
                                        <Td w={'14%'}>Status</Td>
                                    </Tr>
                                </Thead>
                                <Tbody fontSize={'14px'}>
                                    {
                                        data.length > 0 && data && data.map((item) => {
                                            return (
                                                <Tr key={item?.id}>
                                                    <Td >{item?.id ?? ''}</Td>
                                                    <Td>
                                                        <Tooltip label={item?.description} hasArrow>
                                                            <Text className="ellipsisVulnerable">{item?.description}</Text>
                                                        </Tooltip>
                                                    </Td>
                                                    <Td>{item?.metrics[0]?.['attackVector']}</Td>
                                                    <Td>{item?.vulnerabilityStatus}</Td>
                                                    <Td>
                                                        <>
                                                            <Text fontSize={'12px'}>
                                                                Confidentiality: {item?.metrics[0]?.['confidentialityImpact']}
                                                            </Text>
                                                            <Text fontSize={'12px'}>
                                                                Integrity: {item?.metrics[0]?.['integrityImpact']}
                                                            </Text>
                                                            <Text fontSize={'12px'}>
                                                                Availability: {item?.metrics[0]?.['availabilityImpact']}
                                                            </Text>
                                                        </>
                                                    </Td>
                                                    <Td>{item?.metrics[0]?.['baseSeverity']}</Td>
                                                    <Td>
                                                        <>
                                                            <Text fontSize={'12px'}>
                                                                Assigned:
                                                            </Text>
                                                            <Text fontSize={'12px'}>
                                                                Ticket Opened:
                                                            </Text>
                                                            <Text fontSize={'12px'}>
                                                                Resolved:
                                                            </Text>
                                                            <Text fontSize={'12px'}>
                                                                Resolution in Progress:
                                                            </Text>
                                                        </>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                    }


                                </Tbody>
                            </Table>
                        </Stack>
                        :
                        <>
                            <Stack
                                w={'40%'}
                            >
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={'10px'}
                                    justifyContent={'space-between'}
                                    marginBottom={'35px'}
                                >
                                    <Typography.Text style={{ fontWeight: '700', fontSize: '14px' }}>Select Vendor</Typography.Text>

                                    <Select
                                        w={'290px'}
                                        onChange={(e) => setSelectData(e.target.value)}
                                    >
                                        {
                                            vendorData?.length > 0 && vendorData && vendorData.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.name}>{item.name}</option>
                                                    </>
                                                )
                                            })
                                        }
                                    </Select>
                                    {/* <Button
                                    bgColor={'primary.100'}
                                    color={'#fff'}
                                    padding={'14px 5px'}
                                    fontSize={'16px'}
                                    fontWeight={'400'}
                                    isLoading={loadData ? true : false}
                                    border={'2px solid #4A4A4A'}
                                    _hover={{
                                        bgColor: 'transparent',
                                        color: '#4A4A4A'
                                    }}
                                >Download Products</Button> */}
                                    {
                                        loadData ?
                                            <Button
                                                bgColor={'primary.100'}
                                                color={'#fff'}
                                                padding={'14px 5px'}
                                                fontSize={'16px'}
                                                fontWeight={'400'}
                                                isLoading={true}
                                                border={'2px solid #4A4A4A'}
                                                _hover={{
                                                    bgColor: 'transparent',
                                                    color: '#4A4A4A'
                                                }}
                                            >Download Products</Button>
                                            :
                                            <CSVLink
                                                style={{
                                                    padding: '8px 12px',
                                                    borderRadius: '6px',
                                                    fontSize: '16px',
                                                    fontWeight: '600',
                                                    color: '#fff',
                                                    background: "#222"
                                                }}
                                                data={downloadProductsData}
                                            >
                                                Download as CSV
                                            </CSVLink>
                                    }
                                </Box>
                                <Box>
                                    <Box
                                        mb={'20px'}
                                        display={'flex'}
                                        alignItems={'flex-start'}
                                        gap={'10px'}
                                    >
                                        <Stack
                                            direction={'row'}
                                            alignItems={'flex-start'}
                                            w={'58%'}
                                        >
                                            <Tooltip
                                                mt={'20px'}
                                                label="Press Enter to add more products"
                                            >
                                                <Text
                                                    color={'#fff'}
                                                    w={'20px'}
                                                    h={'20px'}
                                                    borderRadius={'100%'}
                                                    bgColor={'#444'}
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={'center'}
                                                >
                                                    i
                                                </Text>
                                            </Tooltip>
                                            <Stack w={'90%'}>
                                                <Input
                                                    onChange={e => setAddProductVal(e.target.value)}
                                                    value={addProductVal}
                                                    onKeyDownCapture={handleKeyPress}
                                                    size="large"
                                                    placeholder=""
                                                />
                                            </Stack>
                                        </Stack>
                                        <Button
                                            isLoading={addProdLoader}
                                            onClick={() => { addProduct() }}
                                            bgColor={'primary.100'}
                                            color={'#fff'}
                                            padding={'16px 5px'}
                                            fontSize={'15px'}
                                            fontWeight={'500'}
                                            border={'2px solid #4A4A4A'}
                                            _hover={{
                                                bgColor: 'transparent',
                                                color: '#4A4A4A'
                                            }}
                                        >Upload and Register Products</Button>
                                    </Box>
                                    {
                                        listOfAddProduct?.length > 0 &&
                                        listOfAddProduct?.map((v, i) =>
                                            <Box
                                                mb={'10px !important'}
                                                key={i}
                                                bgColor={'#edf2f7'}
                                                display={'flex'}
                                                alignItems={'center'}
                                                padding={'0 5px'}
                                                justifyContent={'space-between'}
                                            >
                                                <Text fontSize={'14px'}>{v}</Text>
                                                <Button
                                                    onClick={() => removeRegisterProdItem(i)}
                                                    padding={0}
                                                    minW={'20px'}
                                                    h={'15px'}
                                                    fontSize={'12px'}
                                                >
                                                    <Icon as={FaTimes} />
                                                </Button>
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Stack>
                            <Stack
                                w={'50%'}
                            >
                                <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
                                    <Heading fontSize={'22px'} color={'#262626'} fontWeight={'600'} margin={'0 0 25px 0'}>Registered Products</Heading>
                                    <Button color={"#fff"} onClick={() => { setBool(!bool) }} _hover={"none"} bg={'#222'} >View CVEs</Button>
                                </Stack>
                                {
                                    cpeData && cpeData.length > 0 && cpeData.map((v, i) =>
                                        <Button
                                            onClick={() => setSelectedProduct(v)}
                                            key={i}
                                            bgColor={'transparent'}
                                            color={'#8A959E'}
                                            padding={'20px 30px'}
                                            w={'fit-content'}
                                            _hover={{ bgColor: 'transparent' }}
                                            _focusVisible={{ bgColor: 'transparent' }}
                                            border={'1px solid #adadad'}
                                            borderRadius={6}
                                        >
                                            {v?.name ?? 'Loading...'}
                                        </Button>)
                                }

                            </Stack>
                        </>
                }
            </Stack>
            <EnterpriseRiskTolerance sideMenu={false} />
        </>
    )
}
