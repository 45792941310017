import { ChevronRightIcon } from "@chakra-ui/icons";
import {
    Box, Button, Icon, Stack, Text, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Input,
    Select,
    Heading,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel
} from "@chakra-ui/react";
import { Typography } from "antd";
import EnterpriseRiskTolerance from "../EnterpriseRiskTolerance/EnterpriseRiskTolerance";
import { MinusSquareOutlined, PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { useState } from "react";

export default function CyberResiliency() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isImportOpen, onOpen: onImportOpen, onClose: onImportClose } = useDisclosure();
    const { isOpen: isClientOpen, onOpen: onClientOpen, onClose: onClientClose } = useDisclosure();
    let defaultData = [
        {
            name: 'TD',
            code: 'Palo_ALTO'
        },
        {
            name: 'ZT',
            code: 'CISCO'
        },
        {
            name: 'ZT',
            code: 'Palo_ALTO'
        },
    ]
    let defaultData2 = [
        {
            name: 'Config-1',
            code: 'Palo_ALTO'
        },
        {
            name: 'Config-2',
            code: 'CISCO'
        },
        {
            name: 'Config-3',
            code: 'Palo_ALTO'
        },
    ]
    const [viewHandler, setViewHandler] = useState(1);

    return (
        <Stack>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Execute Security Posture</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Typography.Text>Security Posture</Typography.Text>
                        <Input
                            size="md"
                            p={4}
                            borderRadius={6}
                            placeholder="TD"
                            marginBottom={'15px'}
                        />
                        <Typography.Text>Device Configuration</Typography.Text>
                        <Select
                            borderRadius={6}
                            marginBottom={'15px'}
                        >
                            <option>Config-1</option>
                            <option>Config-2</option>
                            <option>Config-3</option>
                        </Select>
                        <Typography.Text>Device Vendor</Typography.Text>
                        <Select
                            marginBottom={'15px'}
                        >
                            <option>PALO_ALTO</option>
                            <option>PALO_ALTO</option>
                            <option>PALO_ALTO</option>
                        </Select>
                    </ModalBody>
                    <ModalFooter
                        display={'flex'}
                        justifyContent={'center'}
                        gap={'20px'}
                    >
                        <Button
                            bgColor={'#ebf4ff'}
                            border={'2px solid #1E86FF'}
                            borderRadius={'4px'}
                            color={'#1E86FF'}
                            fontWeight={'600'}
                            w={'140px'}
                        >Execute</Button>
                        <Button
                            onClick={onClose}
                            bgColor={'#F0F0F0'}
                            borderRadius={'4px'}
                            w={'140px'} mr={'15px'}
                            color={'#262626'}
                            fontWeight={'600'}
                        >Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={isImportOpen}
                onClose={onImportClose}
                size={'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        marginTop={'40px'}
                    >
                        <Typography.Text>File Name</Typography.Text>
                        <Input
                            size="md"
                            p={4}
                            borderRadius={6}
                            placeholder="TD"
                            marginBottom={'15px'}
                        />
                        <Input
                            borderRadius={6}
                            type="file"
                            border={'none'}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={isClientOpen}
                onClose={onClientClose}
                size={'4xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        marginTop={'40px'}
                    >
                        <Stack>
                            <Box w={'full'} className="vendorConfigOpt">
                                <Heading fontSize={'22px'} color={'#2626262'} margin={'0 0 30px 0'}>
                                    Add / Update Vendor Configuration
                                </Heading>
                            </Box>
                            <Stack
                                direction={'row'}
                                spacing={0}
                                gap={6}
                                flexWrap={'wrap'}
                                className='vendorConfigFieldsBox'
                            >
                                <Box w={{ base: "", mc: "", lg: "", xl: "30.1%" }} margin={'0 0 15px 0 !important'}>
                                    <Typography.Text>Name</Typography.Text>
                                    <Input
                                        size="md"
                                        p={4}
                                        borderRadius={6}
                                        placeholder="Production Account Configuration"
                                    />
                                </Box>
                                <Box w={"30.1%"} margin={'0 0 15px 0 !important'}>
                                    <Typography.Text>ClientId / AccessKeyId</Typography.Text>
                                    <Input
                                        size="md"
                                        p={4}
                                        borderRadius={6}
                                        placeholder="123xxx555xxx"
                                    />
                                </Box>
                                <Box w={"30.1%"} margin={'0 0 15px 0 !important'}>
                                    <Typography.Text>
                                        Client Secret Key / Access Key Secret
                                    </Typography.Text>
                                    <Input
                                        size="md"
                                        p={4}
                                        borderRadius={6}
                                        placeholder="kjuyt67765tgg"
                                    />
                                </Box>
                                <Box w={"30.1%"} margin={'0 0 15px 0 !important'}>
                                    <Typography.Text>Tenant Id</Typography.Text>
                                    <Input
                                        size="md"
                                        p={4}
                                        borderRadius={6}
                                        placeholder="13333558ki8u-00ikxn"
                                    />
                                </Box>
                                <Box w={"30.1%"} margin={'0 0 15px 0 !important '}>
                                    <Typography.Text>Subscription Id</Typography.Text>
                                    <Input
                                        size="md"
                                        p={4}
                                        borderRadius={6}
                                        placeholder="4lkxbb0(kk_@2"
                                    />
                                </Box>
                            </Stack>
                            <Box w={'full'} margin={'25px 0 0 0 !important'}>
                                <Accordion
                                    defaultIndex={[0, 1]}
                                    allowMultiple
                                    allowToggle
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    alignItems={'flex-start'}
                                >
                                    <AccordionItem
                                        w={'47%'}
                                        border={'none'}
                                        borderRadius={'6px'}
                                        mb={'30px'}
                                    >
                                        {({ isExpanded }) => (
                                            <>
                                                <h2>
                                                    <AccordionButton
                                                        border={0}
                                                        outlineOffset={'0px'}
                                                    >
                                                        <Box
                                                            as="span"
                                                            flex="1"
                                                            textAlign="left"
                                                            borderRadius={'6px'}
                                                        >
                                                            Custom Variable
                                                        </Box>
                                                        {isExpanded ? (
                                                            <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                        ) : (
                                                            <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                        )}
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={0} px={0}>
                                                    <Stack
                                                        bgColor={'primary.100'}
                                                        color={'#fff'}
                                                        direction={'row'}
                                                    >
                                                        <Text
                                                            py={'20px'}
                                                            w={'150px'}
                                                            borderRight={'1px solid #E8EDFC'}
                                                            pl={'20px'}
                                                        >
                                                            Variable / Key
                                                        </Text>
                                                        <Text py={'20px'} pl={'20px'}>
                                                            Value
                                                        </Text>
                                                    </Stack>
                                                    <Stack
                                                        direction={'row'}
                                                        alignItems={'center'}
                                                        spacing={0}
                                                    >
                                                        <Text
                                                            border={'1px solid #E8EDFC'}
                                                            py={'20px'}
                                                            w={'150px'}
                                                            pl={'20px'}
                                                        >
                                                            RGN
                                                        </Text>
                                                        <Box w={'78%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                                                            <Input
                                                                _focusVisible={{
                                                                    outlineOffset: '0px'
                                                                }}
                                                                border={'0 !important'}
                                                                style={{ width: '82px' }}
                                                                size="large"
                                                                name='rgn1'
                                                                placeholder="Value 1"
                                                            />
                                                        </Box>
                                                    </Stack>
                                                    <Stack
                                                        direction={'row'}
                                                        spacing={0}
                                                        alignItems={'center'}
                                                    >
                                                        <Text
                                                            border={'1px solid #E8EDFC'}
                                                            py={'20px'}
                                                            w={'150px'}
                                                            pl={'20px'}
                                                        >
                                                            Format
                                                        </Text>
                                                        <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                            <Input
                                                                _focusVisible={{
                                                                    outlineOffset: '0px'
                                                                }}
                                                                border={'0 !important'}
                                                                style={{ width: '82px' }}
                                                                size="large"
                                                                name='format'
                                                                placeholder="Value 2"
                                                            />
                                                        </Box>
                                                    </Stack>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                    <AccordionItem
                                        w={'47%'}
                                        border={'none'}
                                        borderRadius={'6px'}
                                        mb={'30px'}
                                    >
                                        {({ isExpanded }) => (
                                            <>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box
                                                            as="span"
                                                            flex="1"
                                                            textAlign="left"
                                                            borderRadius={'6px'}
                                                        >
                                                            Configuration File Upload
                                                        </Box>
                                                        {isExpanded ? (
                                                            <Icon as={PlusSquareOutlined} fontSize="18px" />
                                                        ) : (
                                                            <Icon as={MinusSquareOutlined} fontSize="18px" />
                                                        )}
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={0} px={0}>
                                                    <Stack
                                                        bgColor={'primary.100'}
                                                        color={'#fff'}
                                                        direction={'row'}
                                                    >
                                                        <Text
                                                            py={'20px'}
                                                            w={'150px'}
                                                            borderRight={'1px solid #E8EDFC'}
                                                            pl={'20px'}
                                                        >
                                                            Name
                                                        </Text>
                                                        <Text py={'20px'} pl={'20px'}>
                                                            File
                                                        </Text>
                                                    </Stack>
                                                    <Stack
                                                        direction={'row'}
                                                        alignItems={'center'}
                                                        spacing={0}
                                                    >
                                                        <Text
                                                            border={'1px solid #E8EDFC'}
                                                            py={'20px'}
                                                            w={'150px'}
                                                            pl={'20px'}
                                                        >
                                                            Name
                                                        </Text>
                                                        <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                                                            <Input
                                                                _focusVisible={{
                                                                    outlineOffset: '0px'
                                                                }}
                                                                border={'0 !important'}
                                                                style={{ width: '82px' }}
                                                                size="large"
                                                                name='name'
                                                                type='text'
                                                                placeholder="name"
                                                            />
                                                        </Box>
                                                    </Stack>
                                                    <Stack
                                                        direction={'row'}
                                                        alignItems={'center'}
                                                        spacing={0}
                                                    >
                                                        <Text
                                                            border={'1px solid #E8EDFC'}
                                                            py={'20px'}
                                                            w={'150px'}
                                                            pl={'20px'}
                                                        >
                                                            RGN
                                                        </Text>
                                                        <Box
                                                            w={'78%'}
                                                            p={'20px 12px'}
                                                        >
                                                            <Input
                                                                _focusVisible={{
                                                                    outlineOffset: '0px'
                                                                }}
                                                                border={'0 !important'}
                                                                style={{ width: '82px' }}
                                                                size="large"
                                                                name='rgn2'
                                                                type='text'
                                                                placeholder="value"
                                                            />
                                                        </Box>
                                                    </Stack>
                                                    <Stack direction={'row'} spacing={0}>
                                                        <Text
                                                            border={'1px solid #E8EDFC'}
                                                            py={'20px'}
                                                            w={'150px'}
                                                            pl={'20px'}
                                                        >
                                                            Content
                                                        </Text>
                                                        <Box
                                                            border={'1px solid #E8EDFC'}
                                                            w={'78%'}
                                                            p={'12px'}
                                                            display={'flex'}
                                                            alignItems={'center'}
                                                            gap={'10px'}
                                                        >
                                                            <Input
                                                                border={'1px solid #E8EDFC'}
                                                                // style={{ width: '82px', display: 'none' }}
                                                                size="large"
                                                                placeholder="Value 2"
                                                                name='content'
                                                                type="file"
                                                            />
                                                        </Box>
                                                    </Stack>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                            </Box>
                        </Stack>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                        >
                            <Button
                                w={'190px'}
                                bgColor={'primary.100'}
                                color={'#fff'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid #4A4A4A'}
                                _hover={{
                                    bgColor: 'transparent',
                                    color: '#4A4A4A',
                                }}
                            >
                                Login
                            </Button>
                            <Button
                                onClick={onClientClose}
                                w={'190px'}
                                bgColor={'transparent'}
                                color={'#8A959E'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid transparent'}
                                _hover={{
                                    borderColor: '#4A4A4A',
                                    color: '#4A4A4A',
                                }}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                marginBottom={'20px'}
            >
                <Box>
                    <Button
                        onClick={() => setViewHandler(3)}
                        bgColor={viewHandler === 3 ? "#ebf4ff" : '#F0F0F0'}
                        borderColor={viewHandler === 3 ? "#1E86FF" : '#F0F0F0'}
                        color={viewHandler === 3 ? "#1E86FF" : '#262626'}
                        borderRadius={'4px'}
                        w={'140px'} mr={'15px'}
                        fontWeight={'600'}
                    >View Result</Button>
                    <Button
                        onClick={() => setViewHandler(2)}
                        bgColor={viewHandler === 2 ? "#ebf4ff" : '#F0F0F0'}
                        borderRadius={'4px'}
                        w={'200px'} mr={'15px'}
                        borderColor={viewHandler === 2 ? "#1E86FF" : '#F0F0F0'}
                        color={viewHandler === 2 ? "#1E86FF" : '#262626'}
                        fontWeight={'600'}
                    >Device Configuration</Button>
                </Box>
                <Box>
                    {
                        viewHandler === 1 ?
                            <Button
                                onClick={onImportOpen}
                                bgColor={'#ebf4ff'}
                                border={'2px solid #1E86FF'}
                                borderRadius={'4px'}
                                color={'#1E86FF'}
                                fontWeight={'600'}
                                w={'140px'}
                            >Import</Button>
                            : viewHandler === 2 ?
                                <Button
                                    onClick={onClientOpen}
                                    bgColor={'#ebf4ff'}
                                    border={'2px solid #1E86FF'}
                                    borderRadius={'4px'}
                                    color={'#1E86FF'}
                                    fontWeight={'600'}
                                    w={'140px'}
                                ><Icon as={PlusOutlined} mr={'5px'} /> Add </Button>
                                :
                                null
                    }
                </Box>
            </Stack>
            {
                viewHandler === 1 ?
                    <Stack>
                        {
                            defaultData.map((v, i) =>
                                <Stack key={i} direction={'row'} spacing={0} justifyContent={'space-between'}>
                                    <Box w={'33%'} borderRadius={'6px'} padding={'10px 20px'} border={'1px solid #E8EDFC'}>{v.name}</Box>
                                    <Box w={'33%'} borderRadius={'6px'} padding={'10px 20px'} border={'1px solid #E8EDFC'}>{v.code}</Box>
                                    <Box w={'33%'} display={'flex'} alignItems={'center'} gap={'5px'} borderRadius={'6px'} padding={'10px 20px'} border={'1px solid #E8EDFC'}>
                                        <Button bgColor={'transparent'} color={'#1E86FF'} fontWeight={600} onClick={onOpen}>Run</Button>
                                        <Text>|</Text>
                                        <Button bgColor={'transparent'}>Delete</Button>
                                        <Text><Icon as={ChevronRightIcon} fontSize={'32px'} fontWeight={600} /></Text>
                                    </Box>
                                </Stack>
                            )
                        }
                    </Stack>
                    : viewHandler === 2 ?
                        <Stack>
                            {
                                defaultData2.map((v, i) =>
                                    <Stack key={i} direction={'row'} spacing={0} justifyContent={'space-between'}>
                                        <Box w={'33%'} borderRadius={'6px'} padding={'10px 20px'} border={'1px solid #E8EDFC'}>{v.name}</Box>
                                        <Box w={'33%'} borderRadius={'6px'} padding={'10px 20px'} border={'1px solid #E8EDFC'}>{v.code}</Box>
                                        <Box w={'33%'} display={'flex'} alignItems={'center'} gap={'5px'} borderRadius={'6px'} padding={'10px 20px'} border={'1px solid #E8EDFC'}>
                                            <Button bgColor={'transparent'}>Delete</Button>
                                            <Text><Icon as={ChevronRightIcon} fontSize={'32px'} fontWeight={600} /></Text>
                                        </Box>
                                    </Stack>
                                )
                            }
                        </Stack>
                        : <EnterpriseRiskTolerance />
            }
            {/* {
                viewHandler !== 3 ? <EnterpriseRiskTolerance /> : null
            } */}

        </Stack>
    )
}
