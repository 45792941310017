import { Box, Button, Icon, Image, Stack, Text } from '@chakra-ui/react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import Amazon from '../../assets/images/companies/amazon.png';
import AmazonWhite from '../../assets/images/companies/amazon-white.png';
import Google from '../../assets/images/companies/google.png';
import GoogleWhite from '../../assets/images/companies/google-white.png';
import Azure from '../../assets/images/companies/azure.png';
import AzureWhite from '../../assets/images/companies/azure-white.png';
import Webex from '../../assets/images/companies/webex.png';
import WebexWhite from '../../assets/images/companies/webex-white.png';
import Microsoft from '../../assets/images/companies/microsoft.png';
import MicrosoftWhite from '../../assets/images/companies/microsoft-white.png';
import Zoom from '../../assets/images/companies/zoom.png';
import ZoomWhite from '../../assets/images/companies/zoom-white.png';
import Okta from '../../assets/images/companies/okta.png';
import OktaWhite from '../../assets/images/companies/okta-white.png';
import SAP from '../../assets/images/sap.png';
import Oracle from '../../assets/images/oracle.png';
import Salesforce from '../../assets/images/salesForce.png';
import PALOALTO from '../../assets/images/companies/paloalto.png';
import Arista from '../../assets/images/companies/arista.png';
import AristaWhite from '../../assets/images/companies/arista-white.png';
import Cisco from '../../assets/images/companies/cisco.png';
import CiscoWhite from '../../assets/images/companies/cisco-white.png';
import Juniper from '../../assets/images/companies/juniper.png';
import JuniperWhite from '../../assets/images/companies/juniper-white.png';
import gpt from '../../assets/images/companies/gpt.png';
import huggingFace from '../../assets/images/companies/huggingFace.png';
import msCopilot from '../../assets/images/companies/msCopilot.jpg';
import gemini from '../../assets/images/companies/gemini.png';
import llama2 from '../../assets/images/companies/llama2.jpg';
import github from '../../assets/images/companies/github.jpg';

import { useState } from 'react';

export default function HostCloudButton({
  w,
  findData,
  id,
  image,
  imageWhite,
  name,
  selectedCloudOption,
  setSelectedCloudOption,
  noImage,
  index,
  isDisabled,
}) {
  const hostCloud = [
    { name: 'Amazon Web Services', image: Amazon, imageWhite: AmazonWhite },
    { name: 'Google Cloud Services', image: Google, imageWhite: GoogleWhite },
    { name: 'Microsoft Azure', image: Azure, imageWhite: AzureWhite },
    { name: 'Microsoft Teams', image: Microsoft, imageWhite: MicrosoftWhite },
    { name: 'WebEx', image: Webex, imageWhite: WebexWhite },
    { name: 'Zoom', image: Zoom, imageWhite: ZoomWhite },
    { name: 'Okta', image: Okta, imageWhite: OktaWhite },
    { name: 'SAP', image: SAP, imageWhite: SAP },
    { name: 'Oracle', image: Oracle, imageWhite: Oracle },
    { name: 'Salesforce', image: Salesforce, imageWhite: Salesforce },
    { name: 'Palo Alto', image: PALOALTO, imageWhite: PALOALTO },
    { name: 'Arista', image: Arista, imageWhite: AristaWhite },
    { name: 'Cisco', image: Cisco, imageWhite: CiscoWhite },
    { name: 'Juniper', image: Juniper, imageWhite: JuniperWhite },
    { name: 'GPT', image: gpt, imageWhite: gpt },
    { name: 'Hugging Face', image: huggingFace, imageWhite: huggingFace },
    { name: 'MS Copilot', image: msCopilot, imageWhite: msCopilot },
    { name: 'Gemini', image: gemini, imageWhite: gemini },
    { name: 'Llama2', image: llama2, imageWhite: llama2 },
    { name: 'GitHub', image: github, imageWhite: github },
  ];

  return (
    <Button
      isDisabled={isDisabled}
      background={'#F0F0F0'}
      border={'1px solid'}
      bgColor={name === selectedCloudOption?.name ? 'primary.100' : '#fff'}
      borderColor={name === selectedCloudOption?.name ? '#4a4a4a' : '#E8EDFC'}
      borderRadius={'6px'}
      padding={'25px 10px'}
      _hover={{
        bgColor: name === selectedCloudOption?.name ? 'primary.100' : '#F0F0F0',
      }}
      _active={{
        bgColor: name === selectedCloudOption?.name ? 'primary.100' : '#F0F0F0',
      }}
      w={w ?? { base: '100%', md: '48%', lg: '40%', xl: '16%' }}
      onClick={() => {
        setSelectedCloudOption({
          id,
          name,
        });
        findData(id, index);
      }}
    >
      <Stack
        w={'full'}
        direction={'row'}
        alignItems={'center'}
        gap={'10px'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          gap={{ base: '', md: '5px', lg: '10px' }}
          alignItems={'center'}
        >
          {
            // image ?
            noImage === true ? (
              <Text
                color={name === selectedCloudOption?.name ? '#fff' : '#8A959E'}
              >
                {name}
              </Text>
            ) : (
              <Image
                src={
                  name === selectedCloudOption?.name
                    ? hostCloud.find(cloud => cloud.name === name)
                        ?.imageWhite ||
                      hostCloud.find(cloud => cloud.name === name)?.image
                    : name === 'Amazon Web Services'
                    ? hostCloud[0]['image']
                    : name === 'Microsoft Azure'
                    ? hostCloud[2]['image']
                    : name === 'Google Cloud Services'
                    ? hostCloud[1]['image']
                    : name === 'Microsoft Teams'
                    ? hostCloud[3]['image']
                    : name === 'WebEx'
                    ? hostCloud[4]['image']
                    : name === 'Zoom'
                    ? hostCloud[5]['image']
                    : name === 'SAP'
                    ? hostCloud[7]['image']
                    : name === 'Oracle'
                    ? hostCloud[8]['image']
                    : name === 'Salesforce'
                    ? hostCloud[9]['image']
                    : name === 'Palo Alto'
                    ? hostCloud[10]['image']
                    : name === 'Arista'
                    ? hostCloud[11]['image']
                    : name === 'Cisco'
                    ? hostCloud[12]['image']
                    : name === 'Juniper'
                    ? hostCloud[13]['image']
                    : name === 'GPT'
                    ? hostCloud[14]['image']
                    : name === 'Hugging Face'
                    ? hostCloud[15]['image']
                    : name === 'MS Copilot'
                    ? hostCloud[16]['image']
                    : name === 'Gemini'
                    ? hostCloud[17]['image']
                    : name === 'Llama2'
                    ? hostCloud[18]['image']
                    : name === 'GitHub'
                    ? hostCloud[19]['image']
                    : hostCloud[6]['image']
                }
                w={'120px'}
                height={'30px'}
                objectFit={'contain'}
              />
            )
            // :
            // <Text
            //     fontSize={'12px'}
            //     fontWeight={'600'}
            //     width={"50px"}
            //     color={name === selectedCloudOption?.name ? '#fff' : '#8A959E'}
            // >
            //     {name ?? 'Other'}
            // </Text>
          }
        </Box>
        <Icon
          fontWeight={'700'}
          fontSize={'17px'}
          as={name === selectedCloudOption?.name ? BsChevronUp : BsChevronDown}
          color={name === selectedCloudOption?.name ? '#fff' : 'initial'}
        />
      </Stack>
    </Button>
  );
}
