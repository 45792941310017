import { BsCloudDownload, BsFillPersonLinesFill, BsCameraVideo } from 'react-icons/bs'
import Amazon from '../assets/images/companies/amazon.png'
import AmazonWhite from '../assets/images/companies/amazon-white.png'
import Google from '../assets/images/companies/google.png'
import GoogleWhite from '../assets/images/companies/google-white.png'
import Azure from '../assets/images/companies/azure.png'
import AzureWhite from '../assets/images/companies/azure-white.png'
import Webex from '../assets/images/companies/webex.png'
import WebexWhite from '../assets/images/companies/webex-white.png'
import Okta from '../assets/images/companies/okta.png'
import OktaWhite from '../assets/images/companies/okta-white.png'
import Arista from '../assets/images/companies/arista.png'
import AristaWhite from '../assets/images/companies/arista-white.png'
import Microsoft from '../assets/images/companies/microsoft.png'
import MicrosoftWhite from '../assets/images/companies/microsoft-white.png'
import Fortinet from '../assets/images/companies/fortinet.png'
import FortinetWhite from '../assets/images/companies/fortinet-white.png'
import Cisco from '../assets/images/companies/cisco.png'
import CiscoWhite from '../assets/images/companies/cisco-white.png'
import Paloalto from '../assets/images/companies/paloalto.png'
import PaloaltoWhite from '../assets/images/companies/paloalto-white.png'
import Zoom from '../assets/images/companies/zoom.png'
import ZoomWhite from '../assets/images/companies/zoom-white.png'
import Juniper from '../assets/images/companies/juniper.png'
import JuniperWhite from '../assets/images/companies/juniper-white.png'
import SAP  from '../assets/images/sap.png'
import Oracle  from '../assets/images/oracle.png'
import Salesforce  from '../assets/images/salesForce.png'
import { GiChaingun } from 'react-icons/gi'
import { HiUserAdd } from 'react-icons/hi'
import { GET } from './ApiProvider'


const cloudOptions = [
    { icon: BsCloudDownload, id:"host", name:'Host Cloud', },
    { icon: BsFillPersonLinesFill,id:"peer1", name:'Peer 1' },
    { icon: BsFillPersonLinesFill, id:"peer2", name:'Peer 2' },
    { icon: HiUserAdd, name: 'Additional Peers' }
]

const hostCloud = [
    { id: 1, image: Amazon, name: 'Amazon', imageWhite: AmazonWhite },
    { id: 2, image: Google, name: 'Google', imageWhite: GoogleWhite },
    { id: 3, image: Azure, name: 'Azure', imageWhite: AzureWhite },
    { id: 4, image: Webex, name: 'Webex', imageWhite: WebexWhite },
    { id: 5, image: SAP, name: 'SAP', imageWhite: SAP },
    { id: 6, image: Salesforce, name: 'Salesforce', imageWhite: Salesforce },
    { id: 7, image: Oracle, name: 'Oracle', imageWhite: Oracle },
    { id: 8, name: 'Other Cloud' }
]

const otherCloud = [
    { id: 1, icon: GiChaingun, name: 'Production Account Configuration' },
    { id: 1, icon: BsFillPersonLinesFill, name: 'Dev Account Configuration' },
    { id: 1, icon: BsCameraVideo, name: 'Staging Account Configuration' },
]

const peer1 = [
    { id: 1, image: Google, name: 'Google', imageWhite: GoogleWhite },
    { id: 2, image: Webex, name: 'Webex', imageWhite: WebexWhite },
    { id: 3, image: Arista, name: 'Arista', imageWhite: AristaWhite },
    { id: 4, image: Azure, name: 'Azure', imageWhite: AzureWhite },
    { id: 5, image: Microsoft, name: 'Microsoft', imageWhite: MicrosoftWhite },
    { id: 6, image: Fortinet, name: 'Fortinet', imageWhite: FortinetWhite },
    { id: 7, image: Amazon, name: 'Amazon', imageWhite: AmazonWhite },
    { id: 8, image: Cisco, name: 'Cisco', imageWhite: CiscoWhite },
    { id: 9, image: Paloalto, name: 'Paloalto', imageWhite: PaloaltoWhite },
    { id: 10, image: Zoom, name: 'Zoom', imageWhite: ZoomWhite },
    { id: 11, image: Juniper, name: 'Juniper', imageWhite: JuniperWhite },
    { id: 12, name: 'Other Cloud' }
]

const peer2 = [
    { id: 1, image: Google, name: 'Google', imageWhite: GoogleWhite },
    { id: 2, image: Fortinet, name: 'Fotinet', imageWhite: FortinetWhite },
    { id: 3, image: Juniper, name: 'Juniper', imageWhite: JuniperWhite },
    { id: 4, image: Cisco, name: 'Cisco', imageWhite: CiscoWhite },
    { id: 5, image: Arista, name: 'Arista', imageWhite: AristaWhite },
    { id: 6, image: Webex, name: 'Webex', imageWhite: WebexWhite },
    // { id: 7, image: Arista, name: 'Arista', imageWhite: AristaWhite },
    { id: 8, image: Azure, name: 'Azure', imageWhite: AzureWhite },
    { id: 9, image: Microsoft, name: 'Microsoft', imageWhite: MicrosoftWhite },
    { id: 10, image: Zoom, name: 'Zoom', imageWhite: ZoomWhite },
    { id: 11, image: Amazon, name: 'Amazon', imageWhite: AmazonWhite },
    { id: 12, image: Paloalto, name: 'Paloalto', imageWhite: PaloaltoWhite },
]

const policySolutions = [
    { id: 1, image: Amazon, name: 'Amazon Web Services', imageWhite: AmazonWhite },
    { id: 2, image: Google, name: 'Google Cloud Services', imageWhite: GoogleWhite },
    { id: 3, image: Azure, name: 'Microsoft Azure', imageWhite: AzureWhite },
    { id: 4, image: Webex, name: 'WebEx', imageWhite: WebexWhite },
    { id: 5, image: Okta, name: 'Okta', imageWhite: OktaWhite }
]

export const getCloudOptionId = (hostname) => {

    let cloudItem = cloudOptions.find(item => {
      return (item.name == hostname);
    });

    return cloudItem.id;
}

export const fetchSupportedVendors = async (errorHandler) => {
    if (localStorage.getItem('accessToken')) {
      try {
        let res = await GET(`api/v1/tenant/supported-vendor/`);
        res = Array.isArray(res) ? res : [res]
        return res;
      } catch (error) {
        console.log(error);
        errorHandler(error)
        
      }
    } else {
      // Handle the case where there's no access token
      errorHandler('Access token is missing')
       return;
    }
  };

  export const fetchVendorConfigurations = async ( errorHandler) => {

    if (localStorage.getItem('accessToken')) {
      try {
        let res = await GET(`api/v1/tenant/vendor/`, {
        });
  
        return res;
      } catch (error) {
        console.log(error);
        errorHandler(error)
      }
    } else {
      // Handle the case where there's no access token
      errorHandler('Access token is missing')
       return;
    }
  };

  export const getSupportedVendorById = (id, supportedVendorList) => {

    let cloudItem = supportedVendorList.find(item => {
      return (item.id == id);
    });

    return cloudItem;
}


export { cloudOptions, otherCloud, hostCloud, peer1, peer2, policySolutions}