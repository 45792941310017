import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET } from '../utilities/ApiProvider';
import Cookies from 'js-cookie';

// Basic Global State
const initialState = {};

// Function For Request Handling For State Update
export const fetchUserName = createAsyncThunk('fetchuser', async () => {
  const response = await GET(`https://jsonplaceholder.typicode.com/users`);
  return { name: response[Math.floor(Math.random() * 10)].name };
});

// State Updating Functions
const userReducer = createSlice({
  name: 'person',
  initialState,
  reducers: {
    updateName: (state, action) => {
      state.value = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
      Cookies.set('user', JSON.stringify(action.payload));
    },
    loadUser: (state, action) => {
      state.value = action.payload;
      localStorage.setItem('en4cibleUser', JSON.stringify(action.payload));
    },
    updateServiceNowUsers: (state, action) => {
      state.serviceNowUsers = action.payload;
    },
    updateServiceNowAuth: (state, action) => {
      state.serviceNowAuth = action.payload;
    },
    pushNotificationState: (state, action) => {
      state.pushNotificationConnectionRef = action.payload;
    },
    // loadLocalStorage: (state, action) => {
    //     state.value = action.payload;
    //     localStorage.setItem('user', JSON.stringify(action.payload))
    // },
    // loadCookies: (state, action) => {
    //     state.value = action.payload;
    //     Cookies.set('user', JSON.stringify(action.payload))
    // },
    logout: (state, action) => {
      console.log('logout triggered');
      localStorage.removeItem('en4cibleUser');
      Cookies.remove('en4cibleUser');
      state.value = {};
    },
  },
  extraReducers: {
    [fetchUserName.fulfilled]: (state, action) => {
      state.value = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
      Cookies.set('user', JSON.stringify(action.payload));
    },
    [fetchUserName.pending]: state => {
      state.value = { name: 'Loading...' };
    },
    [fetchUserName.rejected]: state => {
      state.value = { name: 'Try Again!' };
    },
  },
});

// Exporting All the State Updating Functions
export const {
  updateName,
  loadUser,
  loadLocalStorage,
  loadCookies,
  logout,
  updateServiceNowUsers,
  updateServiceNowAuth,
  pushNotificationState,
} = userReducer.actions;
export default userReducer.reducer;
