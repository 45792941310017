import { Box,Text,Image,Stack} from "@chakra-ui/react";
import tick from "../../assets/images/tick.png";
import { AiOutlineStar } from "react-icons/ai";




const SolutionCard = ()=>{
    return(
<Box  width={"460px"}borderRadius={"4px"} marginLeft={"0!important"}  padding={"20px 10px 20px 20px"}  border={"1px solid #D0D5DD"} color={"black"}>
    <Stack direction={"row"}alignItems={"center"} justifyContent={"space-between"}>
    <Text fontWeight={"600"} fontSize={"15px"}>XDR - Contain Incident: Assets</Text>
    <Stack direction={"row"} bg={"#D3E3FD"} borderRadius={"30px"} padding={"3px 6px"} alignItems={"center"}>
        <Image src={tick} alt="image" />
        <Text fontSize={"10px"} fontWeight={"500"}>Verified</Text>
    </Stack>
    </Stack>
    <Text fontSize={"13px"} m={"5px 0"} fontWeight={"400"} color={"#8A959E"}>System</Text>
    <Box width={"427px"}  backgroundColor={"#EDF1F4"} mt={"8px"} padding={"10px"}>
        <Text fontSize={"13px"} fontWeight={"400"} color={"#8A959E"}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. 
        </Text>
    </Box>
    <Stack direction={"row"}mt={"8px"} justifyContent={"space-between"} alignItems={"center"}>
        <Stack direction={"row"} gap={"12px"}>
        <AiOutlineStar fontSize={"23px"}  color="gray" />
        <Text marginLeft={"10px"} color={"#8A959E"}fontWeight={"400"} fontSize={"14px"}>Last Malicious Sep 21, 2023</Text>
        </Stack>
        <Text fontSize={"10px"} backgroundColor={"#EDF1F4"}padding={"1px 10px"} borderRadius={"30px"} fontWeight={"400"}>Incident</Text>
    </Stack>
</Box>
    )
}


export default SolutionCard;