import { Image, Table, Tbody, Td, Box, Thead, Tr, Text, Tooltip, Button, Icon, useToast } from '@chakra-ui/react';
import { MdEdit, MdDelete } from 'react-icons/md';
import { useState } from 'react'
import { GET, POST } from '../../utilities/ApiProvider';
import { useEffect } from 'react';

export default function CreatePolicy() {
    const toast = useToast();
    // TODO move it to list page or rename page
    const [listOfPolicies, setListOfPolicies] = useState([]);

    const getPolicies = async () => {
        if (localStorage.getItem('accessToken')) {
          try {
            const res = await GET(`api/policy/discovery-risk`);
            console.log(res);
            setListOfPolicies(Array.isArray(res) ? res : [res]); // Ensure hostData is an array
          } catch (error) {
            console.log(error);
            // Handle errors
          }
        } else {
          // Handle the case where there's no access token
          // toast({
          //   position: 'bottom-left',
          //   isClosable: true,
          //   duration: 5000,
          //   status: 'error',
          //   description: 'Access token is missing',
          // });
          // navigate('/');
          // return;
        }
      };

      const stopPolicy = async (id) => {
        try { 
          let response = await POST(`api/policy/complete/`+id);
          
          if (Object.keys(response).length > 0) {
            throw new Error("Failed to stop policy")
          } else {
            toast({
              position: 'bottom-left',
              isClosable: true,
              duration: 5000,
              status: 'success',
              description: 'Policy has been stopped',
            });
          }
        } catch (err) {
          toast({
            position: 'bottom-left',
            isClosable: true,
            duration: 5000,
            status: 'error',
            description: err.message,
          });
        }
        return;
      }

useEffect(() => {
    getPolicies();
}, [])

    const tableHead = [
        'Policy Name',
        'Description',
        'Host',
        'Impact',
        'Context',
        'Action',
        'Zone',
        'Auto-Remediation',
        'Action',
        'Created by/Date',
    ]

    const [datas, setDatas] = useState([
        {
            name: 'Potential Data',
            description: 'Lorem Ipsum dolor sit amet',
            context: 'Collect & Prepare Data',
            impact: 'HIGH',
            status: 'Active',
            condition: 'Yes (Zone A)',
            remediation: '',
        },
        {
            name: 'Potential Data',
            description: 'Lorem Ipsum dolor sit amet',
            context: 'Modal Design & Development',
            impact: 'MEDIUM',
            status: 'Inactive',
            condition: 'Yes (Zone B)',
            remediation: '',
        },
        {
            name: 'Potential Data',
            description: 'Lorem Ipsum dolor sit amet',
            context: 'Model Training, Fine Tuning, & Evaluation',
            impact: 'LOW',
            status: 'Active',
            condition: 'Yes (Zone C)',
            remediation: '',
        },
        {
            name: 'Potential Data',
            description: 'Lorem Ipsum dolor sit amet',
            context: 'Model Deployment, Monitoring, & Management',
            impact: 'INFO',
            status: 'Inactive',
            condition: 'No',
            remediation: '',
        },
        {
            name: 'Potential Data',
            description: 'Lorem Ipsum dolor sit amet',
            context: 'Interference & Prompt Engineering',
            impact: 'HIGH',
            status: 'Active',
            condition: 'Yes (Zone A)',
            remediation: '',
        },
        {
            name: 'Potential Data',
            description: 'Lorem Ipsum dolor sit amet',
            context: 'Infrastructure and Operations',
            impact: 'MEDIUM',
            status: 'Inactive',
            condition: 'Yes (Zone B)',
            remediation: '',
        },
    ])

    return (
        <Table>
            {/* ... Existing table headers */}
            <Thead className='enerpriseRiskTableHead'>
                <Tr bgColor={'#555555'} border={'1px solid #555555'}>
                    {tableHead.map((v, i) => (
                        <Td key={i} color={'#fff'} fontSize={'14px'} fontWeight={'700'}>
                            {v}
                        </Td>
                    ))} {/* Add a closing parenthesis for the map function */}
                </Tr>
            </Thead>
            <Tbody className='enterpriseRiskTable'>
                {listOfPolicies && listOfPolicies.length > 0 ? (
                    listOfPolicies.map((v, i) => {
                        return (
                            <Tr key={i} fontSize={'14px'}>
                                <Td padding={'7px 0 7px 10px'}>{v?.name}</Td>
                                <Td padding={'7px 0 7px 10px'}>{v?.description}</Td>
                                <Td padding={'7px 0 7px 10px'}>{v?.hosts}</Td>
                                <Td padding={'7px 0 7px 10px'}>{v?.impacts && v?.impacts.join(', \n')}</Td>
                                <Td padding={'7px 0 7px 10px'}>{v?.desiredOutcomes}</Td>
                                <Td padding={'7px 0 7px 10px'}>{v?.actions && v?.actions.join(', \n')}</Td>
                                <Td padding={'7px 0 7px 10px'}>{v?.zoneId ?? 'No'}</Td>
                                <Td padding={'7px 0 7px 10px'}>Yes</Td>
                                <Td padding={'7px 0 7px 10px'}><Icon as={MdEdit} /> <Icon as={MdDelete} onClick={() => stopPolicy(v?.processKey)} /></Td>
                                <Td padding={'7px 0 7px 10px'}>{v?.dateTime}</Td>
                            </Tr>
                        )
                    })
                ) : (
                    <Text fontWeight={'700'} textAlign={'center'} mt={'20px'} fontSize='14px' color={'black'}>
                        No Data Found
                    </Text>
                )}
            </Tbody>
        </Table>
    )
}
