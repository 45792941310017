import { Box, Heading, Image, Input, Stack, Text, useDisclosure, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Select } from "@chakra-ui/react";
import SideWrapper from "../components/SideBar/SideWrapper";
import loop from "../assets/images/third-party-images/loop.png";
import crowedStrike from "../assets/images/third-party-images/crowdstrike.png";
import awssec from "../assets/images/third-party-images/awssecurityhub.png";
import service from "../assets/images/third-party-images/servicenow.jpg";
import shield from "../assets/images/third-party-images/shield.png";
import splunk from "../assets/images/third-party-images/splunk.png";
import { Button, Icon } from '@chakra-ui/react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import LocationHeader from "../components/LocationHeader";
import NotificationIcon from "../components/LocationHeader/NotificationIcon.js";
import { useState, useEffect } from "react";
import { Form, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { GET, POST, PUT } from "../utilities/ApiProvider";
import { updateServiceNowUsers, updateServiceNowAuth } from "../reducers/useReducers";
import axios from "axios";

export default function ThirdPartyInteg() {

    const toast = useToast();
    const orgId = localStorage.getItem("orgId");
    const user = useSelector(state => state.value);
    const serviceNowUsers = useSelector(state => state.serviceNowUsers);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();

    const buttonStyle = {
        width: '16%',
        height: '80px',
        borderRadius: '6px',
        padding: "10px 0",
        _hover: { bgColor: '#F0F0F0' },
        _active: { bgColor: '#F0F0F0' },
    };

    const images = [
        { name: 'servicenow', image: service },
        { name: 'loop', image: loop },
        { name: 'crowdstrike', image: crowedStrike },
        { name: 'awssec', image: awssec },
        { name: 'shield', image: shield },
        { name: 'splunk', image: splunk }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [selectedParty, setSelectedparty] = useState(null);
    const [hibernateHtml, setHibernateHtml] = useState(null);

    // https://dev25964.service-now.com
    // 64b887f9c9063110980d370d7bb7a8af
    // u6(2Fd-yxC

    const [serviceNowData, setServiceNowData] = useState({
        customerId: orgId ?? 22,
        url: "",
        clientId: "",
        clientSecret: "",
        type: "SERVICENOW",
    });

    const [serviceNowHibernateData, setServiceNowHibernateData] = useState({
        client_id: "64b887f9c9063110980d370d7bb7a8af",
        client_secret: "u6(2Fd-yxC",
        username: 'admin',
        password: 'PvLg7Q%f2Ho/',
        grant_type: "password",
    });

    const [addUserData, setAddUserData] = useState({
        email: '',
        username: ''
    });

    const [adminInfo, setAdminInfo] = useState({
        username: '',
        passcode: ''
    });

    const getServiceNowUsers = async () => {
        try {
            let response = await GET(`api/itsm/servicenow/instance/${orgId}/SERVICENOW`);
            console.log(response)
            if (response?.id) {
                dispatch(updateServiceNowUsers(response))
            }
        } catch (err) {
            toast({
                description: err?.message ?? 'Something went wrong in third party integration',
                status: 'error',
                position: 'bottom-left',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const checkHibernation = async () => {
        console.log('at hibernate')
        try {
            let response = await POST(`api/itsm/servicenow/user/token`, {
                ...adminInfo,
                customerId: orgId ?? "35",
                type: "SERVICENOW"
            });
            console.log(response)
            if (response?.access_token) {
                dispatch(updateServiceNowAuth(response));
            }
        } catch (err) {
            toast({
                description: err?.message ?? 'Something went wrong in third party integration',
                status: 'error',
                position: 'bottom-left',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const connectServiceNow = async () => {
        setIsLoading(true)
        try {
            let response = await POST(`api/itsm/servicenow/instance`, serviceNowData);
            console.log(response)
            if (response?.id) {
                toast({
                    description: 'Service Now Connected!',
                    status: 'success',
                    position: 'top-right',
                    duration: 3000,
                    isClosable: true,
                });
                getServiceNowUsers();
                checkHibernation();
            }
        } catch (err) {
            toast({
                description: err?.message ?? 'Something went wrong in third party integration',
                status: 'error',
                position: 'bottom-left',
                duration: 3000,
                isClosable: true,
            })
        }
        setIsLoading(false)
    }

    const addUserToServiceNow = async () => {
        setIsLoading(true);
        let addUserRes;
        let data = addUserData;
        data.id = Math.floor(Math.random() * 1000000);

        if (addUserData.email === '' || addUserData.username === '') {
            toast({
                description: 'All fields are required!',
                status: 'error',
                position: 'top-right',
                duration: 3000,
                isClosable: true,
            })
        } else {
            try {
                addUserRes = await PUT(`api/itsm/servicenow/instance/${serviceNowUsers?.id}/users/add`, data);
                if (addUserRes?.id) {
                    toast({
                        description: 'User added successfully!',
                        status: 'success',
                        position: 'top-right',
                        duration: 3000,
                        isClosable: true
                    })
                } else {
                    toast({
                        description: 'Something went wrong!',
                        status: 'error',
                        position: 'top-right',
                        duration: 3000,
                        isClosable: true
                    })
                }
            } catch (err) {
                toast({
                    description: err.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000,
                    isClosable: true
                })
            }
        }
        setAddUserData({
            email: '',
            username: ''
        })
        setIsLoading(false);
        getServiceNowUsers();
        onClose();
    }

    return (
        <SideWrapper>

            <Box padding="30px" width="100%">
                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                    <LocationHeader
                        forcedText={'Third Party Integrations'}
                    />
                    <NotificationIcon />
                </Stack>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={{ base: "center", md: "space-between" }}
                    gap={'10px'}
                    padding={'35px 30px'}
                    background={'#fff'}
                    flexWrap="wrap"
                    borderRadius={'10px'}
                    marginBottom={'20px !important'}
                    spacing={0}
                >
                    {
                        images.map((v, i) =>
                            <Button
                                key={i}
                                {...buttonStyle}
                                width={{ base: "100%", md: "30%", lg: "16%" }}
                                onClick={() => { setSelectedparty(v?.name) }}
                            >
                                <Stack
                                    w={'full'}
                                    direction={'row'}
                                    alignItems={'center'}
                                    gap={'10px'}
                                    justifyContent={'space-between'}
                                >
                                    <Image src={v?.image} w={'160px'} marginX={'auto'} padding={'10px 0'} />
                                </Stack>
                            </Button>
                        )}
                </Stack>
                {
                    selectedParty === null ?
                        <></>
                        : selectedParty !== 'servicenow' ?
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                gap={'10px'}
                                padding={'35px 30px'}
                                background={'#fff'}
                                flexWrap="wrap"
                                borderRadius={'10px'}
                                marginBottom={'20px !important'}
                                spacing={0}
                            >
                                <Text
                                    fontSize={'22px'}
                                    fontWeight={'500'}
                                >{`${selectedParty} is not available currently!`}</Text>
                            </Stack>
                            :
                            <>
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    gap={'10px'}
                                    padding={'35px 30px'}
                                    background={'#fff'}
                                    flexWrap="wrap"
                                    borderRadius={'10px'}
                                    marginBottom={'20px !important'}
                                    spacing={0}
                                    className="serviceFields"
                                >
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        w={'100%'}
                                    >
                                        <Heading fontSize={'26px'} fontWeight={'500'} textTransform={'capitalize'} margin={'0 0 25px 0'}>{selectedParty}</Heading>
                                        <Button
                                            w={'190px'}
                                            bgColor={'primary.100'}
                                            color={'#fff'}
                                            padding={'22px 0'}
                                            fontSize={'16px'}
                                            fontWeight={'500'}
                                            border={'2px solid #4A4A4A'}
                                            _hover={{
                                                bgColor: 'transparent',
                                                color: '#4A4A4A',
                                            }}
                                            onClick={() => onOpen()}
                                        >
                                            Add User
                                        </Button>
                                    </Stack>
                                    <Stack w={'32%'}>
                                        <Typography.Text>Client Id</Typography.Text>
                                        <Form.Item>
                                            <Input
                                                size="large"
                                                name="clientId"
                                                value={serviceNowData.clientId}
                                                onChange={e => {
                                                    setServiceNowData({
                                                        ...serviceNowData,
                                                        clientId: e.target.value
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                    </Stack>
                                    <Stack w={'32%'}>
                                        <Typography.Text>Client Secret</Typography.Text>
                                        <Form.Item>
                                            <Input
                                                size="large"
                                                name="clientSecret"
                                                value={serviceNowData.clientSecret}
                                                onChange={e => {
                                                    setServiceNowData({
                                                        ...serviceNowData,
                                                        clientSecret: e.target.value
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                    </Stack>
                                    <Stack w={'32%'}>
                                        <Typography.Text>Service Now URL</Typography.Text>
                                        <Form.Item>
                                            <Input
                                                size="large"
                                                name="serviceNowUrl"
                                                value={serviceNowData.url}
                                                onChange={e => {
                                                    setServiceNowData({
                                                        ...serviceNowData,
                                                        url: e.target.value
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                    </Stack>
                                    <Stack w={'32%'}>
                                        <Typography.Text>Username</Typography.Text>
                                        <Form.Item>
                                            <Input
                                                size="large"
                                                name="serviceNowUrl"
                                                value={adminInfo.username}
                                                onChange={e => {
                                                    setAdminInfo({
                                                        ...adminInfo,
                                                        username: e.target.value
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                    </Stack>
                                    <Stack w={'32%'}>
                                        <Typography.Text>Passcode</Typography.Text>
                                        <Form.Item>
                                            <Input
                                                size="large"
                                                name="serviceNowUrl"
                                                value={adminInfo.passcode}
                                                onChange={e => {
                                                    setAdminInfo({
                                                        ...adminInfo,
                                                        passcode: e.target.value
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    justifyContent={'flex-end'}
                                    margin={'20px 30px'}
                                >
                                    <Button
                                        isLoading={isLoading}
                                        w={'190px'}
                                        bgColor={'primary.100'}
                                        color={'#fff'}
                                        padding={'22px 0'}
                                        fontSize={'16px'}
                                        fontWeight={'500'}
                                        border={'2px solid #4A4A4A'}
                                        _hover={{
                                            bgColor: 'transparent',
                                            color: '#4A4A4A',
                                        }}
                                        onClick={() => connectServiceNow()}
                                    >
                                        Connect
                                    </Button>
                                </Stack>
                            </>
                }
                {
                    selectedParty !== 'servicenow' && hibernateHtml !== null ?
                        <iframe>{hibernateHtml}</iframe>
                        :
                        <></>
                }
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} size={'lg'} padding={'40px'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add User to ServiceNow</ModalHeader>
                    <ModalBody>
                        <Text fontFamily={"Inter"} fontSize={'16px'} colo={'#667085'} marginBottom={'20px'}>By adding a user you will be able to assign issue to this user in ServiceNow.</Text>
                        <Box
                            border={'1px solid #adadad'}
                            padding={'10px'}
                            cursor={'pointer'}
                            mb={'5px'}
                        >
                            <Text fontSize={'15px'} fontFamily={'Inter'}>Email</Text>
                            <Input
                                onChange={e => setAddUserData({
                                    ...addUserData,
                                    email: e.target.value
                                })}
                                value={addUserData.email}
                            />
                        </Box>
                        <Box
                            border={'1px solid #adadad'}
                            padding={'10px'}
                            cursor={'pointer'}
                        >
                            <Text fontSize={'15px'} fontFamily={'Inter'}>Username</Text>
                            <Input
                                onChange={e => setAddUserData({
                                    ...addUserData,
                                    username: e.target.value
                                })}
                                value={addUserData.username}
                            />
                        </Box>
                        <Stack
                            mt={'30px !important'}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                isLoading={isLoading}
                                onClick={() => addUserToServiceNow()}
                                w={'190px'}
                                bgColor={'#4A4A4A'}
                                color={'#fff'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid #4A4A4A'}
                                _hover={{
                                    bgColor: 'transparent',
                                    color: '#4A4A4A'
                                }}
                            >Add</Button>
                            <Button
                                onClick={() => onClose()}
                                w={'80px'}
                                bgColor={'transparent'}
                                color={'#8A959E'}
                                padding={'22px 0'}
                                fontSize={'16px'}
                                fontWeight={'500'}
                                border={'2px solid transparent'}
                                _hover={{
                                    borderColor: '#4A4A4A',
                                    color: '#4A4A4A'
                                }}
                            >Cancel</Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </SideWrapper>
    );
}
