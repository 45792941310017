import Logo from '../../assets/images/logoF.png';
import { Typography, Input, Form, Divider } from 'antd';
import { EyeFilled, MailOutlined, UserOutlined } from '@ant-design/icons';
import {
  HiOutlineShoppingBag,
  HiOutlineOfficeBuilding,
  HiOutlineLocationMarker,
  HiOutlineKey,
  HiOutlinePhone,
} from 'react-icons/hi';
import { GiFactory } from 'react-icons/gi';
import { AiOutlineIdcard } from 'react-icons/ai';
import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Input as ChakrInput,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { useState } from 'react';
import countryList from 'react-select-country-list';
import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { POST, GET } from '../../utilities/ApiProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseURL } from '../../utilities/config';
import NoSslModal from '../Modals/NoSslModal';
import Iframe from 'react-iframe'
import { useRef } from 'react';

export default function Register({ setShowLogin, isLoading, setIsLoading }) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigation = useNavigate();
  const toast = useToast();
  const [showPass, setShowPass] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isRegOpen, onOpen: onRegOpen, onClose: onRegClose } = useDisclosure();
  const options = useMemo(() => countryList().getData(), []);

  const [registerData, setRegisterData] = useState({
    orgName: '',
    businessType: '',
    contactNumber: '',
    country: '',
    ipHostVM: '0.1.2.3',
    license: {
      licenseName: '',
      startTime: '2023-08-10T01:20:14',
      endTime: '2023-11-18T01:20:14',
    },
    organizationPreference: [
      {
        dataValue: '',
        preference: 'redis.url'
      },
      {
        dataValue: '',
        preference: 'mongodb.url'
      },
      {
        dataValue: '',
        preference: 'postgres.url'
      },
      {
        dataValue: '',
        preference: 'mysql.url'
      },
    ],
    defaultUser: {
      firstName: '',
      lastName: '',
      email: '',
      userName: '',
      tempPassword: '',
      role: '',
    },
  });

  const [licenseData, setLicenseData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState({});
  const [loginResponseUrl, setLoginResponseUrl] = useState(null);

  useEffect(() => {
    if (loginResponseUrl) {
      onRegOpen();
    }
  }, [loginResponseUrl])


  const getLicense = async () => {
    setIsLoading(true);
    try {
      let licenseRes = await POST('api/onboard/org/license/category/search', {})
      if (licenseRes?.content) {
        setLicenseData(licenseRes?.content);
      } else {
        onOpen();
      }
      let roleRes = await GET('api/onboard/roles')
      if (roleRes?.content) {
        setRoleData(roleRes?.content);
      } else {
        onOpen();
      }
    } catch (err) {
      toast({
        description: err?.message,
        isClosable: true,
        status: 'error',
        position: 'top-right',
        duration: 4500,
      });
    }
    setIsLoading(false);
  }

  const submitRegistration = async () => {
    setIsLoading(true);
    let data = registerData;
    data.license = {
      licenseName: selectedLicense?.licenseName,
      startTime: selectedLicense?.startTime,
      endTime: selectedLicense?.endTime,
    };
    try {
      let response = await POST(`api/onboard/org`, data);
      if (response.loginUrl) {
        // setLoginResponseUrl(response.loginUrl);
        toast({
          description: `User registered, Click proceed to complete remaining verification!`,
          isClosable: true,
          status: 'success',
          position: 'top-right',
          duration: 4500,
        });
        localStorage.setItem('orgId', response?.id);
        setTimeout(() => {
          window.open(`${response?.loginUrl}`, '_blank');
        }, 3000);
      } else {
        toast({
          description: response?.debugMessages[0],
          isClosable: true,
          status: 'error',
          position: 'top-right',
          duration: 4500,
        });
      }
    } catch (error) {
      toast({
        description: error.message,
        isClosable: true,
        status: 'error',
        position: 'top-right',
        duration: 4500,
      });
    }
    setIsLoading(false);
  };
  // 53
  // inpl_44870
  useEffect(() => {
    getLicense();
  }, [])

  const location = useLocation();

  function updateDataVal(ind, value) {
    let newArr = registerData?.organizationPreference.map((val, index) => {
      if (index === ind) {
        return {
          ...val,
          dataValue: value
        }
      } else return val
    });

    setRegisterData({
      ...registerData,
      organizationPreference: newArr
    })
  }

  return (
    <>
      <NoSslModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        getLicense={getLicense}
      />
      {
        loginResponseUrl &&
        <Iframe
          url={loginResponseUrl ?? ''}
          width="640px"
          height="320px"
          id=""
          className=""
          display="block"
          position="absolute"
        />
      }
      {/* <iframe loading="lazy" src={loginResponseUrl ?? ''} width={'1000px'} height={'600px'} style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}></iframe> */}
      <Image src={Logo} width={'160px'} margin={'65px auto 43px'} />
      <Heading color={'primary.100'} fontSize={'30px'} fontWeight={600}>
        Register Now
      </Heading>
      <Text fontSize={'16px'} margin={'5px 0 0 0'} color={'#8A959E'}>
        Please register your account by providing following details
      </Text>
      <Divider style={{ margin: '2.25rem 0' }} />
      <form
        onSubmit={handleSubmit(e => {
          submitRegistration(e);
        })}
      >
        <Stack direction={'row'} mb={4} gap={'20px'}>
          <Stack w={'full'}>
            <Typography.Text>First Name</Typography.Text>
            <Form.Item>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: 'This is Required' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    name="firstName"
                    placeholder="Allen"
                    prefix={<UserOutlined />}
                    onChange={e => {
                      setRegisterData({
                        ...registerData,
                        defaultUser: {
                          ...registerData.defaultUser,
                          firstName: e.target.value,
                        },
                      });
                    }}
                  />
                )}
              />

              <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
                {errors.firstName?.message}
              </Text>
            </Form.Item>
          </Stack>
          <Stack w={'full'}>
            <Typography.Text>Last Name</Typography.Text>
            <Form.Item>
              <Controller
                name="username"
                control={control}
                rules={{ required: 'This is Required' }}
                render={({ field }) => (
                  <Input
                    size="large"
                    {...field}
                    name="lastName"
                    placeholder="James"
                    prefix={<UserOutlined />}
                    onChange={e => {
                      setRegisterData({
                        ...registerData,
                        defaultUser: {
                          ...registerData.defaultUser,
                          lastName: e.target.value,
                        },
                      });
                    }}
                  />
                )}
              />

              <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
                {errors.lastName?.message}
              </Text>
            </Form.Item>
          </Stack>
        </Stack>
        <Stack direction={'row'} gap={'20px'}>
          <Stack w={'full'}>
            <Typography.Text>Email Address</Typography.Text>
            <Form.Item>
              <Controller
                name="username"
                control={control}
                rules={{ required: 'This is Required' }}
                render={({ field }) => (
                  <Input
                    size="large"
                    {...field}
                    name="email"
                    placeholder="allen@gmail.com"
                    prefix={<MailOutlined />}
                    onChange={e => {
                      setRegisterData({
                        ...registerData,
                        defaultUser: {
                          ...registerData.defaultUser,
                          email: e.target.value,
                          userName: e.target.value,
                        },
                      });
                    }}
                  />
                )}
              />

              <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
                {errors.email?.message}
              </Text>
            </Form.Item>
          </Stack>
          <Stack w={'full'}>
            <Typography.Text>Phone Number</Typography.Text>
            <Form.Item>
              <Controller
                name="username"
                control={control}
                rules={{ required: 'This is Required' }}
                render={({ field }) => (
                  <Input
                    size="large"
                    {...field}
                    name="contactNumber"
                    placeholder="(code) xxx xxxx xxx"
                    prefix={<Icon as={HiOutlinePhone} />}
                    onChange={e => {
                      setRegisterData({
                        ...registerData,
                        contactNumber: e.target.value,
                      });
                    }}
                  />
                )}
              />

              <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
                {errors.contactNumber?.message}
              </Text>
            </Form.Item>
          </Stack>
        </Stack>
        <Stack w={'full'} marginTop={'16px'}>
          <Typography.Text>Password</Typography.Text>
          <Form.Item>
            <Controller
              name="username"
              control={control}
              rules={{ required: 'This is Required' }}
              render={({ field }) => (
                <Input
                  type={showPass ? 'text' : 'password'}
                  style={{ marginBottom: '16px' }}
                  {...field}
                  name="tempPassword"
                  size="large"
                  placeholder="*****************"
                  prefix={<Icon as={HiOutlineKey} />}
                  onChange={e => {
                    setRegisterData({
                      ...registerData,
                      defaultUser: {
                        ...registerData.defaultUser,
                        tempPassword: e.target.value,
                      },
                    });
                  }}
                />
              )}
            />

            <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
              {errors.tempPassword?.message}
            </Text>
            <Button
              onClick={() => setShowPass(!showPass)}
              position={'absolute'}
              right={'5px'}
              top={0}
              background={'transparent'}
              _hover={{ bgColor: 'transparent' }}
              _active={{ bgColor: 'transparent' }}
              zIndex={10}
            >
              <Icon as={showPass ? BsEyeFill : BsEyeSlashFill} />
            </Button>
          </Form.Item>
        </Stack>
        <Stack direction={'row'} gap={'20px'}>
          <Stack w={'full'}>
            <Typography.Text>Organization/Company</Typography.Text>
            <Form.Item>
              <Controller
                name="username"
                control={control}
                rules={{ required: 'This is Required' }}
                render={({ field }) => (
                  <Input
                    size="large"
                    {...field}
                    name="orgId"
                    placeholder=""
                    prefix={<Icon as={HiOutlineShoppingBag} />}
                    onChange={e => {
                      setRegisterData({
                        ...registerData,
                        orgName: e.target.value,
                      });
                    }}
                  />
                )}
              />

              <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
                {errors.tempPassword?.message}
              </Text>
            </Form.Item>
          </Stack>
        </Stack>
        <Stack direction={'row'} gap={'20px'} marginTop={'16px'}>
          <Stack w={'full'}>
            <Typography.Text>Industry/Vertical</Typography.Text>
            <Box
              display={'flex'}
              alignItems={'center'}
              border={'1px solid #d9d9d9'}
              borderRadius={8}
              height={'40px'}
              paddingX={'10px'}
            >
              <Icon as={GiFactory} />
              <Controller
                name="username"
                control={control}
                rules={{ required: 'This is Required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    name="businessType"
                    border={'none'}
                    _focusVisible={{ outline: 'none' }}
                    onChange={e => {
                      setRegisterData({
                        ...registerData,
                        businessType: e.target.value,
                      });
                    }}
                  >
                    <option style={{ display: 'none' }} value={''}></option>
                    <option value="government">Government</option>
                    <option value="healthcare">Healthcare</option>
                    <option value="financial">Financial</option>
                    <option value="defence">Defence</option>
                    <option value="techVendors">Tech Vendors</option>
                    <option value="saas/CloudNative">Saas/Cloud Native</option>
                  </Select>
                )}
              />
            </Box>
            <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
              {errors.businessType?.message}
            </Text>
          </Stack>
          <Stack w={'full'}>
            <Typography.Text>Job Title/Role</Typography.Text>
            <Box
              display={'flex'}
              alignItems={'center'}
              border={'1px solid #d9d9d9'}
              borderRadius={8}
              height={'40px'}
              paddingX={'10px'}
            >
              <Icon as={HiOutlineOfficeBuilding} />
              <Controller
                name="username"
                control={control}
                rules={{ required: 'This is Required' }}
                render={({ field }) => (
                  <Select
                    border={'none'}
                    {...field}
                    name="role"
                    _focusVisible={{ outline: 'none' }}
                    onChange={e => {
                      setRegisterData({
                        ...registerData,
                        defaultUser: {
                          ...registerData.defaultUser,
                          role: e.target.value,
                        },
                      });
                    }}
                  >
                    <option style={{ display: 'none' }} value={''}></option>
                    {
                      roleData?.length > 0 &&
                      roleData.map(val =>
                        <option key={val?.id} value={val?.roleType.toUpperCase()}>{val?.roleType}</option>
                      )
                    }
                  </Select>
                )}
              />
            </Box>
            <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
              {errors.role?.message}
            </Text>
          </Stack>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} spacing={0} gap="1%" flexWrap={'wrap'} w={'full'} marginTop={'16px'}>
          {
            registerData.organizationPreference?.map((val, ind) =>
              <Stack w="48%" mb="15px !important" key={ind}>
                <Typography.Text style={{ textTransform: 'capitalize' }}>{String(val?.preference).split('.')[0]}-url</Typography.Text>
                <Input
                  size="large"
                  type="text"
                  placeholder=""
                  value={val?.dataValue}
                  onChange={e => updateDataVal(ind, e.target.value)}
                />
              </Stack>
            )
          }

        </Stack>
        <Stack w={'full'} marginTop={'16px'}>
          <Typography.Text>License Type</Typography.Text>
          <Box
            display={'flex'}
            alignItems={'center'}
            border={'1px solid #d9d9d9'}
            borderRadius={8}
            height={'40px'}
            paddingX={'10px'}
          >
            <Icon as={AiOutlineIdcard} />
            <Controller
              name="username"
              control={control}
              rules={{ required: 'This is Required' }}
              render={({ field }) => (
                <Select
                  isDisabled={isLoading}
                  border={'none'}
                  {...field}
                  name="license"
                  _focusVisible={{ outline: 'none' }}
                  onChange={e => {
                    setSelectedLicense(JSON.parse(e.target.value));
                  }}
                >
                  <option style={{ display: 'none' }} value={''}>
                    License Type
                  </option>
                  {
                    licenseData.length &&
                    licenseData?.map(v =>
                      <option
                        key={v?.id}
                        value={JSON.stringify({
                          licenseName: `${v?.licenseName ?? 'INFINITE'}`
                        })}
                      >
                        {v?.licenseName ?? 'INFINITE'}
                      </option>
                    )
                  }
                </Select>
              )}
            />
          </Box>
          <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
            {errors.license?.message}
          </Text>
        </Stack>
        <Stack marginTop={'16px'}>
          <Typography.Text>Region</Typography.Text>
          <Box
            display={'flex'}
            alignItems={'center'}
            border={'1px solid #d9d9d9'}
            borderRadius={8}
            height={'40px'}
            paddingX={'10px'}
          >
            <Icon as={HiOutlineLocationMarker} />
            <Controller
              name="username"
              control={control}
              rules={{ required: 'This is Required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  name="country"
                  border={'none'}
                  _focusVisible={{ outline: 'none' }}
                  onChange={e => {
                    setRegisterData({
                      ...registerData,
                      country: e.target.value,
                    });
                  }}
                >
                  <option style={{ display: 'none' }} value={''}>
                    Country
                  </option>
                  {options.length > 0 &&
                    options.map(val => (
                      <option key={val?.value} value={val?.value}>
                        {val?.label}
                      </option>
                    ))}
                </Select>
              )}
            />
          </Box>
          <Text as={'span'} fontSize={'12px'} color={'#F80D4D'}>
            {errors.country?.message}
          </Text>
        </Stack>
        <Stack
          margin={'34px 0'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography.Text color="#262626">
            Already have an account?{' '}
          </Typography.Text>
          <Typography.Text
            style={{
              textDecoration: 'underline',
              color: '#2B2B2C',
              fontWeight: '600',
              cursor: 'pointer',
            }}
            onClick={() => setShowLogin(true)}
          >
            Login
          </Typography.Text>
        </Stack>
        <Button
          //  type="submit"
          isLoading={isLoading}
          onClick={() => submitRegistration()}
          bgColor={'primary.100'}
          color={'#fff'}
          padding={'12px'}
          w={'full'}
          marginBottom={'80px'}
          fontSize={'16px'}
          fontWeight={'500'}
          border={'2px solid #4A4A4A'}
          _hover={{
            bgColor: 'transparent',
            color: '#4A4A4A',
          }}
        >
          Register
        </Button>
      </form>
      <Modal
        isOpen={isRegOpen}
        onClose={onRegClose}
        size={'xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Step 1 Completed!</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Text>Please complete your registration by verifiying you email at key cloak</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              // as={'a'}
              // target='_blank'
              // href={loginResponseUrl}
              onClick={() => {
                window.open(`${loginResponseUrl}`, '_blank');
                onRegClose();
                setShowLogin(true);
              }}
              bgColor={'#4A4A4A'}
              color={'#fff'}
              padding={'12px'}
              fontSize={'16px'}
              fontWeight={'500'}
              border={'2px solid #4A4A4A'}
              _hover={{
                bgColor: 'transparent',
                cursor: 'pointer',
                color: '#4A4A4A',
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
