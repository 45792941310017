import { Box, Stack, Heading, Button, Accordion, AccordionItem, AccordionButton, Icon, AccordionPanel, Text, Input as CharkInput, Select } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SideWrapper from '../components/SideBar/SideWrapper.js';
import SpacedStack from '../components/SpacedStack/index.js'
import LocationHeader from '../components/LocationHeader/index.js';
import { useState } from 'react';
import { Typography, Input } from 'antd';
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';

export default function RestApi() {
  const navigation = useNavigate();
  const user = useSelector(state => state?.value);
  const [data, setData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [selectedDesireOption, setSelectedDesireOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // if(user === undefined || user == null){
  //   navigation('/auth');
  // }


  return (
    <SideWrapper>
      <Stack w={'full'} margin={'40px 50px 30px 0 !important'}>
        {/* Current Location identifier */}
        <LocationHeader forcedText={'Desired Outcomes > Enhance Control Coverage > Rest API'} />
        {/* Cloud options */}
        <SpacedStack>
          <Stack>
            <Stack
              direction={'row'}
              spacing={0}
              gap={6}
              flexWrap={'wrap'}
              className='vendorConfigFieldsBox'
            >
              <Box w={"35%"} margin={'0 0 15px 0 !important'}>
                <Typography.Text>Name (Unique)</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder="Production Account Configuration"
                />
              </Box>
              <Box w={"50%"} margin={'0 0 15px 0 !important'}>
                <Typography.Text>API Endpoint</Typography.Text>
                <Input
                  size="md"
                  p={4}
                  borderRadius={6}
                  placeholder="123xxx555xxx"
                />
              </Box>
            </Stack>
            <Box w={'full'} className="vendorConfigOpt">
              <Heading fontSize={'22px'} color={'#2626262'} margin={'30px 0 0 0'}>
                Parse API Response
              </Heading>
            </Box>
            <Box w={'full'} margin={'25px 0 0 0 !important'}>
              <Accordion
                defaultIndex={[0, 1]}
                allowMultiple
                allowToggle
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
              >
                <AccordionItem
                  w={'47%'}
                  border={'none'}
                  borderRadius={'6px'}
                  mb={'30px'}
                >
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          border={0}
                          outlineOffset={'0px'}
                        >
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            borderRadius={'6px'}
                          >
                            Extract Result
                          </Box>
                          {isExpanded ? (
                            <Icon as={PlusSquareOutlined} fontSize="18px" />
                          ) : (
                            <Icon as={MinusSquareOutlined} fontSize="18px" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={0} px={0}>
                        <Stack
                          bgColor={'primary.100'}
                          color={'#fff'}
                          direction={'row'}
                        >
                          <Text
                            py={'20px'}
                            w={'150px'}
                            borderRight={'1px solid #E8EDFC'}
                            pl={'20px'}
                          >
                            Variable
                          </Text>
                          <Text py={'20px'} pl={'20px'}>
                            Value (JSON Path Expression)
                          </Text>
                        </Stack>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={0}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            Account_json
                          </Text>
                          <Box w={'78%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                            <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='rgn1'
                              placeholder="Value 1"
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction={'row'}
                          spacing={0}
                          alignItems={'center'}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                          </Text>
                          <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                            {/* <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='format'
                              placeholder="Value 2"
                            /> */}
                          </Box>
                        </Stack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem
                  w={'47%'}
                  border={'none'}
                  borderRadius={'6px'}
                  mb={'30px'}
                >
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton>
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            borderRadius={'6px'}
                          >
                            Http Headers
                          </Box>
                          {isExpanded ? (
                            <Icon as={PlusSquareOutlined} fontSize="18px" />
                          ) : (
                            <Icon as={MinusSquareOutlined} fontSize="18px" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={0} px={0}>
                        <Stack
                          bgColor={'primary.100'}
                          color={'#fff'}
                          direction={'row'}
                        >
                          <Text
                            py={'20px'}
                            w={'150px'}
                            borderRight={'1px solid #E8EDFC'}
                            pl={'20px'}
                          >
                            Content
                          </Text>
                          <Text py={'20px'} pl={'20px'}>
                            Application/json
                          </Text>
                        </Stack>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={0}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            Name
                          </Text>
                          <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                            <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='name'
                              type='text'
                              placeholder="name"
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={0}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            RGN
                          </Text>
                          <Box
                            w={'78%'}
                            p={'20px 12px'}
                          >
                            <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='rgn2'
                              type='text'
                              placeholder="value"
                            />
                          </Box>
                        </Stack>
                        {/* <Stack direction={'row'} spacing={0}>
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            Content
                          </Text>
                          <Box
                            border={'1px solid #E8EDFC'}
                            w={'78%'}
                            p={'12px'}
                            display={'flex'}
                            alignItems={'center'}
                            gap={'10px'}
                          >
                            <CharkInput
                              border={'1px solid #E8EDFC'}
                              // style={{ width: '82px', display: 'none' }}
                              size="large"
                              placeholder="Value 2"
                              name='content'
                              type="file"
                            />
                          </Box>
                        </Stack> */}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>
            <Box w={'full'} margin={'25px 0 0 0 !important'}>
              <Accordion
                defaultIndex={[0, 1]}
                allowMultiple
                allowToggle
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
              >
                <AccordionItem
                  w={'47%'}
                  border={'none'}
                  borderRadius={'6px'}
                  mb={'15px'}
                >
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          border={0}
                          outlineOffset={'0px'}
                        >
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            borderRadius={'6px'}
                          >
                            Uri Parameters
                          </Box>
                          {isExpanded ? (
                            <Icon as={PlusSquareOutlined} fontSize="18px" />
                          ) : (
                            <Icon as={MinusSquareOutlined} fontSize="18px" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={0} px={0}>
                        <Stack
                          bgColor={'primary.100'}
                          color={'#fff'}
                          direction={'row'}
                        >
                          <Text
                            py={'20px'}
                            w={'150px'}
                            borderRight={'1px solid #E8EDFC'}
                            pl={'20px'}
                          >
                            Variable / Key
                          </Text>
                          <Text py={'20px'} pl={'20px'}>
                            Value (Context Variable)
                          </Text>
                        </Stack>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={0}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            ac_id
                          </Text>
                          <Box w={'78%'} border={'1px solid #E8EDFC'} p={'20px 12px'}>
                            {/* <Input
                              _focusVisible={{
                                outlineOffset: '0px'
                              }}
                              border={'0 !important'}
                              style={{ width: '82px' }}
                              size="large"
                              name='rgn1'
                              placeholder="Value 1"
                            /> */}
                            <Select w={'200px'}>
                              <option>Argument 1</option>
                              <option>Argument 2</option>
                            </Select>
                          </Box>
                        </Stack>
                        <Stack
                          direction={'row'}
                          spacing={0}
                          alignItems={'center'}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            Format
                          </Text>
                          <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                            {/* <Text
                              border={'1px solid #E8EDFC'}
                              py={'20px'}
                              w={'150px'}
                              pl={'20px'}
                            > */}
                              json
                            {/* </Text> */}
                          </Box>
                        </Stack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                <AccordionItem
                  w={'47%'}
                  border={'none'}
                  borderRadius={'6px'}
                  mb={'15px'}
                >
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton>
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            borderRadius={'6px'}
                          >
                            Query Parameters
                          </Box>
                          {isExpanded ? (
                            <Icon as={PlusSquareOutlined} fontSize="18px" />
                          ) : (
                            <Icon as={MinusSquareOutlined} fontSize="18px" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={0} px={0}>
                        <Stack
                          bgColor={'primary.100'}
                          color={'#fff'}
                          direction={'row'}
                        >
                          <Text
                            py={'20px'}
                            w={'150px'}
                            borderRight={'1px solid #E8EDFC'}
                            pl={'20px'}
                          >
                            Variable / Key
                          </Text>
                          <Text py={'20px'} pl={'20px'}>
                            Value (Context Variable)
                          </Text>
                        </Stack>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={0}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            rgn
                          </Text>
                          <Box border={'1px solid #E8EDFC'} w={'78%'} p={'20px 12px'}>
                            <Select w={'200px'}>
                              <option>Argument 1</option>
                              <option>Argument 2</option>
                            </Select>
                          </Box>
                        </Stack>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={0}
                        >
                          <Text
                            border={'1px solid #E8EDFC'}
                            py={'20px'}
                            w={'150px'}
                            pl={'20px'}
                          >
                            Format
                          </Text>
                          <Box
                            w={'78%'}
                            p={'20px 12px'}
                          >
                            {/* <Text
                              border={'1px solid #E8EDFC'}
                              py={'20px'}
                              w={'150px'}
                              pl={'20px'}
                            > */}
                              json
                            {/* </Text> */}
                          </Box>
                        </Stack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Button
              isLoading={isLoading}
              w={'190px'}
              bgColor={'primary.100'}
              color={'#fff'}
              padding={'22px 0'}
              fontSize={'16px'}
              fontWeight={'500'}
              border={'2px solid #4A4A4A'}
              _hover={{
                bgColor: 'transparent',
                color: '#4A4A4A',
              }}
            >
              Save
            </Button>
            <Button
              w={'190px'}
              bgColor={'transparent'}
              color={'#8A959E'}
              padding={'22px 0'}
              fontSize={'16px'}
              fontWeight={'500'}
              border={'2px solid transparent'}
              _hover={{
                borderColor: '#4A4A4A',
                color: '#4A4A4A',
              }}
            >
              Cancel
            </Button>
          </Stack>
        </SpacedStack>

      </Stack>
    </SideWrapper>
  );
}
