import { BsCloudDownload, BsFillPersonLinesFill, BsCameraVideo, AiOutlineFileProtect } from 'react-icons/bs'
import { FaFile } from 'react-icons/fa'
import { cloudOptions } from './cloudOnBoardingData';
const Enforcement = [
     {
          name: 'Generate Alert/Sent Email',
          id: 1,
     },
     {
          name: 'Issue a Ticket (ITSM)',
          id: 4,
     },
     {
          name: 'Enforce Policy - Time bound',
          id: 6,
     },
     {
          name: 'Accept/Transfer Risk',
          id: 2,
     },
     {
          name: 'Assign To (Triage)',
          id: 3,
     },
     {
          name: 'Check/Implement Pre-requistes for Policy Enforcement',
          id: 5,
     },
     {
          name: 'Enforce Policy - Ongoing',
          id: 7,
     },

];

const solutionAzureOpt1 = [
     {
          id: 1, name: 'High-Availability'
     },
     {
          id: 6, name: 'Threat Detection'
     },
     {
          id: 2, name: 'Monitoring/Logging'
     },
     {
          id: 3, name: 'Encryption, Data Protection'
     },
     {
          id: 10, name: 'Denial of Service'
     },
     {
          id: 7, name: 'Automation, Orchestration'
     },
     {
          id: 8, name: 'Visbility/Analytics'
     },
     {
          id: 4, name: 'IAM, Access Control'
     },
     {
          id: 5, name: 'Vulnerability Management'
     },
     {
          id: 9, name: 'Communications Fraud'
     },
]

const policyAction = [
     { id: 1, name: 'Asset Discovery' },
     { id: 2, name: 'Risk Assessment' },
     { id: 3, name: 'Monitor and Report Changes' },
     { id: 4, name: 'Monitor and Remediate' },
]

const policyCloudOptions = [
     ...cloudOptions,
     { icon: FaFile, name: 'Policies' }
]

export { Enforcement, solutionAzureOpt1, policyAction, policyCloudOptions }