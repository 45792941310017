import storage from '../assets/images/agentic-icons/storage.png';
import tag from '../assets/images/agentic-icons/tag.png';
import shield from '../assets/images/agentic-icons/shield.png';
import house from '../assets/images/agentic-icons/house.png';
import brain from '../assets/images/agentic-icons/brain.png';
import justice from '../assets/images/agentic-icons/justice.png';
import hazard from '../assets/images/agentic-icons/radioactive.png';
import cpu from '../assets/images/agentic-icons/circuit.png';

export const coreData = [
  {
    id: 1,
    heading: 'Multi-Agent Resource Management',
    description:
      'Aggregate and triage trust signals across agentic Al landscape',
    icon: storage,
  },
  {
    id: 2,
    heading: 'Decision and Reasoning Validation',
    description:
      "Real-Time annotation of agents' risk and performance with a robust HITL and Advanced NLP",
    icon: tag,
  },
  {
    id: 3,
    heading: 'Graceful Decommission of Harmful Decisions & Tasks ',
    description:
      'Seamless deactivation of unreliable and harmful decisions, interactions, and task execution',
    icon: shield,
  },
  {
    id: 4,
    heading: 'Agentic Workflow Optimization Insights',
    description:
      'Generate Comprehensive risk and workflow optimization metrics to track business outcome alignment',
    icon: house,
  },
  {
    id: 5,
    heading: 'Recursive Monitoring & Control Center',
    description:
      'Iteratively monitor the purpose, ownership, and business alignment of autonomous agents',
    icon: brain,
  },
  {
    id: 6,
    heading: 'Ethics and Responsible AI Oversight',
    description:
      'Detect and mitigate bias in your data, actions, and interactions to ensure faimess',
    icon: justice,
  },
  {
    id: 7,
    heading: 'High-stake AI Interaction Governance',
    description:
      'Governance of business-critical Al assets, data, and interaction',
    icon: hazard,
  },
  {
    id: 8,
    heading: 'GPU Utilization Optimization',
    description:
      'Reduce GPU utilization by up to 40% for Model Training and Fine-Tuning',
    icon: cpu,
  },
];
