import { Button, Box, Image, Menu, MenuButton, Stack, Text, MenuList, MenuItem, Icon, Heading } from '@chakra-ui/react'
import FlowChart from '../../assets/images/zeroTrustFlowChart.svg'
import { cloudOptions } from '../../utilities/cloudOnBoardingData'
import CloudOptionButton from '../CloudComponents/CloudOptionButton'
import { useState } from 'react';
import { HiUserAdd, HiOutlineDeviceMobile } from 'react-icons/hi';
import { BsCloudDownload, BsFillPersonLinesFill, BsChevronDown, BsFileArrowDown, BsDatabaseDown } from 'react-icons/bs';
import { AiOutlineIdcard, AiOutlineDatabase, AiOutlineSetting, AiOutlineLineChart } from 'react-icons/ai'
import { BiGlobe } from 'react-icons/bi'
import { GoDeviceMobile } from 'react-icons/go'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import EnterpriseRiskTolerance from './EnterpriseRiskTolerance/EnterpriseRiskTolerance.js'
import { Pie } from 'react-chartjs-2';
import SpacedStack from '../SpacedStack/index.js';

export default function ZeroTrustPosture() {

    const [selectedCloudOption, setSelectedCloudOption] = useState('');
    const trustOptions = [
        { icon: BsCloudDownload, name: 'Host Cloud', },
        { icon: BsFillPersonLinesFill, name: 'Peer 2' },
        { icon: HiUserAdd, name: 'Additional Peers' },
        // { icon: BiGlobe, name: 'Network' },
    ]
    const buttons = ["Inventory, Assets, Resources, Management", "Secure Communication", " Dynamic Resource Authentication & Authorization", "Integrity & Security Posture Monitoring", "Current State of Assets, Network Infrastructure & Communication"]
    const [selectedLeftBtn, setSelectedLeftBtn] = useState(0);
    const [buttonsContent, setButtonsContent] = useState([
        {
            icon: AiOutlineIdcard,
            heading: 'User / Identity',
            content: "Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space."
        },
        {
            icon: GoDeviceMobile,
            heading: 'Device',
            content: "Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space."
        },
        {
            icon: BsFileArrowDown,
            heading: 'Application',
            content: "Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space."
        },
        {
            icon: AiOutlineDatabase,
            heading: 'Data',
            content: "Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space.Lorem ipsum dolar sit is an dummy text to fill up some space."
        },
    ]);

    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: [24, 12, 3],
        datasets: [
            {
                data: [24, 12, 3],
                backgroundColor: [
                    '#36454f',
                    '#b0b0b0',
                    '#717171',
                ],
                borderColor: [
                    '#717171',
                    '#717171',
                    '#717171',
                ],
                borderWidth: 1,

            },
        ],
    };

    return (
        <>
            <Stack
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'15px'}
                w={"100%"}
                margin={'0 auto'}
            >
                <Stack
                    w={'full'}
                    direction={'row'}
                    alignItems={'center'}
                    background={'#fff'}
                    marginBottom={'20px !important'}
                    borderRadius={'10px'}
                    spacing={0}
                    overflow={'hidden'}
                >
                    {trustOptions.map((v, i) => (
                        <CloudOptionButton
                            {...v}
                            key={i}
                            selectedCloudOption={selectedCloudOption}
                            setSelectedCloudOption={setSelectedCloudOption}
                        />
                    ))}
                    <Menu>
                        <MenuButton
                            textAlign={'left'}
                            w={'24%'}
                            as={Button}
                            rightIcon={<Icon as={BsChevronDown} />}
                            leftIcon={<Icon as={BiGlobe} />}
                            bgColor={'transparent'}
                            height={'60px'}
                            color={'#c3c9d2'}
                            _active={{
                                color: '#36454f'
                            }}
                            _hover={{
                                color: '#36454f'
                            }}
                        >
                            Network
                        </MenuButton>
                        <MenuList w={'xs'} >
                            <MenuItem fontWeight={'500'} borderBottom={'1px solid #adadad'} p={'10px'} maxW={'90%'} mx={'auto'} ><Icon as={AiOutlineIdcard} mr={'10px'} /> User / Identity</MenuItem>
                            <MenuItem fontWeight={'500'} borderBottom={'1px solid #adadad'} p={'10px'} maxW={'90%'} mx={'auto'} ><Icon as={GoDeviceMobile} mr={'10px'} /> Device</MenuItem>
                            <MenuItem fontWeight={'500'} borderBottom={'1px solid #adadad'} p={'10px'} maxW={'90%'} mx={'auto'} ><Icon as={BsFileArrowDown} mr={'10px'} /> Application</MenuItem>
                            <MenuItem fontWeight={'500'} borderBottom={'1px solid #adadad'} p={'10px'} maxW={'90%'} mx={'auto'} ><Icon as={AiOutlineDatabase} mr={'10px'} /> Data</MenuItem>
                            <MenuItem fontWeight={'500'} borderBottom={'1px solid #adadad'} p={'10px'} maxW={'90%'} mx={'auto'} ><Icon as={BiGlobe} mr={'10px'} /> Network</MenuItem>
                            <MenuItem fontWeight={'500'} borderBottom={'1px solid #adadad'} p={'10px'} maxW={'90%'} mx={'auto'} ><Icon as={AiOutlineSetting} mr={'10px'} /> Automation & Orchestration</MenuItem>
                            <MenuItem fontWeight={'500'} p={'10px'} maxW={'90%'} mx={'auto'} ><Icon as={AiOutlineLineChart} mr={'10px'} /> Visibility & Analytics</MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
                {
                    selectedCloudOption !== '' &&
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        padding={'35px 30px'}
                        bgColor={'#fff'}
                    >
                        <Box w={'28%'}>
                            {
                                buttons.map((v, i) =>
                                    <Button
                                        key={i}
                                        w={'100%'}
                                        bgColor={selectedLeftBtn === i ? 'primary.100' : 'transparent'}
                                        color={selectedLeftBtn === i ? '#fff' : '#4A4A4A'}
                                        padding={'26px 20px'}
                                        onClick={() => { setSelectedLeftBtn(i) }}
                                        fontSize={'16px'}
                                        fontWeight={'500'}
                                        border={'2px solid #4A4A4A'}
                                        _hover={{
                                            bgColor: 'primary.100',
                                            color: '#fff',
                                        }}
                                        textAlign={'left !important'}
                                        mb={'10px'}
                                    >
                                        <Text
                                            w={'full'}
                                            className='textWrapInitial'
                                        >
                                            {v}
                                        </Text>
                                    </Button>)
                            }
                            <Box
                                w={'60%'}
                            >
                                <Text m={'20px 0 0 0'} fontWeight={'500'}>- Status</Text>
                                <Pie data={data} />
                            </Box>
                            <Button
                                mt={'40px !important'}
                                w={'400px'}
                                bgColor={'transparent'}
                                color={'#2B2B2C'}
                                padding={'22px 0'}
                                fontSize={'14px'}
                                fontWeight={'600'}
                                border={'2px solid #2B2B2C'}
                                borderRadius={'6px'}
                                _hover={{
                                    bgColor: '#2B2B2C',
                                    color: '#fff'
                                }}
                            >
                                Zero-Trust Maturity ( Low / Medium / High )
                            </Button>
                        </Box>
                        <Box w={'65%'}>
                            {
                                buttonsContent.map((v, i) =>
                                    <Box
                                        key={i}
                                        borderBottom={'2px solid #36454f'}
                                    >
                                        <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            gap={'15px'}
                                            p={'15px 0'}
                                        >
                                            <Icon as={v.icon} fontSize={'24px'} /> <Heading fontWeight={'500'} fontSize={'28px'}>{v.heading}</Heading>
                                        </Box>
                                        <Text p={'0 0 20px 0'}>{v.content}</Text>
                                    </Box>
                                )}
                        </Box>
                    </Stack>
                }

            </Stack>
            {/* <SpacedStack>
                <EnterpriseRiskTolerance sideMenu={false} />
            </SpacedStack> */}
        </>
    )
}
