import React, { useCallback, useEffect, useRef, useState } from 'react';
import SideWrapper from '../SideBar/SideWrapper';
import {
  Heading,
  Stack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Divider,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  useNodesInitialized,
  ReactFlowProvider,
  ConnectionLineType,
  Panel,
} from 'reactflow';
import 'reactflow/dist/style.css';
import SelectionNode from '../VisualFlow/SelectionNode';
import '../VisualFlow/custom-node.css';
import CustomEdge from '../VisualFlow/CustomEdge';
import { zoneData } from '../VisualFlow/CustomData';
import IconNode from '../VisualFlow/CustomIcon';
import { AiWorkflowData } from '../VisualFlow/CustomData';
import dagre from 'dagre';
import { GET, POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import Azure from '../../assets/images/companies/azure.png';
import NeighborSelect from '../VisualFlow/NeighborSelect';
import InputNode from '../VisualFlow/InputNode';
import cloudzone from '../../assets/images/cloudzone.png';
import InvisibleNode from '../VisualFlow/InvisibleNode';
import CanvasLabel from '../VisualFlow/CanvasLavel';
import TextNode from '../VisualFlow/TextNode';

const initialNodes = [];

const totalNodes = initialNodes.length;
// console.log('Total nodes:', totalNodes);

const initialNodesUpdated = [
  ...initialNodes,
  {
    id: '3',
    type: 'selectUpdater',
    position: { x: 550, y: 225 },
    data: {
      values: AiWorkflowData,
      totalIds: totalNodes,
    },
  },
];
const initialEdges = [];

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

// const getLayoutedElements = (nodes, edges, direction = 'LR') => {
//   dagreGraph.setGraph({ rankdir: direction });

//   nodes.forEach(node => {
//     dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
//   });

//   edges.forEach(edge => {
//     dagreGraph.setEdge(edge.source, edge.target);
//   });

//   dagre.layout(dagreGraph);

//   nodes.forEach(node => {
//     const nodeWithPosition = dagreGraph.node(node.id);
//     node.targetPosition = 'left';
//     node.sourcePosition = 'right';

// We are shifting the dagre node position (anchor=center center) to the top left
// so it matches the React Flow node anchor point (top left).
//     node.position = {
//       x: nodeWithPosition.x - nodeWidth / 2,
//       y: nodeWithPosition.y - nodeHeight / 2,
//     };

//     return node;
//   });

//   return { nodes, edges };
// };

const nodeTypes = {
  selectUpdater: SelectionNode,
  IconDisplay: IconNode,
  NeighborSelector: NeighborSelect,
  InputUpdater: InputNode,
  InvisbleConnector: InvisibleNode,
  FloatingText: TextNode,
};
const edgeTypes = {
  'custom-edge': CustomEdge,
};

// const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
//   initialNodesUpdated,
//   initialEdges
// );

let id = 1;
const getId = () => `${id++}`;

const TestFlowWithoutProv = ({ icon }) => {
  const user = useSelector(state => state.value);
  const reactFlowInstance = useReactFlow();
  const serviceNowUsers = useSelector(state => state.serviceNowUsers);
  const hibernatingErrorMessage =
    'Could not extract response: no suitable HttpMessageConverter found for response type [com.cipherstack.itsm.servicenow.model.servicenow.ServiceNowTableResponse<com.cipherstack.itsm.servicenow.dto.IssueRequest>] and content type [text/html]';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const nodesInitialized = useNodesInitialized();
  const serviceNowAuth = useSelector(state => state.serviceNowAuth);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [nodeData, setnodeData] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [conditionMet, setConditionMet] = useState(false);
  const [hostData, setHostData] = useState([]);
  const [allowPanel, setAllowPanel] = useState(false);

  const handleNodeClick = (evt, node) => {
    if (nodes.length > 1 && node.type === 'NeighborSelector') {
      // console.log('Found the black sheep');
      // setnodeData(node?.data?.nodeData?.result);
      // default node
      // console.log('NODE', node);
    }
  };

  // const handleNodesChange = useCallback(
  //   changes => {
  //     onNodesChange(changes);

  //     // Check if all NeighborSelector nodes have selected values
  //     const neighborSelectors = nodes.filter(
  //       node =>
  //         node.type === 'NeighborSelector' &&
  //         node.data.selectedValue !== undefined
  //     );
  //     const allSelected = neighborSelectors.length > 0 && neighborSelectors.every(
  //       node => node.data.selectedValue !== ''
  //     );

  //     if (nodes.length > 1 && allSelected === true) {
  //       console.log(
  //         'All NeighborSelector nodes have selected values: 2',
  //         allSelected
  //       );
  //       // Execute the logic when the condition is met
  //       console.log('Found the black sheep 2');
  //     }
  //     console.log("Nodes without selectedValue", neighborSelectors)
  //   },
  //   [nodes, onNodesChange]
  // );

  // const getData = async () => {
  //   setIsLoading(true);
  //   // setFetchingCompliance(true);
  //   try {
  //     const res = await POST(
  //       'api/template/compliance/result/vendor/AZURE/from/2024-03-03/to/2024-03-10'
  //     );
  //     setData([res?.data[res?.data?.length - 1]]);
  //     console.log('WE Got Data', res?.data[res?.data?.length - 1]);
  //     const node = [
  //       {
  //         id: '1',
  //         position: { x: 100, y: 425 },
  //         type: 'selectUpdater',
  //         data: {
  //           values:
  //             res?.data[res?.data?.length - 1]?.compliance?.compliance[0].items,
  //           img: Azure,
  //           placeholder: 'Please Selet a Zone',
  //           totalIds: 1,
  //         },
  //       },
  //       // {
  //       //   id: '2',
  //       //   type: 'IconDisplay',
  //       //   position: { x: 300, y: 230 },
  //       //   data: { label: 'Application Endpoint', icon: 'FaRegCopy' },
  //       // },
  //     ];
  //     setNodes(node);
  //     setIsLoading(false);
  //   } catch (err) {
  //     setIsLoading(false);
  //     console.log(err);
  //   }
  //   setIsLoading(false);
  //   // setFetchingCompliance(false);
  // };

  const getVendorData = async () => {
    if (localStorage.getItem('accessToken')) {
      try {
        const res = await GET(`api/v1/tenant/supported-vendor/`);
        setHostData(
          Array.isArray(res)
            ? res.filter(val => val?.policyDisabled === null)
            : [res]
        ); // Ensure hostData is an array
        // console.log(
        //   'We Got Hosts',
        //   res.filter(val => val?.policyDisabled === null)
        // );
        const node = [
          {
            id: '1',
            position: { x: 100, y: 350 },
            type: 'NeighborSelector',
            data: {
              values: res.filter(val => val?.policyDisabled === null),
              img: cloudzone,
              placeholder: 'Please Selet a Zone',
              totalIds: 1,
              isZone: true,
              setConditionMet: setConditionMet,
            },
          },
        ];
        setNodes(node);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        // Handle errors
      }
    } else {
    }
  };

  const onConnect = useCallback(
    params => setEdges(eds => addEdge(params, eds)),
    [setEdges]
  );

  const createConnector = color => {
    let newIds = [];
    let newEdgeIds = [];
    let totalIds = reactFlowInstance.getNodes().length;
    const touchNode = {
      id: 'D',
      position: { x: 1055, y: 420 },
      type: 'InvisbleConnector',
    };
    reactFlowInstance.addNodes(touchNode);
    const touchEdge = {
      id: `edge-2->D`,
      source: '2',
      target: 'D',
      sourceHandle: 'c',
      style: {
        strokeWidth: 2,
        stroke: color,
      },
    };

    const floatNode = {
      id: 'FL2',
      position: { x: 1220, y: 194 },
      type: 'FloatingText',
      data: {
        text: 'Device / Services',
      },
    };
    reactFlowInstance.addNodes(floatNode);
    reactFlowInstance.addEdges(touchEdge);
    const nodesPayload = [
      {
        position: { x: 1260, y: 240 },
        type: 'NeighborSelector',
        data: {
          placeholder: 'Originator',
        },
      },
      {
        position: { x: 1260, y: 330 },
        type: 'NeighborSelector',
        data: {
          placeholder: 'Adjecent',
        },
      },
      {
        position: { x: 1260, y: 460 },
        type: 'NeighborSelector',
        data: {
          placeholder: 'Transient',
        },
      },
      {
        position: { x: 1260, y: 550 },
        type: 'NeighborSelector',
        data: {
          placeholder: 'Destination',
        },
      },
    ];

    for (let i = 0; i < nodesPayload.length; i++) {
      const id = `${++totalIds}B`;
      newIds.push(id); // Collect new ID
      const newNode = {
        id,
        type: nodesPayload[i].type,
        position: nodesPayload[i].position,
        // parentId: 'A',
        data: {
          placeholder: nodesPayload[i].data.placeholder,
          isNeighbor: true,
          tag: 'Device',
          values: ['AZURE - 663cf917c562623780ac0130'],
          selectedValue: '',
        },
      };
      reactFlowInstance.addNodes(newNode);
      newIds = [...newIds, id];

      const newEdge = {
        id: `edge-D->${++totalIds}`,
        source: 'D',
        target: id,
        sourceHandle: 'e',
        style: {
          strokeWidth: 2,
          stroke: color,
        },
      };
      // console.log('New Edge payload', newEdge);
      newEdgeIds.push(`edge-D->${id}`);
      reactFlowInstance.addEdges(newEdge);
    }
  };

  // useEffect to check for the condition when nodes change
  useEffect(() => {
    console.log('NODES', nodes);

    const color = nodes.filter(
      node => node.type === 'InputUpdater' && node?.data?.edgeColor
    )[0]?.data?.edgeColor;
    console.log('Test color', color);
    // Only run the effect if the condition has not been met
    if (!conditionMet) {
      const neighborSelectors = nodes.filter(
        node =>
          node.type === 'NeighborSelector' &&
          node.data.selectedValue !== undefined
      );
      const allSelected =
        neighborSelectors.length > 0 &&
        neighborSelectors.every(node => node.data.selectedValue !== '');

      if (nodes.length > 1 && allSelected === true) {
        console.log(
          'All NeighborSelector nodes have selected values: 2',
          allSelected
        );
        createConnector(color);
        // Execute the logic when the condition is met
        console.log('Found the black sheep 2');
        // Set the flag to indicate the condition has been met
        setConditionMet(true);
      }

      // console.log('Nodes without selectedValue', neighborSelectors);
    }

    const deviceNodes = nodes.filter(
      node =>
        node.type === 'NeighborSelector' &&
        node.data.tag === 'Device' &&
        node.data.selectedValue !== undefined
    );
    console.log('Device Node', deviceNodes);
    const allDeviceFilled =
      deviceNodes.length > 0 &&
      deviceNodes.every(node => node.data.selectedValue !== '');

    if (nodes.length > 1 && allDeviceFilled === true) {
      setAllowPanel(true);
      console.log('All Device nodes have selected values: 2', allDeviceFilled);
    }
  }, [nodes, conditionMet]);

  useEffect(() => {
    console.log(user);
    // getData();
    getVendorData();
  }, [user]);

  return (
    <>
      <SideWrapper>
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'10px'}
          padding={'35px 30px'}
          background={'#fff'}
          borderRadius={'10px'}
          marginBottom={'20px !important'}
          spacing={0}
          flexWrap={'wrap'}
          width={'100%'}
        >
          <Stack width={'full'} height={'75vh'} border={'1px solid #000'}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onNodeClick={handleNodeClick}
              // fitView
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              // zoomOnScroll={false}
              // panOnDrag={false}
              zoomOnDoubleClick={false}
              // nodesDraggable={false}
            >
              {/* {conditionMet && (
                <CanvasLabel
                  position={{ x: 1260, y: 200 }}
                  text={'Hello World'}
                />
              )} */}
              <Controls />
              {allowPanel && (
                <Panel position="top-right">
                  <Button>Create</Button>
                </Panel>
              )}
              {/* <MiniMap /> */}
              {/* <Background variant="dots" gap={12} size={1} /> */}
            </ReactFlow>
          </Stack>
        </Stack>
        {nodeData && nodeData !== null && (
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            size={'sm'}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Endpoint Details</DrawerHeader>
              <Divider />
              <DrawerBody padding={4}>
                <List gap={6} spacing={3}>
                  <ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">ID:</Text>{' '}
                    <Text>{nodeData?.id}</Text>
                  </ListItem>
                  <ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">Rule Key:</Text>{' '}
                    <Text>{nodeData?.ruleKey}</Text>
                  </ListItem>
                  <ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">Vendor Type:</Text>{' '}
                    <Text>{nodeData?.vendorType}</Text>
                  </ListItem>
                  <ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">Security:</Text>{' '}
                    <Text color={nodeData?.vulnerable ? 'red' : 'green'}>
                      {nodeData?.vulnerable ? 'HIGH' : 'LOW'}
                    </Text>
                  </ListItem>
                  <ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">Detailed Name:</Text>{' '}
                    <Text>{nodeData?.ruleName}</Text>
                  </ListItem>
                  {/*<ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">Detection Method:</Text>{' '}
                    <Text>{nodeData?.detectionMethod}</Text>
                  </ListItem>
                  <ListItem display={'flex'} gap={2}>
                    <Text fontWeight="bold">Source:</Text>{' '}
                    <Text>{nodeData?.source}</Text>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight="bold">Data Source Link:</Text>{' '}
                    <Link
                      href={nodeData?.dataSourceLink?.link}
                      color={'blue.400'}
                    >
                      {' '}
                      {nodeData?.dataSourceLink?.linkName}{' '}
                    </Link>
                  </ListItem> */}
                </List>
              </DrawerBody>

              <DrawerFooter>
                <Button
                  variant="outline"
                  colorScheme="blue"
                  mr={3}
                  onClick={onClose}
                  width={'full'}
                >
                  View Details
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        )}
      </SideWrapper>
    </>
  );
};

function TestFlow({ icon }) {
  return (
    <ReactFlowProvider>
      <TestFlowWithoutProv icon={icon} />
    </ReactFlowProvider>
  );
}

export default TestFlow;
